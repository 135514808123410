import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { Institution } from '../../models/institution.model';
import { InstitutionsState, InstitutionsStateModel } from '../../store/state/institutions/institutions.state';
import { firstValidValueFrom } from '../../utils/firstValidValueFrom';
import { CreateManualInstitutions, GetTrovataInstitutions, UpdateCustomInstitution, UpdateManualInstitution } from '../../store/actions/institutions.actions';
import { TrovataAppState } from 'src/app/core/models/state.model';

@Injectable({
	providedIn: 'root',
})
export class InstitutionFacadeService {
	@Select(InstitutionsState.institutions)
	institutions$: Observable<InstitutionsStateModel>;
	@Select(InstitutionsState.customerInstitutions)
	customerInstitutions$: Observable<Institution[]>;
	@Select(InstitutionsState.manualInstitutions) manualInstitutions$: Observable<Institution[]>;
	@Select(InstitutionsState.trovataInstitutions) trovataInstitutions$: Observable<Institution[]>;
	@Select(InstitutionsState.lastCreatedInstitutionId)
	lastCreatedInstitutionId$: Observable<string>;

	constructor(private store: Store) {}

	getInstitutionById(institutionId: string): Promise<Institution> {
		return new Promise<Institution>(async (resolve, reject) => {
			try {
				const institutions: Institution[] = await this.getCustomerInstitutions();
				resolve(institutions.find((institution: Institution) => institution.institutionId === institutionId));
			} catch (error) {
				reject(error);
			}
		});
	}

	createManualInstitutions(institutionNames: string[]): Promise<Institution[]> {
		return new Promise<Institution[]>(async (resolve, reject) => {
			try {
				const snapshotInstitutions: Institution[] = [...this.store.selectSnapshot((appState: TrovataAppState) => appState.institutions.manualInstitutions)];
				await lastValueFrom(this.store.dispatch(new CreateManualInstitutions(institutionNames)));
				const institutions: Institution[] = await firstValidValueFrom(this.manualInstitutions$);
				const newInstitutions: Institution[] = institutions.filter(
					(institution: Institution) =>
						!snapshotInstitutions.find((findInstitution: Institution) => institution.institutionId === findInstitution.institutionId) &&
						institutionNames.includes(institution.institutionName)
				);
				resolve(newInstitutions);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateInstitution(institution: Institution, institutionNickname: string): Promise<Institution> {
		return new Promise<Institution>(async (resolve, reject) => {
			try {
				if (institution.dataProviderId === 'MANUAL') {
					await this.store.dispatch(new UpdateManualInstitution(institution, institutionNickname));
				} else {
					await this.store.dispatch(new UpdateCustomInstitution(institution, institutionNickname));
				}
				resolve(await this.getInstitutionById(institution.institutionId));
			} catch (error) {
				reject(error);
			}
		});
	}

	getInstitutionDict(): Promise<Map<string, string>> {
		return new Promise(async (resolve, reject) => {
			try {
				const institutionDict: Map<string, string> = new Map<string, string>();
				const institutions: Institution[] = await this.getCustomerInstitutions();
				institutions.forEach((institution: Institution) => {
					if (!institutionDict[institution.institutionId]) {
						institutionDict[institution.institutionId] = institution.institutionNickname;
					}
				});
				resolve(institutionDict);
			} catch (error) {
				reject(error);
			}
		});
	}

	private getCustomerInstitutions(): Promise<Institution[]> {
		return new Promise((resolve, reject) => {
			try {
				Promise.all([firstValidValueFrom(this.manualInstitutions$), firstValidValueFrom(this.customerInstitutions$)]).then(
					([manualInst, customerInst]: [Institution[], Institution[]]) => resolve([...customerInst, ...manualInst])
				);
			} catch {
				reject();
			}
		});
	}

	createTrovataInstitutionsObservable(): Observable<Institution[]> {
		return new Observable(observer => {
			this.institutions$.subscribe((instState: InstitutionsStateModel) => {
				if (instState.trovataInstitutions) {
					observer.next(instState.trovataInstitutions);
				} else if (!instState.trovataInstApiInFlight) {
					this.store.dispatch(new GetTrovataInstitutions());
				}
			});
		});
	}
}
