import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { catchError, Observable, Subscription, tap, throwError } from 'rxjs';
import { PaymentMethod } from '../../models/payment.model';
import { PaymentMethodsService } from '../../services/payment-methods.service';
import {
	ClearPaymentMethodsState,
	CreatePaymentMethod,
	GetPaymentMethods,
	InitPaymentMethodsState,
	UpdatePaymentMethod,
} from '../actions/payment-methods.actions';

export class PaymentMethodsStateModel {
	paymentMethods: PaymentMethod[];
}

@State<PaymentMethodsStateModel>({
	name: 'paymentMethods',
	defaults: {
		paymentMethods: null,
	},
})
@Injectable()
export class PaymentMethodsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private paymentMethodsService: PaymentMethodsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static paymentMethods(state: PaymentMethodsStateModel) {
		return state.paymentMethods;
	}

	@Action(InitPaymentMethodsState)
	async initPaymentMethodsState(context: StateContext<PaymentMethodsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const plaidStateIsCached: boolean = this.paymentMethodsStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (plaidStateIsCached && appReady) {
						const state: PaymentMethodsStateModel = deserializedState.paymentMethods;
						context.patchState(state);
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetPaymentMethods)
	getPaymentMethods(context: StateContext<PaymentMethodsStateModel>) {
		return this.paymentMethodsService.getPaymentMethods().pipe(
			tap((paymentMethodsResponse: { paymentMethods: PaymentMethod[] }) => {
				const state: PaymentMethodsStateModel = context.getState();
				state.paymentMethods = paymentMethodsResponse.paymentMethods;
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(UpdatePaymentMethod)
	updatePaymentMethod(context: StateContext<PaymentMethodsStateModel>, action: UpdatePaymentMethod) {
		return this.paymentMethodsService.updatePaymentMethod(action.paymentMethod, action.makeDefault).pipe(
			tap(() => {
				context.dispatch(new GetPaymentMethods());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CreatePaymentMethod)
	createPaymentMethod(context: StateContext<PaymentMethodsStateModel>, action: CreatePaymentMethod) {
		return this.paymentMethodsService.createPaymentMethod(action.paymentMethodId, action.isDefault, action.billingDetails).pipe(
			tap(() => {
				context.dispatch(new GetPaymentMethods());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ClearPaymentMethodsState)
	clearPlaidState(context: StateContext<PaymentMethodsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: PaymentMethodsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private paymentMethodsStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.paymentMethods && deserializedState.paymentMethods.paymentMethods) {
			return true;
		} else {
			return false;
		}
	}
}
