import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@trovata/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { GetSharesForResourceResponse, OneTimeShare, Share } from '../models/share.model';

@Injectable({
	providedIn: 'root',
})
export class ShareService {
	constructor(private httpClient: HttpClient) {}

	getSharesForResource(resourceType: string, resourceId?: string): Observable<HttpResponse<GetSharesForResourceResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/shares';
		const params: { [param: string]: any } = { resourceType };
		if (resourceId) {
			params.resourceId = resourceId;
		}
		return this.httpClient
			.get<GetSharesForResourceResponse>(url, {
				params,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	createShare(share: Share): Observable<HttpResponse<Share>> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/shares';
		return this.httpClient
			.post<Share>(url, share, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	updateShare(share: Share): Observable<HttpResponse<Share>> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/shares/${share.shareId}`;
		return this.httpClient
			.put<Share>(url, share, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteShare(shareId: string): Observable<HttpResponse<null>> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/shares/${shareId}`;
		return this.httpClient.delete<null>(url).pipe(catchError(err => throwError(() => err)));
	}

	sendOneTimeShare(share: OneTimeShare): Observable<HttpResponse<any>> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/shares/onetime';
		return this.httpClient
			.post<Share>(url, share, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}
}
