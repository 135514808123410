import { PaymentToCreate, StageFeedback, PaymentSubmissionOrCancelation, Payment, BatchPayment, PaymentsListBody } from '../../models/payment.model';

export class InitPaymentsState {
	static readonly type: string = '[Payments] InitPaymentsState';
}

export class ClearPaymentsState {
	static readonly type: string = '[Payments] ClearPaymentsState';
}

export class ResetPaymentsState {
	static readonly type: string = '[Payments] ResetPaymentsState';
}

export class GetPaginatedPayments {
	static readonly type: string = '[Payments] GetPaginatedPayments';
	constructor(public getPaymentsBody: PaymentsListBody) {}
}

export class GetPaymentById {
	static readonly type: string = '[Payments] GetPaymentById';
	constructor(public paymentId: string) {}
}

export class GetBatchPaymentById {
	static readonly type: string = '[Payments] GetBatchPaymentById';
	constructor(public batchPaymentId: string) {}
}

export class GetBatchPayments {
	static readonly type: string = '[Payments] GetBatchPayments';
}

export class GetPaginatedBatchPayments {
	static readonly type: string = '[Payments] GetPaginatedBatchPayments';
	constructor(
		public from: number,
		public size: number
	) {}
}

export class CreatePayment {
	static readonly type: string = '[Payments] CreatePayment';
	constructor(public paymentToCreate: PaymentToCreate) {}
}

export class UploadFileToPayment {
	static readonly type: string = '[Payments] UploadFileToPayment';
	constructor(
		public body: FormData,
		public paymentId: string
	) {}
}

export class DeleteFileFromPayment {
	static readonly type: string = '[Payments] DeleteFileFromPayment';
	constructor(
		public attachmentId: string,
		public paymentId: string
	) {}
}

export class CreateBatchPayment {
	static readonly type: string = '[Payments] CreateBatchPayment';
	constructor(public batchFile: FormData) {}
}

export class SetPaymentsTabIndex {
	static readonly type: string = '[Payments] SetPaymentsTabIndex';
	constructor(public tabIndex: number) {}
}

export class SetStageFeedback {
	static readonly type: string = '[Payments] SetStageFeedback';
	constructor(public stageFeedback: StageFeedback) {}
}

export class SetBatchStageFeedback {
	static readonly type: string = '[Payments] SetBatchStageFeedback';
	constructor(public batchStageFeedback: StageFeedback) {}
}

export class CancelPayment {
	static readonly type: string = '[Payments] CancelPayment';
	constructor(public paymentCancelation: PaymentSubmissionOrCancelation) {}
}

export class CancelBatchPayment {
	static readonly type: string = '[Payments] CancelBatchPayment';
	constructor(public batchPaymentCancelation: PaymentSubmissionOrCancelation) {}
}

export class UpdateStoreBatchsPayments {
	static readonly type: string = '[Payments] UpdateStoreBatchsPayments';
	constructor(public batchId: string) {}
}

export class GetBatchsPayments {
	static readonly type: string = '[Payments] GetBatchsPayments';
	constructor(public batchPayment: BatchPayment) {}
}

export class ResetCachedPayments {
	static readonly type: string = '[Payments] ResetCachedPayments';
}

export class ResetCachedBulkPayments {
	static readonly type: string = '[Payments] ResetCachedBulkPayments';
}

export class RemovePaymentFromStoreById {
	static readonly type: string = '[Payments] RemovePaymentFromStoreById';
	constructor(public paymentId: string) {}
}

export class RemoveBatchPaymentFromStoreById {
	static readonly type: string = '[Payments] RemoveBatchPaymentFromStoreById';
	constructor(public batchId: string) {}
}
