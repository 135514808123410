import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { catchError, combineLatest, Observable, Subscription, tap, throwError } from 'rxjs';
import { CustomerUser, CustomerUserStatus, CustomerUsersDict, GetCustomerUsersResponse } from '../../models/identity.model';
import { IdentityService } from '../../services/identity.service';
import { GetUserGroups } from '../actions/entitlements.actions';
import { PermissionId, PermissionMap } from '../../models/feature.model';
import { CustomerFeatureState } from './customer-feature.state';
import {
	ClearCustomerUsersState,
	CreateCustomerUser,
	DeleteCustomerUser,
	EditCustomerUser,
	GetCustomerUsers,
	InitCustomerUsersState,
	ResetCustomerUsersState,
	SendResetPasswordForCustomerUser,
} from '../actions/customer-users.actions';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';

export class CustomerUsersStateModel extends EntitledStateModel {
	customerUsers: CustomerUser[];
	customerUsersDict: CustomerUsersDict;
}

@State<CustomerUsersStateModel>({
	name: 'customerUsers',
	defaults: {
		customerUsers: null,
		customerUsersDict: null,
		isCached: false,
	},
})
@Injectable()
export class CustomerUsersState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private identityService: IdentityService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static customerUsers(state: CustomerUsersStateModel) {
		return state.customerUsers;
	}

	@Selector()
	static customerUsersDict(state: CustomerUsersStateModel) {
		return state.customerUsersDict;
	}

	@Action(InitCustomerUsersState)
	async initCustomerUsersState(context: StateContext<CustomerUsersStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const isCustomerUsersStateCached: boolean = this.customerUsersStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (!this.isInitialized && appReady && permissions) {
						if (permissions.has(PermissionId.readUsers)) {
							if (isCustomerUsersStateCached) {
								const state: CustomerUsersStateModel = deserializedState.customerUsers;
								context.patchState(state);
							} else {
								context.dispatch(new GetCustomerUsers());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ customerUsers: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetCustomerUsers)
	getCustomerUsers(context: StateContext<CustomerUsersStateModel>) {
		return this.identityService.getCustomerUsers().pipe(
			tap((getCustomerUsersResponse: GetCustomerUsersResponse) => {
				const state: CustomerUsersStateModel = context.getState();
				const users = getCustomerUsersResponse.users.filter((user: CustomerUser) => user.status !== CustomerUserStatus.deleted);
				users.forEach((user: CustomerUser) => {
					if (!state.customerUsersDict) {
						state.customerUsersDict = {};
					}
					state.customerUsersDict[user.userId] = user;
				});
				state.customerUsers = this.sortCustomerUsersByName(users);
				state.isCached = true;
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(EditCustomerUser)
	editCustomerUser(context: StateContext<CustomerUsersStateModel>, action: EditCustomerUser) {
		return this.identityService.editUserForCustomer(action.userId, action.editUserForCustomerBody).pipe(
			tap((customerUser: CustomerUser) => {
				const state: CustomerUsersStateModel = context.getState();
				state.customerUsers = state.customerUsers.filter((filterCustomerUser: CustomerUser) => filterCustomerUser.userId !== customerUser.userId);
				state.customerUsers.push(customerUser);
				state.customerUsersDict[customerUser.userId] = customerUser;
				state.customerUsers = this.sortCustomerUsersByName(state.customerUsers);
				context.patchState(state);
				context.dispatch(new GetUserGroups());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CreateCustomerUser)
	createCustomerUser(context: StateContext<CustomerUsersStateModel>, action: CreateCustomerUser) {
		return this.identityService.addNewUserForCustomer(action.addNewUserForCustomerBody).pipe(
			tap((customerUser: CustomerUser) => {
				const state: CustomerUsersStateModel = context.getState();
				// remove first and last name set when user POST method is fixed
				customerUser.firstName = action.addNewUserForCustomerBody.firstName;
				customerUser.lastName = action.addNewUserForCustomerBody.lastName;
				customerUser.email = action.addNewUserForCustomerBody.email;
				customerUser.phone = action.addNewUserForCustomerBody.phone;
				state.customerUsers.push(customerUser);
				state.customerUsersDict[customerUser.userId] = customerUser;
				state.customerUsers = this.sortCustomerUsersByName(state.customerUsers);
				context.patchState(state);
				context.dispatch(new GetUserGroups());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(DeleteCustomerUser)
	deleteCustomerUser(context: StateContext<CustomerUsersStateModel>, action: DeleteCustomerUser) {
		return this.identityService.deleteUserFromCustomer(action.userId).pipe(
			tap((response: null) => {
				const state: CustomerUsersStateModel = context.getState();
				const deletedCustomerUser: CustomerUser = state.customerUsers.find((filterCustomerUser: CustomerUser) => filterCustomerUser.userId === action.userId);
				state.customerUsers = state.customerUsers.filter((filterCustomerUser: CustomerUser) => filterCustomerUser.userId !== deletedCustomerUser.userId);
				state.customerUsers = this.sortCustomerUsersByName(state.customerUsers);
				delete state.customerUsersDict[action.userId];
				context.patchState(state);
				context.dispatch(new GetUserGroups());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(SendResetPasswordForCustomerUser)
	sendResetPasswordForCustomerUser(context: StateContext<CustomerUsersStateModel>, action: DeleteCustomerUser): Observable<void> {
		return this.identityService.sendResetPasswordForCustomerUser(action.userId).pipe(catchError(error => throwError(() => error)));
	}

	@Action(ClearCustomerUsersState)
	clearCustomerUsersState(context: StateContext<CustomerUsersStateModel>) {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: CustomerUsersStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetCustomerUsersState)
	resetCustomerUsersStates(context: StateContext<CustomerUsersStateModel>) {
		context.dispatch(new GetCustomerUsers());
	}

	private customerUsersStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState?.customerUsers?.customerUsers && deserializedState.customerUsers.isCached && deserializedState.customerUsers.customerUsersDict) {
			return true;
		} else {
			return false;
		}
	}

	private sortCustomerUsersByName(customerUsers: CustomerUser[]): CustomerUser[] {
		customerUsers = customerUsers.sort((customerUserA: CustomerUser, customerUserB: CustomerUser) => {
			if (customerUserA.firstName?.toLowerCase() < customerUserB.firstName?.toLowerCase()) {
				return -1;
			} else if (customerUserA.firstName?.toLowerCase() > customerUserB.firstName?.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return customerUsers;
	}
}
