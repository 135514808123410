import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { UserConfigurationService } from 'src/app/shared/services/user-configuration.service';
import { ClearUserConfigState, GetUserConfig, InitUserConfigState } from '../../actions/user-config.actions';
import { UserConfig } from 'src/app/core/models/user-config.model';
import { HeapAnalyticsService } from '@trovata/app/core/services/heap-analytics.service';

export class UserConfigStateModel {
	userConfig: UserConfig;
}

@State<UserConfigStateModel>({
	name: 'userConfig',
	defaults: {
		userConfig: null,
	},
})
@Injectable()
export class UserConfigState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	@Selector()
	static userConfig(state: UserConfigStateModel) {
		return state.userConfig;
	}

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private userConfigurationService: UserConfigurationService,
		private heapService: HeapAnalyticsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitUserConfigState)
	async initUserConfigState(context: StateContext<UserConfigStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const userConfigIsCached: boolean = this.userConfigIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (userConfigIsCached && appReady) {
						const state: UserConfigStateModel = deserializedState.userConfig;
						context.patchState(state);
					} else if (!userConfigIsCached && appReady) {
						context.dispatch(new GetUserConfig());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetUserConfig)
	getUserConfig(context: StateContext<UserConfigStateModel>, action: GetUserConfig) {
		let state = context.getState();
		return this.userConfigurationService.getUserConfig().pipe(
			tap((response: HttpResponse<UserConfig>) => {
				const userConfig: UserConfig = response.body;
				state = context.getState();
				if (JSON.stringify(state.userConfig) !== JSON.stringify(userConfig)) {
					state.userConfig = userConfig;
					context.patchState(state);
				}
				this.heapService.addUserProperties({ stripeTier: userConfig.stripeTier });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ClearUserConfigState)
	clearUserConfigState(context: StateContext<UserConfigStateModel>) {
		this.appReadySub.unsubscribe();
		const state: UserConfigStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private userConfigIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedPaymentsState: UserConfigStateModel | undefined = deserializedState.userConfig;
		if (deserializedPaymentsState && deserializedPaymentsState.userConfig) {
			return true;
		} else {
			return false;
		}
	}
}
