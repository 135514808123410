import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetResourceTypesResponse, ResourceAccessUser } from '../models/user-group.model';
import { PermissionId } from '../models/feature.model';

@Injectable({
	providedIn: 'root',
})
export class ResourceTypesService {
	constructor(private httpClient: HttpClient) {}

	getResourceTypes(): Observable<GetResourceTypesResponse> {
		return this.httpClient.get<GetResourceTypesResponse>(environment.trovataAPI('workspace') + '/entitlements/customer/user-groups/resource-types');
	}

	getResourceAccessList(
		featureId: number,
		permissionId: PermissionId,
		resourceType: string,
		resourceId: string
	): Observable<HttpResponse<ResourceAccessUser[]>> {
		const url: string =
			environment.trovataAPI('workspace') +
			`/entitlements/customer/features/${featureId}/permissions/${permissionId}/resourcetypes/${resourceType}/resources/${resourceId}/users`;
		return this.httpClient.get<ResourceAccessUser[]>(url, {
			observe: 'response',
		});
	}
}
