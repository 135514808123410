import { HighchartColors } from '@trovata/app/shared/models/highcharts.model';

// This file gets swapped out for the NAB deployement.  All chnages need to be reflected there
// src/styles/environments/nab-environment/highchart-colors.ts

export const HighChartColors: HighchartColors = {
	generalChartColors: [
		'#0082FF',
		'#00A8E9',
		'#00C4CB',
		'#00D6AA',
		'#0ADC9A',
		'#72E582',
		'#AEE96A',
		'#E4E958',
		'#FDE854',
		'#F4BC4D',
		'#EF8F4B',
		'#EC6563',
		'#FA4173',
		'#D14AC3',
		'#A956F6',
		'#6B73F6',
		'#8199B8',
		'#3F5973',
	],
	forecastChartColors: ['#006CE2', '#00EBC1', '#5FF987', '#051B47', '#7771E6', '#54D2FF', '#0DB25F', '#6B8ABC'],
	barColorScheme: [
		{
			value: 0,
			color: '#FF4979',
		},
		{
			color: '#2290FF',
		},
	],
	grossColors: {
		credit: '#2290FF',
		debit: '#FF4979',
		neutral: '#A0A0A0',
		lightNeutral: '#D9D9D9',
	},
};
