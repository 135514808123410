import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { CacheService } from 'src/app/shared/services/cache.service';
import { PopoutService } from 'src/app/features/transactions/services/popout.service';
import { Logout, SetAccessToken, SetAuthenticated, SetAuthErrorMessage, SetRefreshToken } from '../../actions/auth.actions';
import { throwError } from 'rxjs';
import * as LocalForage from 'localforage';
import { WebWorkerService } from '../../../services/web-worker.service';
import { ClearTrovataAppStates, SetAppReady } from '../../actions/core.actions';
import { GetFeatureConfig, GetFeaturePermissions } from 'src/app/features/settings/store/actions/customer-feature.actions';
import { firstValidValueFrom } from 'src/app/shared/utils/firstValidValueFrom';
import { AuthUser } from '@trovata/app/core/models/auth.model';
import { SessionWebWorkerService } from '@trovata/app/core/services/session-web-worker.service';
import { AnalyticsUserActions, UserActions } from '@trovata/app/core/utils/analyticsEnums';
import { HeapAnalyticsService } from '@trovata/app/core/services/heap-analytics.service';
import { WebSocketService } from '@trovata/app/core/services/web-socket.service';
import { VitallyService } from '@trovata/app/core/services/vitally.service';

export class AuthStateModel {
	authUser: AuthUser;
	accessToken: string;
	refreshToken: string;
	authenticated: boolean;
	authErrorMessage: string;
	loggedIn: boolean;
}

@State<AuthStateModel>({
	name: 'auth',
	defaults: {
		authUser: null,
		accessToken: null,
		refreshToken: null,
		authenticated: null,
		authErrorMessage: null,
		loggedIn: null,
	},
})
@Injectable()
export class AuthState implements NgxsOnInit {
	constructor(
		private authService: AuthService,
		private cacheService: CacheService,
		private popoutService: PopoutService,
		private sessionService: SessionWebWorkerService,
		private router: Router,
		private store: Store,
		private webWorkerService: WebWorkerService,
		private vitallyService: VitallyService,
		private heapService: HeapAnalyticsService,
		private websocketService: WebSocketService
	) {}

	async ngxsOnInit(context: StateContext<AuthStateModel>): Promise<void> {
		try {
			await LocalForage.ready();

			this.setSubscriptions(context);
			this.checkForInstitutionOAuthCallback();
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Selector() static accessToken(authState: AuthStateModel): string {
		return `Bearer ${authState.accessToken}`;
	}

	@Action(Logout)
	logout(context: StateContext<AuthStateModel>, action: Logout): void {
		this.webWorkerService.terminateWorker();
		this.sessionService.cleanup();
		this.websocketService.closeConnection();
		this.cacheService.clear();
		context.dispatch(new ClearTrovataAppStates());
		window.localStorage.removeItem('loginTime');
		window.localStorage.removeItem('auth');
		window.localStorage.removeItem('refreshToken');
		window.localStorage.removeItem('latestActivity');
		this.popoutService.closePopoutModal();
		(<any>window).Intercom('shutdown');
		if (action.customerDeleted) {
			this.authService.logout();
		} else {
			this.authService.logout({
				logoutParams: {
					returnTo: document.location.origin,
				},
			});
		}
	}

	@Action(SetAccessToken)
	setAccessToken(context: StateContext<AuthStateModel>, action: SetAccessToken): void {
		const state: AuthStateModel = context.getState();
		localStorage.setItem('auth', action.accessToken);
		state.accessToken = action.accessToken;
		context.patchState(state);
		context.dispatch(new SetAuthenticated(true));
	}

	@Action(SetRefreshToken)
	setRefreshToken(context: StateContext<AuthStateModel>, action: SetRefreshToken): void {
		const state: AuthStateModel = context.getState();
		state.refreshToken = action.refreshToken;
		context.patchState(state);
	}

	@Action(SetAuthenticated)
	setAuthenticated(context: StateContext<AuthStateModel>, action: SetAuthenticated): void {
		const state: AuthStateModel = context.getState();
		state.authenticated = action.authenticated;
		context.patchState(state);
	}

	@Action(SetAuthErrorMessage)
	setErrorMessage(context: StateContext<AuthStateModel>, action: SetAuthErrorMessage): void {
		const state: AuthStateModel = context.getState();
		localStorage.setItem('auth', action.authErrorMessage);
		state.authErrorMessage = action.authErrorMessage;
		context.patchState(state);
	}

	private checkForInstitutionOAuthCallback(): void {
		if (/\/home\/connections(\/\w+)?\/success/.test(window.location.pathname)) {
			const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
			if (queryParams.has('code') && queryParams.has('state')) {
				queryParams.set('_state', queryParams.get('state'));
				queryParams.delete('state');

				this.router.navigate([window.location.pathname], {
					queryParams: Object.fromEntries(queryParams.entries()),
				});
			}
		}
	}

	private setSubscriptions(context: StateContext<AuthStateModel>): void {
		this.authService.error$.subscribe(async (error: Error) => {
			if (error.message === 'user is blocked') {
				this.router.navigate(['/customer-deleted']);
			}
		});
		this.authService.isAuthenticated$.subscribe(async (authenticated: boolean) => {
			if (!this.shouldDoNothing() && authenticated) {
				try {
					await this.sessionService.refreshTokens();
					await Promise.all([
						firstValidValueFrom(this.store.dispatch(new GetFeatureConfig())),
						firstValidValueFrom(this.store.dispatch(new GetFeaturePermissions())),
					]);
					this.store.dispatch(new SetAppReady(true));
					const state: AuthStateModel = context.getState();
					this.sessionService.initSession();
					this.websocketService.initializeConnection();

					// because of angulars life cycles, using document.location is more accurate than using this.router.url
					if (['/login', '/'].includes(document.location.pathname)) {
						if (!state.loggedIn) {
							state.loggedIn = true;
							context.patchState(state);
							this.heapService.userAction(UserActions.login);
						}
						this.router.navigate(['/balances'], {
							queryParamsHandling: 'preserve',
							preserveFragment: true,
						});
					}
				} catch (error) {
					throw error;
				}
			} else if (this.shouldDoNothing()) {
				return;
			} else {
				const params: { [k: string]: string } | void = this.getParams();

				if (params && params['utm_campaign'] && params['utm_medium'] && params['utm_source']) {
					localStorage.setItem('utm_campaign', params['utm_campaign']);
					localStorage.setItem('utm_medium', params['utm_medium']);
					localStorage.setItem('utm_source', params['utm_source']);
				}

				const redirect: string = window.location.pathname + window.location.search;
				this.authService.loginWithRedirect({
					authorizationParams: {
						prompt: 'login',
					},
					appState: { target: redirect },
				});
			}
		});
		this.authService.user$.subscribe((authUser: AuthUser | null) => {
			const state: AuthStateModel = context.getState();
			if (authUser) {
				if (state && !state.authUser) {
					state.authUser = authUser;
					context.patchState(state);
				} else if (
					state &&
					state.authUser &&
					state.authUser['https://auth.trovata.io/userinfo/userId'] !== authUser['https://auth.trovata.io/userinfo/userId']
				) {
					/**
					 * This happens when you log in on another workspace app with a different user logged in on this app
					 */
					state.authUser = authUser;
					context.patchState(state);
					// context.dispatch(new ResetTrovataAppStates()); // TODO: Fix bugs that this causes in some components
				}
				this.heapService.setIdentity(authUser.email);
				this.heapService.addUserProperties({
					email: authUser.email,
					isAdmin: authUser.email.includes('admin'),
				});
			}
		});
	}

	private shouldDoNothing(): boolean {
		// auth check based on certain query params or route
		if (this.shouldDoNothingParams() || this.shouldDoNothingRoute()) {
			return true;
		} else {
			return false;
		}
	}

	private shouldDoNothingParams(): boolean {
		// let the LoginComponent handle 'error' && 'error_description' && 'm' query params
		const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
		const params: { [key: string]: string } = Object.fromEntries(urlSearchParams.entries());
		const paramKeys: string[] = Object.keys(params);
		let jpmflow: boolean = false;
		if (paramKeys.includes('m')) {
			jpmflow = true;
		}
		this.heapService.addUserProperties({ jpmFlow: jpmflow });
		if (paramKeys.includes('error' && 'error_description') || paramKeys.includes('m') || paramKeys.includes('bank')) {
			if (paramKeys.includes('m')) {
				window.location.href = 'https://trovata.io/partner/jp-morgan-access/';
			}
			return true;
		} else {
			return false;
		}
	}

	private shouldDoNothingRoute(): boolean {
		// ignore auth checks for these un-authenticated routes
		const unautheticatedRoutes: string[] = ['/customer-deleted', '/home/connections/success', '/home/connections/model-bank-oauth-login'];
		return unautheticatedRoutes.includes(document.location.pathname);
	}

	private getParams(): { [k: string]: string } | void {
		if (window.location.search) {
			const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
			const params: { [k: string]: string } = Object.fromEntries(urlSearchParams.entries());
			return params;
		}
	}
}
