import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Select, Selector } from '@ngxs/store';
import {
	checkToAddStateApiError,
	checkForBadHttpErrorMessage,
	StateApiError,
	TrovataAppState,
	checkToRemoveStateApiError,
} from 'src/app/core/models/state.model';
import { combineLatest, lastValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PaymentsAccount, InstitutionId, InstitutionInstructions, GetPaymentsAccountsResponse, InstitutionInstruction } from '../../../models/account.model';
import { PaymentsAccountsService } from '../../../services/accounts/accounts.service';
import {
	ClearPaymentsAccountsState,
	GetPaymentsAccounts,
	GetInstitutionInstructions,
	InitPaymentsAccountsState,
	PostPaymentsAccountAdminApproval,
	ResetPaymentsAccountsState,
	UpdatePaymentsAccount,
	GetPaymentsAccountById,
} from '../../actions/accounts.actions';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { AdminApprovalService } from 'src/app/shared/services/admin-approval.service';
import {
	AdminApprovalRecordStatus,
	ChangeRequest,
	ChangeRequestType,
	PaymentsRecordType,
	PostAdminApprovalResponse,
} from 'src/app/shared/models/admin-approval.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { PermissionId, PermissionMap } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AccountsListBody } from '../../../models/payment.model';
import { ReadyForReviewSnack, ReadyForReviewSnackParams, sortSnacksByDate } from 'src/app/shared/models/ready-for-review-snack.model';
import { DateTime } from 'luxon';
import { TrovataResourceType, TrovataResourceViewText } from 'src/app/shared/models/trovata.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthUser } from '@trovata/app/core/models/auth.model';

export class PaymentsAccountsStateModel extends EntitledStateModel {
	accounts: PaymentsAccount[];
	institutionInstructions: InstitutionInstructions;
	accountSnacks: ReadyForReviewSnack[];
	apiErrors: StateApiError[];
}

@State<PaymentsAccountsStateModel>({
	name: 'paymentsAccounts',
	defaults: {
		accounts: null,
		institutionInstructions: null,
		accountSnacks: null,
		isCached: false,
		apiErrors: null,
	},
})
@Injectable()
export class PaymentsAccountsState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	@Select(CustomerFeatureState.paymentsEnabled) paymentsEnabled$: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	private authUser$: Observable<AuthUser>;
	private authUser: AuthUser;

	constructor(
		private accountsService: PaymentsAccountsService,
		private store: Store,
		private serializationService: SerializationService,
		private adminApprovalService: AdminApprovalService,
		private sanitizer: DomSanitizer
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.authUser$ = this.store.select((state: TrovataAppState) => state.auth.authUser);
	}

	@Selector()
	static paymentsAccounts(state: PaymentsAccountsStateModel): PaymentsAccount[] {
		return state.accounts;
	}

	@Selector()
	static paymentsAccountsIsCached(state: PaymentsAccountsStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static paymentsAccountsApiErrors(state: PaymentsAccountsStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector()
	static accountSnacks(state: PaymentsAccountsStateModel): ReadyForReviewSnack[] {
		return state.accountSnacks;
	}

	@Action(InitPaymentsAccountsState)
	async initAccountsState(context: StateContext<PaymentsAccountsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const accountsStateIsCached: boolean = this.accountsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$, this.authUser$, this.paymentsEnabled$]).subscribe({
				next: ([appReady, permissions, authUser, paymentsEnabled]: [boolean, PermissionMap, AuthUser, boolean]) => {
					if (!this.isInitialized && appReady && permissions && authUser && paymentsEnabled) {
						this.authUser = authUser;
						if (permissions.has(PermissionId.readAccounts)) {
							if (accountsStateIsCached) {
								const state: PaymentsAccountsStateModel = deserializedState.paymentsAccounts;
								this.postProcessAccountsData(context, state);
							} else if (!accountsStateIsCached && appReady && permissions && permissions.has(PermissionId.readAccounts)) {
								this.initDefaultPaymentsAccountsState(context);
							}
							this.isInitialized = true;
						} else {
							context.patchState({ accounts: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetPaymentsAccounts)
	getAccounts(context: StateContext<PaymentsAccountsStateModel>): Observable<GetPaymentsAccountsResponse> {
		const accountsRequestBody: AccountsListBody = { includeBalance: true };
		return this.accountsService.getAccounts(accountsRequestBody).pipe(
			tap(async (getPaymentsAccountResponse: GetPaymentsAccountsResponse) => {
				checkToRemoveStateApiError(context, GetPaymentsAccounts);
				const accounts: PaymentsAccount[] = getPaymentsAccountResponse.accounts;
				const state: PaymentsAccountsStateModel = context.getState();
				if (!state.institutionInstructions) {
					await lastValueFrom(context.dispatch(new GetInstitutionInstructions()));
				}
				this.postProcessAccountsData(context, null, accounts);
			}),
			catchError(error =>
				throwError(() => checkToAddStateApiError(error, context, GetPaymentsAccounts, 'Payments accounts are down right now. Please try again later.'))
			)
		);
	}

	@Action(GetInstitutionInstructions)
	getInstitutionInstructions(context: StateContext<PaymentsAccountsStateModel>): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const instructions: InstitutionInstructions = await lastValueFrom(this.accountsService.getInstitutionInstructions());
				checkToRemoveStateApiError(context, GetInstitutionInstructions);
				const state: PaymentsAccountsStateModel = context.getState();
				state.institutionInstructions = instructions;
				this.postProcessAccountsData(context, state);
				resolve(true);
			} catch (error) {
				error = checkToAddStateApiError(error, context, GetInstitutionInstructions, 'Institution instructions are down right now. Please try again later.');
				throwError(() => error);
				reject(error);
			}
		});
	}

	@Action(UpdatePaymentsAccount)
	updateAccount(context: StateContext<PaymentsAccountsStateModel>, action: UpdatePaymentsAccount): Observable<PaymentsAccount> {
		return this.accountsService.updateAccount(action.accountToUpdate).pipe(
			tap((account: PaymentsAccount) => {
				const state: PaymentsAccountsStateModel = context.getState();
				const filteredAccounts: PaymentsAccount[] = state.accounts.filter((filterAccount: PaymentsAccount) => filterAccount.accountId !== account.accountId);
				filteredAccounts.push(account);
				this.postProcessAccountsData(context, null, filteredAccounts);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Update account is down right now. Please try again later.')))
		);
	}

	@Action(PostPaymentsAccountAdminApproval)
	postAccountAdminApproval(context: StateContext<PaymentsAccountsStateModel>, action: PostPaymentsAccountAdminApproval): Observable<PostAdminApprovalResponse> {
		return this.adminApprovalService.postAdminApproval(action.adminApproval, PaymentsRecordType.ACCOUNT).pipe(
			tap((response: PostAdminApprovalResponse) => {
				const state: PaymentsAccountsStateModel = context.getState();
				const changeRequest: ChangeRequest = response.changeRequest;
				const approvalActionType: ChangeRequestType = changeRequest.changeType;
				const approvalStatus: AdminApprovalRecordStatus = changeRequest.status;
				const account: PaymentsAccount = response.record as PaymentsAccount;
				if (
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.REJECTED)
				) {
					const filteredAccounts: PaymentsAccount[] = state.accounts.filter((filterAccount: PaymentsAccount) => filterAccount.accountId !== account.accountId);
					filteredAccounts.push(account);
					this.postProcessAccountsData(context, null, filteredAccounts);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Account approval is down right now. Please try again later.')))
		);
	}

	@Action(GetPaymentsAccountById)
	getPaymentsAccountById(context: StateContext<PaymentsAccountsStateModel>, action: GetPaymentsAccountById): Observable<GetPaymentsAccountsResponse> {
		return this.accountsService.getAccountById(action.accountId).pipe(
			tap((getPaymentsAccountsResponse: GetPaymentsAccountsResponse) => {
				const state: PaymentsAccountsStateModel = context.getState();
				const account: PaymentsAccount = getPaymentsAccountsResponse.accounts[0];
				if (account) {
					const filteredAccounts: PaymentsAccount[] = state.accounts.filter((filterAccount: PaymentsAccount) => filterAccount.accountId !== account.accountId);
					filteredAccounts.push(account);
					this.postProcessAccountsData(context, null, filteredAccounts);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Payment is down right now. Please try again later.')))
		);
	}

	@Action(ClearPaymentsAccountsState)
	clearAccountsState(context: StateContext<PaymentsAccountsStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: PaymentsAccountsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetPaymentsAccountsState)
	resetAccountsState(context: StateContext<PaymentsAccountsStateModel>): void {
		context.dispatch(new ClearPaymentsAccountsState());
		context.dispatch(new GetPaymentsAccounts());
	}

	private accountsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedAccountsState: PaymentsAccountsStateModel | undefined = deserializedState.paymentsAccounts;
		if (
			deserializedAccountsState &&
			deserializedAccountsState.accounts &&
			deserializedAccountsState.institutionInstructions &&
			deserializedAccountsState.accountSnacks &&
			deserializedAccountsState.isCached &&
			deserializedAccountsState.apiErrors
		) {
			return true;
		} else {
			return false;
		}
	}

	private initDefaultPaymentsAccountsState(context: StateContext<PaymentsAccountsStateModel>): void {
		const state: PaymentsAccountsStateModel = context.getState();
		state.accounts = [];
		state.accountSnacks = [];
		state.apiErrors = [];
		state.isCached = false;
		context.patchState(state);
		context.dispatch(new GetPaymentsAccounts());
	}

	private postProcessAccountsData(context: StateContext<PaymentsAccountsStateModel>, state?: PaymentsAccountsStateModel, accounts?: PaymentsAccount[]): void {
		const stateToProcess: PaymentsAccountsStateModel = state ? state : context.getState();
		const accountsToProcess: PaymentsAccount[] = accounts ? accounts : stateToProcess.accounts;
		const accountNotificationSnacks: ReadyForReviewSnack[] = this.getSnacksAndSetOtherData(accountsToProcess, stateToProcess.institutionInstructions);
		const sortedAccounts: PaymentsAccount[] = this.sortAccountsAlphabetically(accountsToProcess);
		stateToProcess.accountSnacks = accountNotificationSnacks;
		stateToProcess.accounts = sortedAccounts;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private getSnacksAndSetOtherData(accounts: PaymentsAccount[], institutionInstructions: InstitutionInstructions): ReadyForReviewSnack[] {
		let accountNotificationSnacks: ReadyForReviewSnack[] = [];
		accounts.forEach((account: PaymentsAccount) => {
			account.institutionInstruction = this.setAccountInstitutionInstructions(account, institutionInstructions);
			account.needsReview = this.checkIfAccountNeedsReview(account);
			if (account.needsReview) {
				const requestor: string = 'A user '; // TODO: Update with actual user name once apis are updated
				const actionText: string = `${account.changeRequest.changeType.toLocaleLowerCase()}d an `;
				const accountNameText: string = `account (${account.name})`;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${accountNameText}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(account.changeRequest.createdAt).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: account,
					resourceId: account.accountId,
					resourceType: TrovataResourceType.paymentsAccount,
					resourceViewText: TrovataResourceViewText.Account,
					sortByDate: account.changeRequest.createdAt,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				accountNotificationSnacks.push(snack);
			}
		});
		accountNotificationSnacks = sortSnacksByDate(accountNotificationSnacks);
		return accountNotificationSnacks;
	}

	private setAccountInstitutionInstructions(account: PaymentsAccount, institutionInstructions: InstitutionInstructions): InstitutionInstruction | undefined {
		switch (account.institution) {
			case InstitutionId.BOFA:
				return institutionInstructions.BOFA;
			case InstitutionId.JPM:
				return institutionInstructions.JPM;
			case InstitutionId.WFB:
				return institutionInstructions.WFB;
			case InstitutionId.SVB:
				return institutionInstructions.SVB;
			case InstitutionId.PNC:
				return institutionInstructions.PNC;
			default:
				return undefined;
		}
	}

	private checkIfAccountNeedsReview(account: PaymentsAccount): boolean {
		if (account.changeRequest && account.changeRequest.createdBy !== this.authUser['https://auth.trovata.io/userinfo/userId']) {
			return true;
		}
		return false;
	}

	private sortAccountsAlphabetically(accounts: PaymentsAccount[]): PaymentsAccount[] {
		const sortedAccounts: PaymentsAccount[] = accounts.sort((accountA: PaymentsAccount, accountB: PaymentsAccount) => {
			if (accountA.name < accountB.name) {
				return -1;
			} else if (accountA.name > accountB.name) {
				return 1;
			}
			return 0;
		});
		return sortedAccounts;
	}
}
