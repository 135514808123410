import { ResourceType, Share } from '../../models/share.model';

export class InitShareState {
	static readonly type: string = '[Share] InitShareState';
}

export class GetSharesForResourceId {
	static readonly type: string = '[Share] GetSharesForResourceId';
	constructor(
		public resourceType: ResourceType,
		public resourceId: string
	) {}
}

export class GetSharesForResource {
	static readonly type: string = '[Share] GetSharesForResource';
	constructor(public resourceType: ResourceType) {}
}

export class UpdateShare {
	static readonly type: string = '[Share] UpdateShare';
	constructor(public share: Share) {}
}

export class CreateShare {
	static readonly type: string = '[Share] CreateShare';
	constructor(public share: Share) {}
}

export class DeleteShare {
	static readonly type: string = '[Share] DeleteShare';
	constructor(
		public shareId: string,
		public resourceId: string,
		public resourceType: ResourceType
	) {}
}

export class ClearShareState {
	static readonly type: string = '[Share] ClearShareState';
}
