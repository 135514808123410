import { Rounding } from 'src/app/shared/models/rounding.model';
import { DateTime } from 'luxon';
import { CashPositionColumnConfig } from './paginated-cash-positions-acct-table-view.model';
import { AccountTagType } from '../../transactions/models/tag.model';

export class CashPositionPagePreferences {
	cashPositionFavorites: string[];
	cashPositionIndividualPreferences: {
		[cashPositionId: string]: CashPositionPreference;
	};
}

export class CashPositionPreference {
	columns: CashPositionColumnConfig[];
	grouping: CashPositionGrouping;
	gridCurrencyColumn: boolean;
	gridCurrencySymbols: boolean;
}

export enum CashPositionGrouping {
	currency = 'currency',
	institutionId = 'institutionId',
	accountId = 'accountId',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	entity = 'entity',
	region = 'region',
	division = 'division',
	accountType = 'accountType',
}

export class CashPositionGroupType {
	value: CashPositionGrouping;
	view: string;
}

export const acctGroupTypes: CashPositionGroupType[] = [
	{ value: CashPositionGrouping.accountId, view: 'Account' },
	{ value: CashPositionGrouping.institutionId, view: 'Bank' },
	{ value: CashPositionGrouping.entityId, view: 'Entity Alias' },
	{ value: CashPositionGrouping.entityRegion, view: 'Entity Region' },
	{ value: CashPositionGrouping.entityDivision, view: 'Entity Division' },
	{ value: CashPositionGrouping.entity, view: AccountTagType.entity },
	{ value: CashPositionGrouping.region, view: AccountTagType.region },
	{ value: CashPositionGrouping.division, view: AccountTagType.division },
	{ value: CashPositionGrouping.currency, view: 'Currency' },
	{ value: CashPositionGrouping.accountType, view: 'Account Type' },
];

export const DefaultCashPositionCloseHour: number = 20;

export const CashPositionIteration: number = 2;
export class CashPosition {
	cashPositioningId: string;
	name: string;
	currency: string;
	includeWeekends: boolean;
	balanceRounding: Rounding;
	accounts: CashPositionAccount[];
	lastModifiedDate: string;
	createdAt: string;
	closeHour: number;
	timezone: string;
	version?: number;
	data?: CashPositionData;
	latestVersion?: CashPositionData;
	versions?: CashPositionVersion[];
	versionOverviews?: CashPositionVersionOverview[];
	dataErrorMessage?: string;
	versionsErrorMessage?: string;
	latestVersionErrorMessage?: string;
	versionOverviewsErrorMessage?: string;
}

export class CashPositionAccount {
	accountId: string;
	closingBalance?: string;
	openingBalance?: string;
	threshold?: number;
}

export enum CashPositionClosingBal {
	currentDayClosingLedger = 'currentDayClosingLedger',
	currentDayClosingAvailable = 'currentDayClosingAvailable',
	nextDayOpeningLedger = 'nextDayOpeningLedger',
	nextDayOpeningAvailable = 'nextDayOpeningAvailable',
}

export class CashPositionBalanceOpt<T> {
	balanceOpt: T;
	display: string;
	default?: boolean;
}

export const CashPositionClosingBalanceOpts: CashPositionBalanceOpt<CashPositionClosingBal>[] = [
	{
		balanceOpt: CashPositionClosingBal.currentDayClosingLedger,
		display: 'Current day closing ledger',
		default: true,
	},
	{
		balanceOpt: CashPositionClosingBal.currentDayClosingAvailable,
		display: 'Current day closing available',
	},
	{
		balanceOpt: CashPositionClosingBal.nextDayOpeningAvailable,
		display: 'Next day opening available',
	},
	{
		balanceOpt: CashPositionClosingBal.nextDayOpeningLedger,
		display: 'Next day opening ledger',
	},
];

export enum CashPositionOpeningBal {
	currentDayOpeningLedger = 'currentDayOpeningLedger',
	currentDayOpeningAvailable = 'currentDayOpeningAvailable',
	currentDayCurrentAvailable = 'currentDayCurrentAvailable',
	previousDayClosingLedger = 'previousDayClosingLedger',
	previousDayClosingAvailable = 'previousDayClosingAvailable',
}

export const CashPositionOpeningBalanceOpts: CashPositionBalanceOpt<CashPositionOpeningBal>[] = [
	{
		balanceOpt: CashPositionOpeningBal.currentDayCurrentAvailable,
		display: 'Current day current available',
	},
	{
		balanceOpt: CashPositionOpeningBal.currentDayOpeningAvailable,
		display: 'Current day opening available',
	},
	{
		balanceOpt: CashPositionOpeningBal.currentDayOpeningLedger,
		display: 'Current day opening ledger',
		default: true,
	},
	{
		balanceOpt: CashPositionOpeningBal.previousDayClosingAvailable,
		display: 'Previous day closing available',
	},
	{
		balanceOpt: CashPositionOpeningBal.previousDayClosingLedger,
		display: 'Previous day closing ledger',
	},
];

export class CashPositionListResponse {
	reports: CashPosition[];
}

export class CashPositionVersionsResponse {
	versions: CashPositionVersion[];
}

export class CashPositionVersionOverviewResponse {
	versions: CashPositionVersionOverview[];
}

export enum CashPositionVersionStatus {
	latest = 'Latest',
	closed = 'Closed',
}

export class CashPositionVersion {
	versionId: string;
	createdAt?: string;
	createdBy?: string;
	targetDate?: string;
	accounts?: CashPositionAccountData[];
	totals?: CashPositionCurrencyTotal[];
}

export interface CashPositionVersionOverview {
	date?: string;
	delta: number;
	targetBalance: number;
	expectedBalance: number;
	acctsBelowTarget?: number;
}

export class CashPositionData {
	groups?: CashPositionGroup[];
	versionId?: string;
	createdAt?: string;
	createdBy?: string;
}

export class CashPositionGroup {
	groupValue: string;
	groupType: string;
	data: {
		accounts: CashPositionAccountData[];
		totals: CashPositionCurrencyTotal[];
	};
}

export class CashPositionVersionPostBody {
	accounts: CashPositionAccountData[];
	totals: CashPositionCurrencyTotal[];
}

export class CashPositionVersionResponseBody {
	versionId: string;
	createdAt: string;
	createdBy: string;
	accounts: CashPositionAccountData[];
	totals: CashPositionCurrencyTotal[];
}

export class CashPositionCurrencyTotal {
	currency: string;
	openingBalance: number;
	closingBalance: number;
	currentTransaction: number;
	assumedTransactions: number;
	expectedBalance: number;
	targetBalance: number;
	delta: number;
}

export class CashPositionAccountData {
	accountId: string;
	institutionId: string;
	entityGroupingId: string;
	regionGroupingId: string;
	divisionGroupingId: string;
	currency: string;
	comment: string[];
	openingBalance: number;
	closingBalance: number;
	currentTransaction: number;
	assumedTransactions: number;
	assumedTransactionsList: AccountAssumedTransactions;
	expectedBalance: number;
	targetBalance: number;
	delta: number;
}

export class AccountAssumedTransactions {
	prefillLabels: boolean;
	prefillValues: boolean;
	assumptions: AssumedTransaction[];
}

export class AssumedTransaction {
	label: string;
	value: number;
	reconciled: boolean = false;
}

// all methods returning dates should be in the cash position saved timezone
// for display, they must be converted .toLocal()
// for server interaction, convert .toUTC()
export class CashPositionTimezone {
	closeHour: number;
	timezone: string;
	versionDate: DateTime;

	constructor(closeHour: number, timezone: string, versionDate?: string) {
		this.closeHour = closeHour;
		this.timezone = timezone;
		if (versionDate) {
			this.versionDate = DateTime.fromISO(versionDate, {
				zone: 'UTC',
			}).setZone(this.timezone);
		}
	}

	setCloseHour(closeHour: number): void {
		this.closeHour = closeHour;
		this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	// Autosaves must happen the moment BEFORE close time, so if close is 12 PM, autosave is 11:59:59.99999,
	// whereas opening time happens at closing time, 12:00:00.0000

	getNextClose(): DateTime {
		let nextClose: DateTime = (this.versionDate || DateTime.now())
			.setZone(this.timezone)
			.set({ hour: this.closeHour - 1 })
			.endOf('hour');
		if (this.getIsDayClosed()) {
			nextClose = nextClose.plus({ days: 1 });
		}
		return nextClose;
	}

	getPreviousClose(): DateTime {
		return this.getNextClose().minus({ days: 1 });
	}

	getOpenTime(): DateTime {
		return this.getPreviousClose().plus({ hour: 1 }).startOf('hour');
	}

	// transaction date according to Curtis is the date of the period's close in its local timezone
	getTransactionDate(): string {
		if (this.versionDate) {
			return this.versionDate.toFormat('yyyy-MM-dd');
		} else {
			return this.getNextClose().plus({ hour: 1 }).startOf('hour').toFormat('yyyy-MM-dd');
		}
	}

	getIsDayClosed(): boolean {
		return this.closeHour <= (this.versionDate || DateTime.local().setZone(this.timezone)).hour;
	}
}
