import { GlCodePayload, BulkGlCodesPayload } from '../../models/gl-code.model';
import { Tag } from '../../models/tag.model';

export class InitGlCodeState {
	static readonly type: string = '[GlCode] InitGlCodeState';
}

export class ClearGlCodeState {
	static readonly type: string = '[GlCode] ClearGlCodeState';
}

export class ResetGlCodeState {
	static readonly type: string = '[GlCode] ResetGlCodeState';
}

export class GetGlCodes {
	static readonly type: string = '[GlCode] GetGlCode';
	constructor(public glTags?: Tag[]) {}
}

export class CreateGlCode {
	static readonly type: string = '[GlCode] CreateGlCode';
	constructor(public glCodePayload: GlCodePayload) {}
}

export class CreateBulkGlCodes {
	static readonly type: string = '[GlCode] CreateBulkGlCodes';
	constructor(public bulkGlCodePayload: BulkGlCodesPayload) {}
}

export class DeleteGlCode {
	static readonly type: string = '[GlCode] DeleteGlCode';
	constructor(public glCodeId: string) {}
}
export class UpdateGlCode {
	static readonly type: string = '[GlCode] UpdateGlCode';
	constructor(
		public glCodePayload: GlCodePayload,
		public glCodeId: string
	) {}
}

export class ClearLastCreatedGlCodeId {
	static readonly type: string = '[GlCode] ClearLastCreatedGlCodeId';
}

export class ClearLastCreatedBulkGlCodeIds {
	static readonly type: string = '[GlCode] ClearLastCreatedBulkGlCodeIds';
}

export class SetLastCreatedGlCodeId {
	static readonly type: string = '[GlCode] SetLastCreatedGlCodeId';
	constructor(public id: string) {}
}

export class SetLastCreatedBulkGlCodeIds {
	static readonly type: string = '[GlCode] SetLastCreatedBulkGlCodeIds';
	constructor(public ids: string[]) {}
}
