import { TqlService } from 'src/app/shared/services/tql.service';
import { Tag, TagTreeNode, TagType, TagVersion } from './tag.model';

export class TagTree {
	_tags: Tag[];
	visibleTags: Tag[];
	tagNodes: TagTreeNode[];
	flatTree: string[];

	private tagsDict: Map<string, Tag>;
	private isChildDict: { [id: string]: boolean };
	private completeTree: TagTreeNode[];

	constructor(
		public tags: Tag[],
		private tqlService: TqlService,
		private searchTerm?: string
	) {
		this._tags = tags;
		this.tagsDict = new Map();
		this._tags.forEach(tag => (this.tagsDict[tag.tagId] = tag));
		this.search(searchTerm);
	}

	search(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.visibleTags = this.tags?.filter((tag: Tag) => this.tagVisibleInSearch(tag)) || [];
		this.isChildDict = {};
		this.createCompleteTree();
		this.generateTagNodes();
	}

	private generateTagNodes() {
		this.completeTree = this.completeTree.filter((tagLineage: TagTreeNode) => !this.isChildDict[tagLineage.tag.tagId]);
		this.sortLineage(this.completeTree);
		this.tagNodes = this.completeTree;
		this.flatTree = this.flattenTree(this.tagNodes);
	}

	private sortLineage(lineages: TagTreeNode[]): void {
		lineages.sort((a: TagTreeNode, b: TagTreeNode) => {
			const titleA: string = a.tag.tagTitle.trim();
			const titleB: string = b.tag.tagTitle.trim();

			return titleA.localeCompare(titleB, undefined, { sensitivity: 'accent' });
		});

		for (const lineage of lineages) {
			if (lineage.childNodes) {
				this.sortLineage(lineage.childNodes);
			}
		}
	}

	private getTagLineage(tagIds: string[], parent?: Tag, level: number = 0): TagTreeNode[] {
		const lineages: TagTreeNode[] = [];
		for (const tagId of tagIds) {
			if (level) {
				this.isChildDict[tagId] = true;
			} else if (this.isChildDict[tagId]) {
				// if tag is a child and a true parent node is being built you can skip it
				continue;
			}
			const currentTag: Tag = this.tagsDict[tagId];
			if (currentTag && this.tagVisibleInSearch(currentTag)) {
				const childTagIds: string[] =
					currentTag.tagType !== TagType.transaction || currentTag.version === TagVersion.v2
						? currentTag.tagMetadata.tags
						: this.tqlService.extractChildTagsFromTQLExpression(currentTag.tagMetadata.tql?.expression);
				const currentNode: TagTreeNode = {
					tag: currentTag,
					id: currentTag.tagId,
					childNodes: childTagIds.length ? this.getTagLineage(childTagIds, currentTag, level + 1) : null,
					parent: parent || null,
					level,
				};
				if (currentNode.childNodes && currentNode.childNodes.length === 0) {
					currentNode.childNodes = null;
				}
				lineages.push(currentNode);
			}
		}
		return lineages;
	}

	private tagVisibleInSearch(tag: Tag): boolean {
		return !this.searchTerm?.length || (tag.tagTitle || '').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
	}

	private createCompleteTree() {
		const tagIdArr: string[] = this.visibleTags.map((tag: Tag) => tag.tagId);
		this.completeTree = this.getTagLineage(tagIdArr);
	}

	private flattenTree(tagLineages: TagTreeNode[]): string[] {
		return tagLineages.flatMap((tagLineage: TagTreeNode) => {
			const flatTree: string[] = [tagLineage.tag.tagId];

			if (tagLineage.childNodes) {
				return flatTree.concat(this.flattenTree(tagLineage.childNodes));
			}
			return flatTree;
		});
	}
}
