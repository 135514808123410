export enum AnalyticsUserActions {
	/* eslint-disable-next-line @typescript-eslint/naming-convention */

	// Login
	trovataAppLoad = 'trovata-app-load',

	// Transactions
	transactionsViewList = 'transactions-list-view',
	transactionsViewDetails = 'transactions-details-view',

	// Reports
	reportsListView = 'reports-list-view',
	reportsCreate = 'reports-create',
	reportsView = 'reports-view',
	reportsUpdate = 'reports-update',
	reportsDelete = 'reports-delete',

	// Tags
	tagsTreeView = 'tags-tree-view',
	tagsCreate = 'tags-create',
	tagsView = 'tags-view',
	tagsUpdate = 'tags-update',
	tagsDelete = 'tags-delete',

	// GL Tags
	glTagsCreate = 'gl-tags-create',
	glTagsView = 'gl-tags-view',
	glTagsUpdate = 'gl-tags-update',
	glTagsDelete = 'gl-tags-delete',

	// Entities
	entitiesTreeView = 'entities-tree-view',
	entitiesView = 'entities-view',
	entitiesUpdate = 'entities-update',
	entitiesDelete = 'entities-delete',

	// Forecasts
	forecastsListView = 'forecasts-list-view',
	forecastsView = 'forecasts-view',
	forecastsCreate = 'forecasts-create',
	forecastsUpdate = 'forecasts-update',
	forecastsDelete = 'forecasts-delete',

	forecastsInvoicesListView = 'forecasts-invoices-list-view',

	forecastsStreamsListView = 'forecasts-streams-list-view',
	forecastsStreamsView = 'forecasts-streams-view',
	forecastsStreamsCreate = 'forecasts-streams-create',
	forecastsStreamsUpdate = 'forecasts-streams-update',
	forecastsStreamsDataUpdate = 'forecasts-streams-data-update',
	forecastsStreamsDelete = 'forecasts-streams-delete',

	// Cash Position
	cashPositionListView = 'cash-position-list-view',
	cashPositionView = 'cash-position-view',
	cashPositionCreate = 'cash-position-create',
	cashPositionUpdate = 'cash-position-update',
	cashPositionDelete = 'cash-position-delete',
	cashPositionVersionSave = 'cash-position-version-save',

	// Payments
	paymentsInitiate = 'payments-initiate',

	// Dashboard
	dashboardWidgetAdd = 'dashboard-widget-add',

	// Settings
	menuSettingsClicked = 'menu-settings-clicked',
	menuChangeCurrencyClicked = 'menu-change-currency-clicked',
	menuTrovataAIClicked = 'menu-trovata-ai-clicked',
	menuDashboardClicked = 'menu-dashboard-clicked',
	menuEntitiesClicked = 'menu-entities-clicked',
	menuBalancesClicked = 'menu-balances-clicked',
	menuBalancesGroupsClicked = 'menu-balances-groups-clicked',
	menuCashPositionClicked = 'menu-cash-position-clicked',
	menuTransactionsClicked = 'menu-transactions-clicked',
	menuTransactionsTagsClicked = 'menu-transactions-tags-clicked',
	menuTransactionsGLTagsClicked = 'menu-transactions-gl-tags-clicked',
	menuAnalysisClicked = 'menu-analysis-clicked',
	menuInsightsClicked = 'menu-insights-clicked',
	menuInsightsExplorerClicked = 'menu-insights-explorer-clicked',
	menuReportsClicked = 'menu-reports-clicked',
	menuReconciliationClicked = 'menu-reconciliation-clicked',
	menuForecastsClicked = 'menu-forecasts-clicked',
	menuForecastsStreamsClicked = 'menu-forecasts-streams-clicked',
	menuForecastsInvoicesClicked = 'menu-forecasts-invoices-clicked',
	menuWorkbooksClicked = 'menu-workbooks-clicked',
	menuPaymentsClicked = 'menu-payments-clicked',
	menuPaymentsWorkflowsClicked = 'menu-payments-workflows-clicked',
	menuPaymentsTemplatesClicked = 'menu-payments-templates-clicked',
	menuPaymentsAccountsClicked = 'menu-payments-accounts-clicked',
	menuInvestmentsClicked = 'menu-investments-clicked',
	menuInvestmentsMorganMoneyClicked = 'menu-investments-morgan-money-clicked',
	menuDeveloperClicked = 'menu-developer-clicked',
}

export enum UserActions {
	/* eslint-disable-next-line @typescript-eslint/naming-convention */
	login = 'login',

	JPMFlowDemoClicked = 'JPMFlowDemoClicked',

	balancesAccountsSearch = 'balancesSearchAccounts',

	dashboardSearch = 'dashboardSearch',

	snackAddReport = 'snackAddReport',
	snackAddReconReport = 'snackAddReconReport',
	snackAddForecast = 'snackAddForecast',
	snackAddTag = 'snackAddTag',
	snackDelete = 'snackDelete',
	snackMove = 'snackMove',
	snackEditRestoreDefault = 'snackEditRestoreDefault',

	forecastSearch = 'forecastSearch',
	forecastViewForecast = 'forecastViewForecast',

	reportsSearch = 'reportsSearch',
	reportsViewReport = 'reportsViewReport',
	reportsCreateReport = 'reportsCreateReport',
	reportsAddToDashboard = 'reportsAddToDashboard',
	reportsDuplicateReport = 'reportsDuplicateReport',

	accountView = 'accountView',

	balancesEditBalanceGrid = 'balancesEditBalanceGrid',
	balancesEditBalanceGridPasted = 'balancesEditBalanceGridPasted',
	balancesEditBulkBalance = 'balancesEditBulkBalance',
	balancesEditAccountBalance = 'balancesEditAccountBalance',
	balancesPastedBulkBalance = 'balancesPastedBulkBalance',
	balancesPastedAccountBalance = 'balancesPastedAccountBalance',

	searchbarSearch = 'searchbarSearch',

	transactionAddTag = 'transactionAddTag',
	transactionManualUpload = 'transactionManualUpload',

	reconViewRecon = 'reconViewRecon',
	reconSaveReport = 'reconSaveReport',
	reconItems = 'reconItems',
	reconTagsBreakdown = 'reconTagsBreakdown',
	reconDuplicateReport = 'reconDuplicateReport',
	reconAddToDashboard = 'reconAddToDashboard',
	reconToggle = 'reconToggle',

	analysisTypeBalance = 'analysisTypeBalance',
	analysisTypeTransaction = 'analysisTypeTransaction',
	analysisTypeInvoice = 'analysisTypeInvoice',

	passwordReset = 'passwordReset',
}
