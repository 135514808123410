import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Select, Selector } from '@ngxs/store';
import { StateApiError, TrovataAppState } from 'src/app/core/models/state.model';
import { combineLatest, Observable, Subscription, throwError, firstValueFrom } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
	ClearDeveloperPortalApplicationsState,
	ClearLastCreatedDeveloperPortalApplicationId,
	CreateDeveloperPortalApplication,
	DeleteDeveloperPortalApplication,
	GetDeveloperPortalApplications,
	InitDeveloperPortalApplicationsState,
	SetLastCreatedDeveloperPortalApplicationId,
	UpdateDeveloperPortalApplication,
} from '../actions/applications.actions';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { PermissionMap, PermissionId } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { DeveloperApplication } from '../../models/developer-application.model';
import { DeveloperPortalApplicationsService } from '../../services/applications.service';
import { HttpResponse } from '@angular/common/http';

export class DeveloperPortalApplicationsStateModel extends EntitledStateModel {
	applications: DeveloperApplication[];
	apiErrors: StateApiError[];
	lastCreatedApplicationId: string;
}

@State<DeveloperPortalApplicationsStateModel>({
	name: 'developerApplications',
	defaults: {
		applications: null,
		isCached: false,
		apiErrors: null,
		lastCreatedApplicationId: null,
	},
})
@Injectable()
export class DeveloperPortalApplicationsState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;

	constructor(
		private applicationsService: DeveloperPortalApplicationsService,
		private store: Store,
		private serializationService: SerializationService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static developerApplications(state: DeveloperPortalApplicationsStateModel): DeveloperApplication[] {
		return state.applications;
	}

	@Selector()
	static developerPortalApplicationsIsCached(state: DeveloperPortalApplicationsStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static developerPortalApplicationsApiErrors(state: DeveloperPortalApplicationsStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector()
	static lastCreatedApplicationId(state: DeveloperPortalApplicationsStateModel): string {
		return state.lastCreatedApplicationId;
	}

	@Action(InitDeveloperPortalApplicationsState)
	async InitDeveloperPortalApplicationsState(context: StateContext<DeveloperPortalApplicationsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const applicationsStateIsCached: boolean = this.applicationsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (!this.isInitialized && appReady && permissions) {
						if (permissions.has(PermissionId.manageDeveloperPortalApps)) {
							if (applicationsStateIsCached) {
								const state: DeveloperPortalApplicationsStateModel = deserializedState.developerApplications;
								this.postProcessDeveloperPortalApplicationsData(context, state);
							} else if (!applicationsStateIsCached && appReady) {
								this.initDefaultDeveloperPortalApplicationsState(context);
							}
							this.isInitialized = true;
						} else {
							context.patchState({
								applications: [],
								isCached: false,
							});
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetDeveloperPortalApplications)
	getApplications(context: StateContext<DeveloperPortalApplicationsStateModel>) {
		let state = context.getState();
		return this.applicationsService.getApplications().pipe(
			tap(async (response: HttpResponse<DeveloperApplication[]>) => {
				state = context.getState();
				const applications: DeveloperApplication[] = response.body;
				this.postProcessDeveloperPortalApplicationsData(context, null, applications);
				state.isCached = true;
				context.patchState(state);
			}),
			catchError(error => {
				state = context.getState();
				context.patchState(state);
				return throwError(() => error);
			})
		);
	}

	@Action(ClearDeveloperPortalApplicationsState)
	clearApplicationsState(context: StateContext<DeveloperPortalApplicationsStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: DeveloperPortalApplicationsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(CreateDeveloperPortalApplication)
	createApplication(context: StateContext<DeveloperPortalApplicationsStateModel>, action: CreateDeveloperPortalApplication): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const createApplicationResponse: HttpResponse<{ appId: string }> = await firstValueFrom(
					this.applicationsService.createApplication(action.applicationPayload)
				);
				const applicationId: string = createApplicationResponse.body.appId;

				if (applicationId) {
					context.patchState({
						lastCreatedApplicationId: applicationId,
					});
					this.store.dispatch(new SetLastCreatedDeveloperPortalApplicationId(applicationId));
					await firstValueFrom(context.dispatch(new GetDeveloperPortalApplications()));
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateDeveloperPortalApplication)
	updateApplication(context: StateContext<DeveloperPortalApplicationsStateModel>, action: UpdateDeveloperPortalApplication): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.applicationsService.updateApplication(action.application));
				this.store.dispatch(new GetDeveloperPortalApplications());
				resolve();
			} catch (error) {
				reject(new Error('Could not update Application'));
			}
		});
	}

	@Action(DeleteDeveloperPortalApplication)
	deleteApplication(context: StateContext<DeveloperPortalApplicationsStateModel>, action: DeleteDeveloperPortalApplication): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const applicationToDelete: DeveloperApplication = action.application;
				const state: DeveloperPortalApplicationsStateModel = context.getState();
				const applications: DeveloperApplication[] = [...state.applications];
				const i: number = applications.findIndex((application: DeveloperApplication) => application.appId === applicationToDelete.appId);
				if (i > -1) {
					applications.splice(i, 1);
				}
				await firstValueFrom(this.applicationsService.deleteApplication(applicationToDelete));
				await context.patchState({ applications: applications });
				this.store.dispatch(new GetDeveloperPortalApplications());
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Tag'));
			}
		});
	}

	@Action(ClearLastCreatedDeveloperPortalApplicationId)
	clearLastCreatedTagId(context: StateContext<DeveloperPortalApplicationsStateModel>): void {
		context.patchState({ lastCreatedApplicationId: null });
	}

	@Action(SetLastCreatedDeveloperPortalApplicationId)
	setLastCreatedId(context: StateContext<DeveloperPortalApplicationsStateModel>, action: SetLastCreatedDeveloperPortalApplicationId): void {
		context.patchState({ lastCreatedApplicationId: action.id });
	}

	private applicationsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedApplicationsState: DeveloperPortalApplicationsStateModel | undefined = deserializedState.developerApplications;
		if (
			deserializedApplicationsState &&
			deserializedApplicationsState.applications &&
			deserializedApplicationsState.isCached &&
			deserializedApplicationsState.apiErrors
		) {
			return true;
		} else {
			return false;
		}
	}

	private initDefaultDeveloperPortalApplicationsState(context: StateContext<DeveloperPortalApplicationsStateModel>): void {
		const state: DeveloperPortalApplicationsStateModel = context.getState();
		state.applications = [];
		state.apiErrors = [];
		state.isCached = false;
		context.patchState(state);
		context.dispatch(new GetDeveloperPortalApplications());
	}

	private postProcessDeveloperPortalApplicationsData(
		context: StateContext<DeveloperPortalApplicationsStateModel>,
		state?: DeveloperPortalApplicationsStateModel,
		applications?: DeveloperApplication[]
	): void {
		const stateToProcess: DeveloperPortalApplicationsStateModel = state ? state : context.getState();
		const applicationsToProcess: DeveloperApplication[] = applications ? applications : stateToProcess.applications;
		const developerApplications: DeveloperApplication[] = this.sortApplicationsAlphabetically(applicationsToProcess);
		stateToProcess.applications = developerApplications;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private sortApplicationsAlphabetically(applications: DeveloperApplication[]): DeveloperApplication[] {
		const sortedApplications: DeveloperApplication[] = applications.sort((applicationA: DeveloperApplication, applicationB: DeveloperApplication) => {
			if (applicationA.name < applicationB.name) {
				return -1;
			} else if (applicationA.name > applicationB.name) {
				return 1;
			}
			return 0;
		});
		return sortedApplications;
	}
}
