import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HeapAnalyticsService {
	private theWindow = <any>window;

	constructor() {
		this.addUserProperties({ environment: environment.environmentName });
	}

	setIdentity(email: string): void {
		this.theWindow.heap?.identify(email);
	}

	addUserProperties(params: unknown): void {
		this.theWindow.heap?.addUserProperties(params);
	}

	userAction(action: string): void {
		this.theWindow.heap?.track('User Action', {
			userAction: action,
		});
	}

	apiError(error: string): void {
		this.theWindow.heap?.track('API Error', {
			error: error,
		});
	}
}
