import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { catchError, EMPTY, lastValueFrom, mergeMap, Observable, Subject, Subscription, throwError } from 'rxjs';
import {
	TrovataAppState,
	TROVATA_APP_STATES_TO_CLEAR,
	TROVATA_APP_STATES_TO_INIT,
	TROVATA_FEATURE_STATES_TO_CLEAR,
	TROVATA_FEATURE_STATES_TO_INIT,
	TROVATA_PAYMENTS_STATES_TO_CLEAR,
	TROVATA_PAYMENTS_STATES_TO_INIT,
	TrovataAppStateKey,
} from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import {
	ClearCoreState,
	ClearPaymentsStates,
	ClearTrovataAppStates,
	ClearTrovataFeatureState,
	InitCoreState,
	InitPaymentsStates,
	InitTrovataAppStates,
	InitTrovataFeatureState,
	ResetCoreState,
	ResetPaymentsStates,
	ResetTrovataAppStates,
	ResetTrovataFeatureState,
	SetAppReady,
	SetUATEnvironment,
} from '../../actions/core.actions';
import * as LocalForage from 'localforage';
import { environment } from 'src/environments/environment';

export class CoreStateModel {
	appReady: boolean;
	uatEnvironment: boolean;
}

export class EntitledStateModel {
	isCached: boolean;
}

@State<CoreStateModel>({
	name: 'core',
	defaults: {
		appReady: null,
		uatEnvironment: null,
	},
})
@Injectable()
export class CoreState implements NgxsOnInit {
	private authenticated$: Observable<boolean>;
	private authenticatedSub: Subscription;

	constructor(
		private store: Store,
		private serializationService: SerializationService
	) {
		this.authenticated$ = this.store.select((state: TrovataAppState) => state.auth.authenticated);
	}

	ngxsOnInit(context: StateContext<CoreStateModel>) {
		context.dispatch(new InitTrovataAppStates());
	}

	@Action(InitCoreState)
	async initCoreState(context: StateContext<CoreStateModel>) {
		try {
			const serializedState: string = await LocalForage.getItem(TrovataAppStateKey);
			let deserializedState: TrovataAppState;
			if (serializedState) {
				this.serializationService.deserializeState(serializedState);
				deserializedState = await this.serializationService.getDeserializedState();
			} else {
				deserializedState = {} as TrovataAppState;
			}

			this.authenticatedSub = this.authenticated$.subscribe(
				(authenticated: boolean) => {
					if (deserializedState && deserializedState.core && deserializedState.core.uatEnvironment && authenticated) {
						context.dispatch(new SetUATEnvironment(deserializedState.core.uatEnvironment));
						context.dispatch(new SetAppReady(true));
					} else if (!deserializedState.core && authenticated) {
						const state: CoreStateModel = context.getState();
						state.appReady = true;
						context.patchState(state);
					}
				},
				(error: Error) => throwError(() => error)
			);
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(SetUATEnvironment)
	setUAT(context: StateContext<CoreStateModel>, action: SetUATEnvironment) {
		return new Promise(resolve => {
			if (action.uatEnvironment) {
				environment.paymentsUat = true;
			} else {
				environment.paymentsUat = false;
			}
			const state: CoreStateModel = context.getState();
			state.uatEnvironment = action.uatEnvironment;
			context.patchState(state);
			resolve(true);
		});
	}

	@Action(SetAppReady)
	setAppReady(context: StateContext<CoreStateModel>, action: SetAppReady) {
		const state: CoreStateModel = context.getState();
		state.appReady = action.appReady;
		context.patchState(state);
	}

	@Action(InitTrovataAppStates)
	initTrovataAppStates(context: StateContext<CoreStateModel>): Promise<void> {
		return this.asyncForEach(TROVATA_APP_STATES_TO_INIT);
	}

	@Action(InitPaymentsStates)
	initPaymentsStates(context: StateContext<CoreStateModel>): Promise<void> | void {
		return this.asyncForEach(TROVATA_PAYMENTS_STATES_TO_INIT);
	}

	@Action(ClearCoreState)
	clearCoreState(context: StateContext<CoreStateModel>) {
		this.authenticatedSub.unsubscribe();
		const state: CoreStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetCoreState)
	resetCoreState(context: StateContext<CoreStateModel>) {
		context.dispatch(new ClearCoreState());
		context.dispatch(new InitCoreState());
	}

	@Action(ClearTrovataAppStates)
	clearTrovataAppStates(context: StateContext<CoreStateModel>) {
		return this.asyncForEach(TROVATA_APP_STATES_TO_CLEAR);
	}

	@Action(InitTrovataFeatureState)
	InitTrovataFeatureState(context: StateContext<CoreStateModel>) {
		return this.asyncForEach(TROVATA_FEATURE_STATES_TO_INIT);
	}

	@Action(ClearTrovataFeatureState)
	clearTrovataFeatureState(context: StateContext<CoreStateModel>) {
		return this.asyncForEach(TROVATA_FEATURE_STATES_TO_CLEAR);
	}

	@Action(ResetTrovataFeatureState)
	async resetTrovataFeatureState(context: StateContext<CoreStateModel>) {
		await this.serializationService.clearFeatureStatesFromDeserializedState();
		await lastValueFrom(context.dispatch(new ClearTrovataFeatureState()));
		await lastValueFrom(context.dispatch(new InitTrovataFeatureState()));
	}

	@Action(ClearPaymentsStates)
	clearPaymentsStates(context: StateContext<CoreStateModel>) {
		return this.asyncForEach(TROVATA_PAYMENTS_STATES_TO_CLEAR);
	}

	@Action(ResetTrovataAppStates)
	async resetTrovataAppStates(context: StateContext<CoreStateModel>) {
		this.serializationService.clearDeserializedState();
		await lastValueFrom(context.dispatch(new ClearTrovataAppStates()));
		await lastValueFrom(context.dispatch(new InitTrovataAppStates()));
	}

	@Action(ResetPaymentsStates)
	async resetPaymentsStates(context: StateContext<CoreStateModel>) {
		await this.serializationService.clearPaymentsStatesFromDeserializedState();
		await lastValueFrom(context.dispatch(new ClearPaymentsStates()));
		await lastValueFrom(context.dispatch(new InitPaymentsStates()));
	}

	private asyncForEach<T>(statesToInit: T[]): Promise<void> {
		const maxConcurrentStateInits: number = 1;
		return new Promise((resolve, reject) => {
			const initState$: Subject<T> = new Subject();
			initState$.pipe(mergeMap((stateToInit: T) => this.store.dispatch(stateToInit).pipe(catchError(error => EMPTY)), maxConcurrentStateInits)).subscribe({
				next: () => {
					// nothing
				},
				error: () => {
					// nothing
				},
				complete: () => {
					resolve();
				},
			});
			statesToInit.forEach(stateToInit => {
				initState$.next(stateToInit);
			});
			initState$.complete();
		});
	}
}
