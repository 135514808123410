export class GenericOptionValue<T = any> {
	option: GenericOption<T>;
	group: GenericOptionGroup<T>;
	selected?: boolean;
}

export class GenericOption<T = any> {
	displayValue: string;
	key?: T;
	id: T;
	disabled?: boolean;
	disabledText?: string;
}

export class GenericOptionGroup<T = any> {
	type: string;
	displayType: string;
	options: GenericOption<T>[];
}
