import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethod, BillingDetails } from '../models/payment.model';

@Injectable({
	providedIn: 'root',
})
export class PaymentMethodsService {
	constructor(private httpClient: HttpClient) {}

	getPaymentMethods(): Observable<{ paymentMethods: PaymentMethod[] }> {
		return this.httpClient.get<{ paymentMethods: PaymentMethod[] }>(environment.trovataAPI('workspace') + '/billing/customer/payment-methods', {
			params: {
				stripeEnvironment: environment.stripe,
			},
		});
	}

	updatePaymentMethod(method: PaymentMethod, makeDefault?: boolean): Observable<{ paymentMethod: PaymentMethod }> {
		return this.httpClient.put<{ paymentMethod: PaymentMethod }>(environment.trovataAPI('workspace') + '/billing/customer/payment-methods', {
			paymentMethodId: method.paymentMethodId,
			billingDetails: method.billingDetails,
			makeDefault: makeDefault || false,
		});
	}

	createPaymentMethod(
		paymentMethodId: string,
		isDefault?: boolean,
		billingDetails?: BillingDetails
	): Observable<{ paymentMethod: PaymentMethod; default: boolean }> {
		return this.httpClient.post<{
			paymentMethod: PaymentMethod;
			default: boolean;
		}>(
			environment.trovataAPI('workspace') + '/billing/customer/payment-methods',
			{
				paymentMethodId,
				billingDetails,
				makeDefault: isDefault || false,
			},
			{
				params: {
					stripeEnvironment: environment.stripe,
				},
			}
		);
	}

	deletePaymentMethods(paymentMethodIds: string[]): Observable<{ paymentMethodIds: string[] }> {
		return this.httpClient.delete<{ paymentMethodIds: string[] }>(environment.trovataAPI('workspace') + '/billing/customer/payment-methods', {
			body: { paymentMethodIds: paymentMethodIds },
		});
	}

	getStripeClientSecret(amount: number): Observable<{ stripeClientSecret: string }> {
		const url = environment.trovataAPI('workspace') + '/billing/customer/payment-methods/intent';
		return this.httpClient.post<{ stripeClientSecret: string }>(
			url,
			{ amount },
			{
				params: {
					stripeEnvironment: environment.stripe,
				},
			}
		);
	}
}
