import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { SearchData, SearchParameter } from '@trovata/app/shared/models/search-parameter.model';
import { Transaction, TransactionGrid, TransactionGridRow } from '../../models/transaction.model';
import { TransactionsService } from '../../services/transactions.service';
import { CollectionView } from '@grapecity/wijmo';
import { CellRange, FlexGrid, ICellTemplateContext } from '@grapecity/wijmo.grid';
import { TAB_DATA } from '../../models/popout-token.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tag } from '../../models/tag.model';
import { ListBox } from '@grapecity/wijmo.input';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { PreferencesService } from '@trovata/app/shared/services/preferences.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AccountsService } from '../../../../shared/services/accounts.service';
import { AccountTargetV3 } from '@trovata/app/shared/models/account-target.model';
import { CurrencyService } from '@trovata/app/shared/services/currency.service';
import { CurrencyDict } from '@trovata/app/shared/models/currency.model';
import { Formatter } from '@trovata/app/shared/utils/formatter';
import { Select } from '@ngxs/store';
import { PermissionMap, PermissionId } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { ColumnConfiguration, DisplayColumnMenu } from '@trovata/app/shared/models/columns.model';
import { TagFacadeService } from '../../services/facade/tag.facade.service';
import { GlTagFacadeService } from '../../services/facade/glTag.facade.service';
import { TransactionsBatchService } from '../../services/transactions-batch.service';
import { DataSource } from '../../../workbooks/models/workbook.model';
import { TagTree } from '../../models/tag-tree.model';
import { TqlService } from '@trovata/app/shared/services/tql.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
	selector: 'app-transactions-grid-dialog',
	templateUrl: './transactions-grid-dialog.component.html',
	styleUrls: ['./transactions-grid-dialog.component.css'],
})
export class TransactionsGridDialogComponent implements OnInit, OnDestroy {
	private destroyed$: Subject<boolean>;
	positionType: string;
	searchData: SearchData = new SearchData();
	private tqlJSONExpression: Object;
	searchParams: SearchParameter[];
	sortFilter: SearchParameter;

	transactionsGrid: TransactionGrid;

	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	userAvailablePermissions: PermissionMap;
	PermissionId = PermissionId;
	tags: Tag[] = [];
	tagSortingOrder: string[] = [];
	glTags: Tag[] = [];
	accounts: AccountTargetV3[] = [];
	isSingleTagColumn: boolean = false;
	transactions: Transaction[] = [];
	currencyDict: CurrencyDict;
	currencyConverted: string;
	transactionGridRows: TransactionGridRow[];
	transactionTotalNumber = 0;
	showFullAccountNumbers = false;
	openTrovataAIGrid: boolean;
	metadataDisplay: string[];
	currentWindow: any;
	DataSource: typeof DataSource;

	loading: boolean = true;
	loadingProgress: number = 0;
	loadingText: string = 'Loading Transactions';

	view: CollectionView;

	// column menu properties
	showMenu = false;
	columnConfigurations: Array<ColumnConfiguration> = [];
	columnConfigSelect: UntypedFormControl = new UntypedFormControl('');
	configInput: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
	isCreateNewConfig: boolean = false;
	isEditConfig: boolean = false;
	displayColumnsMenu: DisplayColumnMenu[] = [];
	filterCtrl: UntypedFormControl = new UntypedFormControl('');
	filteredOptions: Object = {};
	formatter: Formatter = new Formatter();
	defaultColumnsKeys: string[];
	wijmoTemplate: (ctx: ICellTemplateContext) => string;

	gridCols: string[];
	trovataAIFlexGrid: FlexGrid;
	interactionId: string;
	@ViewChild('flex', { static: false }) flex: FlexGrid;
	@ViewChild('trxnColumnPicker') trxnColumnPicker: ListBox;

	constructor(
		private transactionsService: TransactionsService,
		private snackbar: MatSnackBar,
		private preferenceService: PreferencesService,
		private accountService: AccountsService,
		private currencyService: CurrencyService,
		private tagFacadeService: TagFacadeService,
		private glTagFacadeService: GlTagFacadeService,
		private transactionsBatchService: TransactionsBatchService,
		private tqlService: TqlService,
		@Inject(TAB_DATA) public data: any
	) {
		this.destroyed$ = new Subject<boolean>();
		this.searchData = data.searchData;
		this.tqlJSONExpression = data.tqlJSONExpression;
		this.sortFilter = data.sortFilter;
		this.positionType = data.positionType;
		this.currentWindow = data.currentWindow;
		this.DataSource = DataSource;
		this.showFullAccountNumbers = data.showFullAccountNumbers;
		this.openTrovataAIGrid = data.openTrovataAIGrid;
		this.transactionsBatchService.batchProgress$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((batchProgress: number) => (this.loadingProgress = batchProgress * 0.9));
		this.gridCols = data.cols;
		this.interactionId = data.interactionId;
	}

	ngOnInit() {
		if (!this.openTrovataAIGrid) {
			document.querySelectorAll('link, style').forEach(htmlElement => {
				this.currentWindow.document.head.appendChild(htmlElement.cloneNode(true));
			});
			this.setSearchParams();
			this.getAvailablePermissions();
			firstValidValueFrom(this.userAvailablePermissions$).then(() =>
				this.getNeededData().then(() => {
					this.requestTransactions();
				})
			);
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private getAvailablePermissions() {
		this.userAvailablePermissions$.pipe(takeUntil(this.destroyed$)).subscribe((availablePermission: PermissionMap) => {
			this.userAvailablePermissions = availablePermission;
		});
	}

	private setDefaultColumnKeys() {
		this.defaultColumnsKeys = this.flex.columns.map(column => column.binding);
	}

	async requestTransactions() {
		try {
			this.transactions = await this.transactionsBatchService.getBatchTransactions(
				this.searchData.filter,
				this.positionType,
				this.searchParams,
				this.showFullAccountNumbers,
				this.tags,
				this.glTags,
				this.tqlJSONExpression
			);
			this.loadGrid();
		} catch (error) {
			this.snackbar.open('Something went wrong, please try again.', null, {
				duration: 6000,
			});
			this.currentWindow.close();
		}
	}

	getNeededData() {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const dataRequests: Observable<any>[] = [
					this.accountService.getAccounts(),
					this.currencyService.getCurrencies(),
					this.preferenceService.getAllPreferences('global'),
					this.transactionsService.getTransactionsSummary(this.searchData.filter, this.positionType, null, this.searchParams, null, null),
				];
				if (this.userAvailablePermissions.has(PermissionId.readTags)) {
					this.tags = await this.tagFacadeService.getTags();
					this.tagSortingOrder = new TagTree(this.tags, this.tqlService).flatTree;

					if (this.userAvailablePermissions.has(PermissionId.readGlTags)) {
						this.glTags = await this.glTagFacadeService.getGlTags();
					} else {
						this.glTags = [];
					}
				} else {
					this.tags = [];
					this.glTags = [];
				}
				forkJoin(dataRequests).subscribe(
					(res: any) => {
						this.accounts = res[0].body['accounts'];
						this.currencyDict = this.currencyService.cachedCurrenciesDict;
						this.transactionTotalNumber = res[3].body['totalTransactions'];
						this.wijmoTemplate = this.formatter.currencyWijmoCellTemplate(this.currencyDict);
						this.columnConfigurations = res[2]?.body['transactionGridColumnConfigs'] ? res[2]?.body['transactionGridColumnConfigs'] : [];
						resolve();
					},
					err => {
						throw new Error(err);
					}
				);
			} catch (error) {
				this.snackbar.open('Something went wrong, Please try again.', null, {
					duration: 6000,
				});
				this.currentWindow.close();
				reject(error);
			}
		});
	}

	setSearchParams() {
		this.searchParams = [...this.searchData.parameters];

		if (this.sortFilter != null) {
			this.searchParams.push(this.sortFilter);
		}

		if (this.searchData.startDateParameter) {
			this.searchParams.push(this.searchData.startDateParameter);
		}

		if (this.searchData.endDateParameter) {
			this.searchParams.push(this.searchData.endDateParameter);
		}
	}

	loadGrid() {
		this.transactionsGrid = new TransactionGrid(this.transactions, this.accounts, this.currencyDict, true, true, this.tagSortingOrder);
		this.view = new CollectionView(this.transactionsGrid.transactionGridRows);
		this.loadingText = 'Loading Grid';
		const loadInterval = setInterval(() => {
			this.loadingProgress += 5;
			if (this.loadingProgress === 100) {
				setTimeout(() => {
					this.loading = false;
					clearInterval(loadInterval);
				}, 1000);
			}
		}, 2500);
	}

	get textToCopy() {
		if (this.flex) {
			return this.flex.getClipString();
		}
	}

	flexInitialized(flexgrid: FlexGrid) {
		this.setDefaultColumnKeys();
		flexgrid.copied.addHandler((grid: FlexGrid, event) => {
			this.copy();
		});
	}

	copyAll() {
		this.flex.selection = new CellRange(0, 0, this.flex.rows.length - 1, this.flex.columns.length - 1);
		this.copy();
	}

	copy() {
		const tmp = this.currentWindow.document.createElement('textarea');
		tmp.value = this.textToCopy;
		tmp.style.height = '0';
		tmp.style.overflow = 'hidden';
		tmp.style.position = 'fixed';
		this.currentWindow.document.body.appendChild(tmp);
		tmp.focus();
		tmp.select();
		this.currentWindow.document.execCommand('copy');
		this.currentWindow.document.body.removeChild(tmp);
	}

	exportCSV(flex: FlexGrid) {
		const rng = new CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1);
		const csv = flex.getClipString(rng, true, true);
		this.exportFile(csv, 'Transactions.csv');
	}

	exportFile(csv, fileName) {
		const fileType = 'txt/csv;charset=utf-8';
		if ((navigator as any).msSaveBlob) {
			// IE
			(navigator as any).msSaveBlob(
				new Blob([csv], {
					type: fileType,
				}),
				fileName
			);
		} else {
			const downloadElement = document.createElement('a');
			downloadElement.setAttribute('href', 'data:' + fileType + ',' + encodeURIComponent(csv));
			downloadElement.setAttribute('download', fileName);
			downloadElement.style.display = 'none';
			document.body.appendChild(downloadElement);
			downloadElement.click();
			document.body.removeChild(downloadElement);
		}
	}

	deselectGrid() {
		this.flex.select(-1, -1);
	}

	toggleMenu() {
		this.showMenu = !this.showMenu;
	}

	onNewSelectedColumns(columns: string[]) {
		this.defaultColumnsKeys = columns;
		this.transactionsGrid.filterSingleTagColumn(columns);

		this.setColumnVisibility();
	}

	onTrovataGridInitialized(flex: FlexGrid): void {
		this.trovataAIFlexGrid = flex;
	}

	setColumnVisibility(): void {
		this.flex.columns.forEach(column => {
			if (column.binding === 'singleTagColumn') {
				return;
			}

			column.visible = this.defaultColumnsKeys.includes(column.binding);
		});
	}
}
