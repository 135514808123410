import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import { GetUserGroupsResponse, UserGroup, UserGroupPostBody } from '../models/user-group.model';
import { GetFeaturePermissionsResponse } from '../models/feature.model';
import { ChangeRequestBody } from 'src/app/shared/models/admin-approval.model';

@Injectable({
	providedIn: 'root',
})
export class UserGroupService {
	private baseApiUrl: string;

	constructor(private httpClient: HttpClient) {
		this.baseApiUrl = environment.trovataAPI('workspace');
	}

	getUserGroups(): Observable<GetUserGroupsResponse> {
		const maxUserGroupsList: number = 100;
		const body: object = {};
		body['size'] = maxUserGroupsList;
		body['from'] = 0;
		const url: string = `${this.baseApiUrl}/v2/entitlements/customer/user-groups`;
		return this.httpClient
			.get<GetUserGroupsResponse>(url + `?from=${body['from']}&size=${body['size']}`, {
				observe: 'response',
			})
			.pipe(
				switchMap((result: HttpResponse<GetUserGroupsResponse>) => {
					const totalUserGroups: number = result.body.totalUserGroups;
					const requests: Observable<HttpResponse<GetUserGroupsResponse>>[] = [];
					const firstResult: Observable<HttpResponse<GetUserGroupsResponse>> = of(result);
					requests.push(firstResult);
					if (totalUserGroups > maxUserGroupsList) {
						for (let i: number = maxUserGroupsList; i < totalUserGroups; i += maxUserGroupsList) {
							const reqBody: object = JSON.parse(JSON.stringify(body));
							reqBody['from'] = i;
							reqBody['size'] = maxUserGroupsList;
							requests.push(
								this.httpClient
									.get<GetUserGroupsResponse>(url + `?from=${reqBody['from']}&size=${reqBody['size']}`, {
										observe: 'response',
									})
									.pipe(catchError(err => throwError(err)))
							);
						}
					}
					return forkJoin(requests);
				}),
				map((responses: HttpResponse<GetUserGroupsResponse>[]) => {
					const firstGroup: HttpResponse<GetUserGroupsResponse> = responses[0];
					if (responses.length > 1) {
						responses.forEach((response: HttpResponse<GetUserGroupsResponse>, index: number) => {
							if (index > 0) {
								firstGroup.body.userGroups = [...firstGroup.body.userGroups, ...response.body.userGroups];
							}
						});
					}
					return firstGroup.body;
				})
			);
	}

	getUserGroupById(userGroupId: number): Observable<GetUserGroupsResponse> {
		return this.httpClient.get<GetUserGroupsResponse>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups?userGroupId=${userGroupId}`);
	}

	getFeaturePermissions(): Observable<GetFeaturePermissionsResponse> {
		return this.httpClient.get<GetFeaturePermissionsResponse>(`${this.baseApiUrl}/entitlements/customer/user-groups/feature-permissions`);
	}

	createUserGroup(body: UserGroupPostBody): Observable<UserGroup> {
		return this.httpClient.post<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups`, body);
	}

	updateUserGroup(userGroupId: number, body: UserGroupPostBody): Observable<UserGroup> {
		return this.httpClient.patch<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups/${userGroupId}`, body);
	}

	deleteUserGroup(userGroupId: number): Observable<UserGroup | string> {
		return this.httpClient.delete<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups/${userGroupId}`);
	}

	reviewChangeRequest(changeRequestId: number, body: ChangeRequestBody): Observable<UserGroup> {
		return this.httpClient.post<UserGroup>(`${this.baseApiUrl}/entitlements/customer/user-groups/change-requests/${changeRequestId}/review`, body);
	}

	cancelChangeRequest(changeRequestId: number): Observable<UserGroup> {
		return this.httpClient.delete<UserGroup>(`${this.baseApiUrl}/entitlements/customer/user-groups/change-requests/${changeRequestId}`);
	}
}
