import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { FilterObjectService } from 'src/app/shared/services/filter-object.service';
import { Filter, FilterOption } from 'src/app/shared/models/abstract-filter.model';
import { TransactionFilters, TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';
import { AccountFilters, AccountFilterType } from 'src/app/features/reports/models/account-filters.model';
import { ClearFilterObjectState, InitFilterObjectState, LoadAllFilters, ResetFilterObjectState, UpdateFilter } from '../../actions/filter-object.actions';

export class FilterObjectStateModel {
	allFilters: AllFiltersModel;
	ogTransactionFilters: TransactionFilters;
	ogAccountFilters: AccountFilters;
	ogInvoiceFilters: any[];
	ogInvoiceARFilters: any[];
	ogInvoiceAPFilters: any[];
	apiInFlight: boolean;
	isError: boolean;
}

export class AllFiltersModel {
	transactionOptions: Filter[];
	accountOptions: Filter[];
	invoiceOptions: Filter[];
	invoiceAPOptions: Filter[];
	invoiceAROptions: Filter[];
	genericOptions: Filter[];
}

@State<FilterObjectStateModel>({
	name: 'filterObject',
	defaults: {
		allFilters: null,
		ogTransactionFilters: null,
		ogAccountFilters: null,
		ogInvoiceFilters: null,
		ogInvoiceARFilters: null,
		ogInvoiceAPFilters: null,
		apiInFlight: null,
		isError: null,
	},
})
@Injectable()
export class FilterObjectState {
	@Selector()
	static entityFilterOptions(state: FilterObjectStateModel): FilterOption[] {
		return state.allFilters.accountOptions.find(filter => filter.type === 'entity').options;
	}
	@Selector()
	static regionFilterOptions(state: FilterObjectStateModel): FilterOption[] {
		return state.allFilters.accountOptions.find(filter => filter.type === 'region').options;
	}
	@Selector()
	static divisionFilterOptions(state: FilterObjectStateModel): FilterOption[] {
		return state.allFilters.accountOptions.find(filter => filter.type === 'division').options;
	}

	@Selector()
	static allFilters(state: FilterObjectStateModel): AllFiltersModel {
		return state.allFilters;
	}

	@Selector()
	static accountFilters(state: FilterObjectStateModel): AccountFilters {
		return state.ogAccountFilters;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private filterObjectService: FilterObjectService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitFilterObjectState)
	async initFilterObjectState(context: StateContext<FilterObjectStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const filterObjectStateIsCached: boolean = this.filterObjectStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (filterObjectStateIsCached && appReady) {
						const state: FilterObjectStateModel = deserializedState.filterObject;
						context.patchState(state);
					} else if (!filterObjectStateIsCached && appReady) {
						context.dispatch(new LoadAllFilters());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(LoadAllFilters)
	loadAllFilters(context: StateContext<FilterObjectStateModel>, action: LoadAllFilters) {
		let state = context.getState();
		if (!state?.allFilters || action.forceRefresh) {
			state.apiInFlight = true;
			state.isError = false;
			context.patchState(state);
			return this.filterObjectService.loadAllFilters().pipe(
				map((res: { allFilters: any; ogFilters: any }) => {
					state = context.getState();
					state.allFilters = res.allFilters;
					state.ogTransactionFilters = res.ogFilters.transactionFilters;
					state.ogAccountFilters = res.ogFilters.accountFilters;
					state.ogInvoiceFilters = res.ogFilters.invoiceFilters;
					state.ogInvoiceARFilters = res.ogFilters.invoiceARFilters;
					state.ogInvoiceAPFilters = res.ogFilters.invoiceAPFilters;
					state.apiInFlight = false;
					context.patchState(state);
				}),
				catchError(err => {
					state = context.getState();
					state.apiInFlight = false;
					state.isError = true;
					context.patchState(state);
					return throwError(err);
				})
			);
		}
	}

	@Action(UpdateFilter)
	updateFilter(context: StateContext<FilterObjectStateModel>, action: UpdateFilter) {
		let state = context.getState();
		state.apiInFlight = true;
		state.isError = false;
		context.patchState(state);
		return this.filterObjectService.loadFilter(action.callType, action.filterType).pipe(
			map((res: { filterOptions: any; ogFilter: any }) => {
				state = context.getState();
				if (action.filterType === TransactionFilterType.tags) {
					state.ogTransactionFilters.tags = res.ogFilter['tags'];
					state.ogTransactionFilters.totalTags = res.ogFilter['totalTags'];
					const i = state.allFilters.genericOptions.findIndex(filter => filter.type === 'tag');
					const j = state.allFilters.genericOptions.findIndex(filter => filter.type === 'excludeTags');
					state.allFilters.genericOptions[i] = res.filterOptions[0];
					state.allFilters.genericOptions[j] = res.filterOptions[1];
				} else if (action.filterType === TransactionFilterType.accounts) {
					state.ogTransactionFilters.accounts = res.ogFilter['accounts'];
					state.ogTransactionFilters.totalAccounts = res.ogFilter['totalAccounts'];
					const i = state.allFilters.genericOptions.findIndex(filter => filter.type === 'accountId');
					state.allFilters.genericOptions[i] = res.filterOptions[0];

					state.ogAccountFilters.accounts = res.ogFilter['accounts'];
					state.ogAccountFilters.totalAccounts = res.ogFilter['totalAccounts'];
					const j = state.allFilters.accountOptions.findIndex(filter => filter.type === 'accountId');
					state.allFilters.accountOptions[j] = res.filterOptions[0];
				} else if (action.filterType === TransactionFilterType.institutions) {
					state.ogTransactionFilters.institutions = res.ogFilter['institutions'];
					state.ogTransactionFilters.totalInstitutions = res.ogFilter['totalInstitutions'];
					const i = state.allFilters.genericOptions.findIndex(filter => filter.type === 'institutionId');
					state.allFilters.genericOptions[i] = res.filterOptions[0];

					state.ogAccountFilters.institutions = res.ogFilter['institutions'];
					state.ogAccountFilters.totalInstitutions = res.ogFilter['totalInstitutions'];
					const j = state.allFilters.accountOptions.findIndex(filter => filter.type === 'institutionId');
					state.allFilters.accountOptions[j] = res.filterOptions[0];
				} else if (action.filterType === AccountFilterType.accountTags) {
					state.ogAccountFilters.accountTags = res.ogFilter['accountTags'];
					state.ogAccountFilters.totalAccountTags = res.ogFilter['totalAccountTags'];
					const i = state.allFilters.accountOptions.findIndex(filter => filter.type === 'entity');
					const j = state.allFilters.accountOptions.findIndex(filter => filter.type === 'region');
					const k = state.allFilters.accountOptions.findIndex(filter => filter.type === 'division');
					state.allFilters.accountOptions[i] = res.filterOptions[0];
					state.allFilters.accountOptions[j] = res.filterOptions[1];
					state.allFilters.accountOptions[k] = res.filterOptions[2];
				} else if (action.filterType === TransactionFilterType.glTags || action.filterType === TransactionFilterType.glAutoTags) {
					state.ogTransactionFilters.glTags = [...res.ogFilter['glTags'], ...res.ogFilter['glAutoTags']];
					state.ogTransactionFilters.totalGlTags = res.ogFilter['totalGlTags'] + res.ogFilter['totalGlAutoTags'];
					const i = state.allFilters.genericOptions.findIndex(filter => filter.type === 'glTagId');
					state.allFilters.genericOptions[i] = res.filterOptions[0];
				} else if (action.callType === 'glCode') {
					state.ogTransactionFilters.glCodes = res.ogFilter['glCodes'];
					const i = state.allFilters.genericOptions.findIndex(filter => filter.type === 'glCode1');
					const j = state.allFilters.genericOptions.findIndex(filter => filter.type === 'glCode2');
					state.allFilters.genericOptions[i] = res.filterOptions[0];
					state.allFilters.genericOptions[j] = res.filterOptions[1];
				}
				state.allFilters = Object.assign({}, state.allFilters);
				state.apiInFlight = false;
				context.patchState(state);
				return;
			}),
			catchError(err => {
				state = context.getState();
				state.apiInFlight = false;
				state.isError = true;
				context.patchState(state);
				return throwError(() => err);
			})
		);
	}

	@Action(ResetFilterObjectState)
	resetFilterObjectState(context: StateContext<FilterObjectStateModel>) {
		context.dispatch(new ClearFilterObjectState());
		context.dispatch(new InitFilterObjectState());
	}

	@Action(ClearFilterObjectState)
	clearFilterObjectState(context: StateContext<FilterObjectStateModel>) {
		this.appReadySub.unsubscribe();
		const state: FilterObjectStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private filterObjectStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedFilterObjectState: FilterObjectStateModel | undefined = deserializedState.filterObject;
		if (deserializedFilterObjectState && deserializedFilterObjectState.allFilters) {
			return true;
		} else {
			return false;
		}
	}
}
