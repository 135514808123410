import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { GLTagAction, Tag, TagPayload } from '../../models/tag.model';
import { ClearLastCreatedGlTagId, CreateGlTag, DeleteGlTag, UpdateGlTag } from '../../store/actions/glTags.action';
import { GlTagsState } from '../../store/state/glTags.state';
import { TagService } from '../tag.service';
import { SearchParameter } from '@trovata/app/shared/models/search-parameter.model';
import { AnalyticsUserActions } from '@trovata/app/core/utils/analyticsEnums';
import { VitallyService } from '@trovata/app/core/services/vitally.service';

@Injectable({
	providedIn: 'root',
})
export class GlTagFacadeService {
	@Select(GlTagsState.glTags) glTags$: Observable<Tag[]>;
	@Select(GlTagsState.lastCreatedGlTagId)
	lastCreatedGlTagId$: Observable<string>;

	constructor(
		private store: Store,
		private tagService: TagService,
		private vitallyService: VitallyService
	) {}

	getGlTags(): Promise<Tag[]> {
		return new Promise(async resolve => {
			this.glTags$.subscribe((glTags: Tag[]) => {
				if (glTags) {
					resolve(glTags);
				}
			});
		});
	}

	getGlTag(glTagId: string): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				const glTags: Tag[] = await this.getGlTags();
				const glTag: Tag = glTags.find((tag: Tag) => tag.tagId === glTagId);
				resolve(glTag);
			} catch (error) {
				reject(error);
			}
		});
	}

	createGlTag(glTagPayload: TagPayload): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateGlTag(glTagPayload)));
				let lastCreatedGlTagId: string;
				this.lastCreatedGlTagId$.subscribe((id: string) => (lastCreatedGlTagId = id));
				const newGlTag: Tag = await this.getGlTag(lastCreatedGlTagId);
				const newGlTagCopy: Tag = JSON.parse(JSON.stringify(newGlTag));
				this.store.dispatch(new ClearLastCreatedGlTagId());

				const analyticsParams: object = {
					tagId: newGlTag.tagId,
					name: newGlTag.tagTitle,
					tagType: newGlTag.tagType,
				};

				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsCreate, analyticsParams);

				resolve(newGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateGlTag(glTag: Tag, glTagPayload: TagPayload): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateGlTag(glTag, glTagPayload)));
				const updatedGlTag: Tag = await this.getGlTag(glTag.tagId);
				const updatedGlTagCopy: Tag = JSON.parse(JSON.stringify(updatedGlTag));

				const analyticsParams: object = {
					tagId: updatedGlTag.tagId,
					name: updatedGlTag.tagTitle,
					tagType: updatedGlTag.tagType,
				};

				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsUpdate, analyticsParams);

				resolve(updatedGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteGlTag(glTag: Tag): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteGlTag(glTag)));

				const analyticsParams: object = {
					tagId: glTag.tagId,
					name: glTag.tagTitle,
					tagType: glTag.tagType,
				};

				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsDelete, analyticsParams);

				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	actionGlTag(glTagId: string, transactionIds: string[], action: GLTagAction, isAll?: boolean, q?: string, params?: SearchParameter[]): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.tagService.actionGlTag(glTagId, transactionIds, action, isAll, q, params));
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}
}
