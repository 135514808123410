import { Injectable } from '@angular/core';
import { State, Action, StateContext, Select, Selector } from '@ngxs/store';
import { StateApiError } from 'src/app/core/models/state.model';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { GetDeveloperPortalResources, ClearDeveloperPortalResourcesState } from '../actions/resources.actions';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { FeatureMap, PermissionMap } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { DeveloperResource } from '../../models/developer-application.model';
import { DeveloperPortalResourcesService } from '../../services/resources.service';
import { HttpResponse } from '@angular/common/http';

export class DeveloperPortalResourcesStateModel extends EntitledStateModel {
	resources: DeveloperResource[];
	apiErrors: StateApiError[];
	resourcesInFlight: boolean;
}

@State<DeveloperPortalResourcesStateModel>({
	name: 'developerResources',
	defaults: {
		resources: null,
		isCached: false,
		apiErrors: null,
		resourcesInFlight: null,
	},
})
@Injectable()
export class DeveloperPortalResourcesState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	@Select(CustomerFeatureState.featureIds) userAvailableFeatures$: Observable<FeatureMap>;

	private appReadySub: Subscription;

	constructor(private resourcesService: DeveloperPortalResourcesService) {}

	@Selector()
	static developerResources(state: DeveloperPortalResourcesStateModel): DeveloperResource[] {
		return state.resources;
	}

	@Selector()
	static developerPortalResourcesIsCached(state: DeveloperPortalResourcesStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static developerPortalResourcesApiErrors(state: DeveloperPortalResourcesStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector() static resourcesInFlight(state: DeveloperPortalResourcesStateModel): boolean {
		return state.resourcesInFlight;
	}

	@Action(GetDeveloperPortalResources)
	getResources(context: StateContext<DeveloperPortalResourcesStateModel>): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				context.patchState({ resourcesInFlight: true });
				const response: HttpResponse<DeveloperResource[]> = await firstValueFrom(this.resourcesService.getResources());
				const resources: DeveloperResource[] = response.body;
				const sortedResources: DeveloperResource[] = this.sortResourcesAlphabetically(resources);
				context.patchState({ resourcesInFlight: false, isCached: true, resources: sortedResources });
				resolve();
			} catch (error) {
				context.patchState({ resourcesInFlight: false });
				reject(error);
			}
		});
	}

	@Action(ClearDeveloperPortalResourcesState)
	clearResourcesState(context: StateContext<DeveloperPortalResourcesStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: DeveloperPortalResourcesStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private sortResourcesAlphabetically(resources: DeveloperResource[]): DeveloperResource[] {
		const sortedResources: DeveloperResource[] = resources.sort((resourceA: DeveloperResource, resourceB: DeveloperResource) => {
			if (resourceA.endpoint < resourceB.endpoint) {
				return -1;
			} else if (resourceA.endpoint > resourceB.endpoint) {
				return 1;
			}
			return 0;
		});
		return sortedResources;
	}
}
