import { GlobalFactorItem, IFactor, IStreamGroup } from '../../models/forecastV3-forecast.model';
import { EditedForecastFactorGroups, EditedStreamGroups, ForecastValues, IForecast } from '../../models/forecastV3-forecast-response.model';
import { IStream } from '../../models/forecastV3-stream.model';
import { PostForecastPayload } from '../../models/forecast-api.model';
export class InitForecastV3State {
	static readonly type: string = '[ForecastV3] InitForecastV3State';
}

export class ClearForecastV3State {
	static readonly type: string = '[ForecastV3] ClearForecastV3State';
}

export class GetForecasts {
	static readonly type: string = '[ForecastV3] GetForecasts';
}

export class GetStreams {
	static readonly type: string = '[ForecastV3] GetStreams';
}

export class CreateStream {
	static readonly type: string = '[ForecastV3] CreateStream';
	constructor(
		public streamPayload: IStream,
		public duplicateStreamId?: string,
		public newStreamName?: string
	) {}
}

export class CreateForecast {
	static readonly type: string = '[ForecastV3] CreateForecast';
	constructor(
		public forecastPayload: PostForecastPayload,
		public duplicateForecastById?: string,
		public nameDuplicate?: string,
		public cadence?: string
	) {}
}

export class CreateGlobalFactor {
	static readonly type: string = '[ForecastV3] CreateGlobalFactor';
	constructor(
		public forecastFactorsPayload: IFactor,
		public forecastId: string
	) {}
}

export class AddStreamDataToState {
	static readonly type: string = '[ForecastV3] AddStreamDataToState';
	constructor(public stream: IStream) {}
}

export class AddForecastDataToState {
	static readonly type: string = '[ForecastV3] AddForecastDataToState';
	constructor(public forecast: IForecast) {}
}

export class LazyLoadForecastData {
	static readonly type: string = '[ForecastV3] LazyLoadForecastData';
	constructor(public forecastId: string) {}
}

export class LazyLoadStreamData {
	static readonly type: string = '[ForecastV3] LazyLoadStreamData';
	constructor(public streamId: string) {}
}

export class DeleteStream {
	static readonly type: string = '[ForecastV3] DeleteStream';
	constructor(
		public streamId: string,
		public parentForecast?: IForecast
	) {}
}

export class DeleteForecast {
	static readonly type: string = '[ForecastV3] DeleteForecast';
	constructor(public forecastId: string) {}
}

export class UpdateStreamData {
	static readonly type: string = '[ForecastV3] UpdateStreamData';
	constructor(
		public streamId: string,
		public streamValues: ForecastValues,
		public parentForecast?: IForecast
	) {}
}

export class UpdateStream {
	static readonly type: string = '[ForecastV3] UpdateStream';
	constructor(
		public streamId: string,
		public streamPayload: IStream,
		public values?: ForecastValues,
		public parentForecast?: IForecast
	) {}
}

export class UpdateForecast {
	static readonly type: string = '[ForecastV3] UpdateForecast';
	constructor(
		public forecastId: string,
		public forecastPayload: Partial<PostForecastPayload>,
		public streamGroupsSource?: IStreamGroup[],
		public editedStreamGroups?: EditedStreamGroups,
		public globalFactorsSource?: GlobalFactorItem[],
		public editedGlobalFactors?: EditedForecastFactorGroups
	) {}
}

export class RetryLazyLoadForecastData {
	static readonly type: string = '[ForecastV3] RetryLazyLoadForecastData';
}
