import { DeveloperApplication } from '../../models/developer-application.model';

export class InitDeveloperPortalApplicationsState {
	static readonly type: string = '[DeveloperApplications] InitDeveloperPortalApplicationsState';
}

export class GetDeveloperPortalApplications {
	static readonly type: string = '[DeveloperApplications] GetDeveloperPortalApplications';
}

export class ClearDeveloperPortalApplicationsState {
	static readonly type: string = '[DeveloperApplications] ClearDeveloperPortalApplicationsState';
}

export class CreateDeveloperPortalApplication {
	static readonly type: string = '[DeveloperApplications] CreateDeveloperPortalApplication';
	constructor(public applicationPayload: DeveloperApplication) {}
}

export class UpdateDeveloperPortalApplication {
	static readonly type: string = '[DeveloperApplications] UpdateDeveloperPortalApplication';
	constructor(public application: DeveloperApplication) {}
}

export class DeleteDeveloperPortalApplication {
	static readonly type: string = '[DeveloperApplications] DeleteDeveloperPortalApplication';
	constructor(public application: DeveloperApplication) {}
}

export class ClearLastCreatedDeveloperPortalApplicationId {
	static readonly type: string = '[DeveloperApplications] ClearLastCreatedDeveloperPortalApplicationId';
}

export class SetLastCreatedDeveloperPortalApplicationId {
	static readonly type: string = '[DeveloperApplications] SetLastCreatedDeveloperPortalApplicationId';
	constructor(public id: string) {}
}
