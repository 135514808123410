import { Injectable } from '@angular/core';
import { WorkerAction, WorkerMessage } from '../models/worker.model';

interface WorkerPoolLaborer extends Worker {
	idle: boolean;
	taskId: string;
}

@Injectable({
	providedIn: 'root',
})
export class WorkerPoolManagerService {
	private workers: WorkerPoolLaborer[];
	private tasks: WorkerMessage[];
	private maxWorkers: number;
	private taskActionMap: Map<string, WorkerAction>;
	private taskPromiseMap: Map<string, { resolve: (value: WorkerMessage) => void; reject: (reason: Error) => void }>;

	constructor() {
		this.workers = [];
		this.tasks = [];
		this.maxWorkers = (navigator.hardwareConcurrency || 4) - 2; // TODO: Remove ' - 2' once other worker code is combined
		this.taskActionMap = new Map();
		this.taskPromiseMap = new Map();
		this.spawnWorkerPool();
	}

	private spawnWorkerPool(): void {
		if (typeof Worker !== 'undefined') {
			for (let i: number = 0; i < this.maxWorkers; i++) {
				let worker: WorkerPoolLaborer | null = new Worker(new URL('../utils/worker', import.meta.url)) as WorkerPoolLaborer;
				if (worker) {
					worker.idle = true;
					worker.onmessage = this.onWorkerMessage.bind(this);
					worker.onerror = (error: ErrorEvent): ((this: AbstractWorker, ev: ErrorEvent) => Error) | void =>
						this.onWorkerError(error, worker?.taskId ? worker.taskId : '');
					this.workers.push(worker);
					worker = null;
				}
			}
		} else {
			console.error('Web worker not supported');
		}
	}

	private enqueueTask(task: WorkerMessage): void {
		this.tasks.push(task);
		this.taskActionMap.set(task.taskId, task.action);
		this.assignTasks();
	}

	private assignTasks(): void {
		this.workers.forEach((worker: WorkerPoolLaborer) => {
			if (this.tasks.length > 0 && worker.idle) {
				let task: WorkerMessage | undefined = this.tasks.shift();
				if (task) {
					worker.taskId = task.taskId;
					worker.postMessage(task);
					worker.idle = false;
					task = undefined;
					if (!this.tasks.length) {
						this.tasks = [];
					}
				}
			}
		});
	}

	private onWorkerMessage(event: MessageEvent<WorkerMessage>): void {
		(event.currentTarget as WorkerPoolLaborer).idle = true;
		(event.currentTarget as WorkerPoolLaborer).taskId = '';
		this.handleTaskCompletion(event.data);
		this.assignTasks();
	}

	private onWorkerError(error: ErrorEvent, taskId: string): void {
		if (this.taskPromiseMap.has(taskId)) {
			this.taskPromiseMap.get(taskId).reject(new Error(`Worker error: ${error.message}`));
			this.taskPromiseMap.delete(taskId);
		}
		if (this.taskActionMap.get(taskId)) {
			this.taskActionMap.delete(taskId);
		}
		(error.currentTarget as WorkerPoolLaborer).taskId = '';
		(error.currentTarget as WorkerPoolLaborer).idle = true;
		this.assignTasks();
	}

	private handleTaskCompletion(workerMessage: WorkerMessage): void {
		if (this.taskPromiseMap.get(workerMessage.taskId)) {
			this.taskPromiseMap.get(workerMessage.taskId).resolve(workerMessage);
			this.taskPromiseMap.delete(workerMessage.taskId);
		}
		if (this.taskActionMap.has(workerMessage.taskId)) {
			this.taskActionMap.delete(workerMessage.taskId);
		}
	}

	awaitTask(message: WorkerMessage): Promise<WorkerMessage> {
		return new Promise((resolve: (value: WorkerMessage) => void, reject: (reason: Error) => void) => {
			this.taskPromiseMap.set(message.taskId, { resolve: resolve, reject: reject });
			this.enqueueTask(message);
		});
	}

	terminateAll(): void {
		this.workers.forEach((worker: WorkerPoolLaborer) => worker.terminate());
	}
}
