import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeveloperResource } from '../models/developer-application.model';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class DeveloperPortalResourcesService {
	constructor(private httpClient: HttpClient) {}

	getResources(): Observable<HttpResponse<DeveloperResource[]>> {
		return this.httpClient
			.get<DeveloperResource[]>(environment.trovataAPI('workspace') + '/developer/resources', {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}
}
