import { Injectable } from '@angular/core';
import { Action, Selector, Select, State, StateContext, Store } from '@ngxs/store';
import { ReconciliationReport, ReconData } from '../../models/reconciliation.model';
import { TrovataAppState } from 'src/app/core/models/state.model';
import {
	AddReconDataToReconReport,
	ClearLastCreatedReconId,
	ClearReconciliationState,
	CreateReconReport,
	DeleteReconReport,
	DuplicateReconReport,
	GetReconReports,
	InitReconciliationState,
	LazyLoadReconReportsData,
	SetLastCreatedReconId,
	UpdateReconReport,
} from '../actions/reconciliation.actions';
import { catchError, combineLatest, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { ReconciliationService } from '../../services/reconciliation.service';
import { DateTime } from 'luxon';
import { Cadence } from '../../models/cadence.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, ReconUpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { firstValidValueFrom } from 'src/app/shared/utils/firstValidValueFrom';
import { PermissionId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { ReportType } from '../../models/report.model';
import { PreferencesFacadeService } from '@trovata/app/shared/services/facade/preferences.facade.service';
import { SnackType } from '@trovata/app/shared/models/snacks.model';

export class ReconciliationStateModel extends EntitledStateModel {
	reconReports: ReconciliationReport[];
	lastCreatedReconId: string;
	preFetchInFlight: boolean;
}

@State<ReconciliationStateModel>({
	name: 'reconciliation',
	defaults: {
		reconReports: null,
		isCached: false,
		lastCreatedReconId: null,
		preFetchInFlight: false,
	},
})
@Injectable()
export class ReconciliationState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private loadingReconReports: boolean;
	private isInitialized: boolean;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	@Selector()
	static reconReports(state: ReconciliationStateModel): ReconciliationReport[] {
		return state.reconReports;
	}

	@Selector()
	static lastCreatedReconId(state: ReconciliationStateModel) {
		return state.lastCreatedReconId;
	}

	@Selector() static reconPreFetchInFlight(recons: ReconciliationStateModel): boolean {
		return recons.preFetchInFlight;
	}

	constructor(
		private serializationService: SerializationService,
		private preferncesFacadeService: PreferencesFacadeService,
		private store: Store,
		private reconciliationService: ReconciliationService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitReconciliationState)
	async InitReconciliationState(context: StateContext<ReconciliationStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const reconciliationStateIsCached: boolean = this.reconciliationStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (!this.isInitialized && appReady && permissions) {
						if (permissions.has(PermissionId.readReconReport)) {
							if (reconciliationStateIsCached) {
								const state: ReconciliationStateModel = deserializedState.reconciliation;
								context.patchState(state);
								this.lazyLoadVisibleReportsData(context);
							} else {
								context.dispatch(new GetReconReports());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ reconReports: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetReconReports)
	getReconReports(context: StateContext<ReconciliationStateModel>): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				if (!this.loadingReconReports) {
					this.reconciliationService.getReconciliationReports().subscribe(async resp => {
						const reconReports: ReconciliationReport[] = resp.body['recon'];
						await this.addReconReportsToState(context, reconReports);
						resolve(true);
					});
				} else {
					resolve(true);
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(AddReconDataToReconReport)
	addReconDataToReconReport(context: StateContext<ReconciliationStateModel>, action: AddReconDataToReconReport) {
		const state = context.getState();
		const reconReportToAddDataTo: ReconciliationReport = action.reconReport;
		const data: ReconData = action.reconData;
		if (data.reconData.periodView) {
			reconReportToAddDataTo.periodData = data;
		} else {
			reconReportToAddDataTo.dailyData = data;
		}
		const filteredReports: ReconciliationReport[] = state.reconReports.filter(
			(filterReport: ReconciliationReport) => filterReport.reconId !== action.reconReport.reconId
		);
		filteredReports.push(reconReportToAddDataTo);
		state.isCached = true;
		context.patchState(state);
	}

	@Action(CreateReconReport)
	createReconReport(context: StateContext<ReconciliationStateModel>, action: CreateReconReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const createReconResponse: HttpResponse<ReconciliationReport> = await firstValueFrom(
					this.reconciliationService.createReconciliationReport(action.reconReport)
				);
				const newRecon: ReconciliationReport = createReconResponse.body;
				await firstValueFrom(context.dispatch(new SetLastCreatedReconId(newRecon.reconId)));
				await firstValueFrom(context.dispatch(new GetReconReports()));
				await this.loadReconciliationData(context, newRecon);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DuplicateReconReport)
	duplicateReport(context: StateContext<ReconciliationStateModel>, action: DuplicateReconReport): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const duplicateReconResponse: HttpResponse<ReconciliationReport> = await firstValidValueFrom(
					this.reconciliationService.duplicateReconReport(action.reconId, action.newReconReportName)
				);
				if (duplicateReconResponse?.body?.reconId) {
					const reconId: string = duplicateReconResponse.body.reconId;
					await firstValueFrom(context.dispatch(new SetLastCreatedReconId(reconId)));
					const state: ReconciliationStateModel = context.getState();
					context.patchState(state);
					await firstValidValueFrom(context.dispatch(new GetReconReports()));
					await this.loadReconciliationData(context, duplicateReconResponse['body']);
					resolve(reconId);
				} else {
					reject(new Error(`Could not duplicate reconciliation report with id ${action.reconId}`));
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateReconReport)
	updateReconReport(context: StateContext<ReconciliationStateModel>, action: UpdateReconReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			this.reconciliationService.putReconciliationReport(action.reconReport).subscribe(async response => {
				const reconReportToUpdate: ReconciliationReport = response.body;
				const state: ReconciliationStateModel = context.getState();
				reconReportToUpdate.reportType = ReportType.recon;
				state.reconReports = state.reconReports.filter((filterReport: ReconciliationReport) => filterReport.reconId !== reconReportToUpdate.reconId);
				state.reconReports = state.reconReports.concat(reconReportToUpdate);
				context.patchState(state);
				await this.loadReconciliationData(context, reconReportToUpdate);
				this.updatedEventsService.updateItem(new ReconUpdatedEvent(ActionType.update, action.reconReport.reconId, reconReportToUpdate));
				this.loadingReconReports = false;
				resolve();
			});
		});
	}

	@Action(DeleteReconReport)
	deleteReconReport(context: StateContext<ReconciliationStateModel>, action: DeleteReconReport) {
		return this.reconciliationService.deleteReconciliationReport(action.reconId).pipe(
			tap((response: HttpResponse<any>) => {
				const state = context.getState();
				const filteredReports: ReconciliationReport[] = state.reconReports.filter(
					(filterReport: ReconciliationReport) => filterReport.reconId !== action.reconId
				);
				state.reconReports = filteredReports;
				this.updatedEventsService.updateItem(new ReconUpdatedEvent(ActionType.delete, action.reconId));
				context.patchState(state);
				context.dispatch(new GetReconReports());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ClearReconciliationState)
	clearReconciliationState(context: StateContext<ReconciliationStateModel>) {
		this.loadingReconReports = false;
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: ReconciliationStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(LazyLoadReconReportsData)
	async lazyLoadReconReportsData(context: StateContext<ReconciliationStateModel>, action: LazyLoadReconReportsData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ReconciliationStateModel = context.getState();
				const report: ReconciliationReport = state.reconReports.find((findReport: ReconciliationReport) => findReport.reconId === action.reconId);
				await this.loadReconciliationData(context, report);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(ClearLastCreatedReconId)
	clearLastCreatedReconId(context: StateContext<ReconciliationStateModel>) {
		context.patchState({ lastCreatedReconId: null });
	}

	@Action(SetLastCreatedReconId)
	setLastCreatedId(context: StateContext<ReconciliationStateModel>, action: SetLastCreatedReconId) {
		context.patchState({ lastCreatedReconId: action.id });
	}

	private addReconReportsToState(context: StateContext<ReconciliationStateModel>, reconReports: ReconciliationReport[]): Promise<void> {
		return new Promise((resolve, reject) => {
			let needsUpdate: boolean = false;
			reconReports.forEach((report: ReconciliationReport) => {
				report.reportType = ReportType.recon;
			});
			const state: ReconciliationStateModel = context.getState();
			if (reconReports && !state.reconReports) {
				state.reconReports = reconReports;
				needsUpdate = true;
			} else {
				const newReconReportsToAdd: ReconciliationReport[] = reconReports.filter(
					(filterReport: ReconciliationReport) => !state.reconReports.find((findReport: ReconciliationReport) => filterReport.reconId === findReport.reconId)
				);
				const reconReportsToBeUpdated: ReconciliationReport[] = reconReports.filter((filterReport: ReconciliationReport) =>
					state.reconReports.find(
						(findReport: ReconciliationReport) => findReport.reconId === filterReport.reconId && filterReport.lastModifiedDate !== findReport.lastModifiedDate
					)
				);
				if (reconReportsToBeUpdated) {
					reconReportsToBeUpdated.forEach((eachUpdatedReport: ReconciliationReport) => {
						state.reconReports = state.reconReports.filter((filterReport: ReconciliationReport) => filterReport.reconId !== eachUpdatedReport.reconId);
					});
					state.reconReports = state.reconReports.concat(reconReportsToBeUpdated);
					needsUpdate = true;
				}
				if (newReconReportsToAdd) {
					state.reconReports = state.reconReports.concat(newReconReportsToAdd);
					needsUpdate = true;
				}
			}

			if (needsUpdate) {
				context.patchState(state);
				this.lazyLoadVisibleReportsData(context);
				resolve();
			} else {
				this.loadingReconReports = false;
				context.patchState(state);
				resolve();
			}
		});
	}

	private loadReconciliationData(context: StateContext<ReconciliationStateModel>, updatedRecon: ReconciliationReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const date: string = updatedRecon.endDate || DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd');
			const periods = updatedRecon.periods || 13;
			const cadence = updatedRecon.cadence || Cadence.daily;
			const dailyRequest = this.reconciliationService.getReconciliationDataById(updatedRecon.reconId, false, date);
			const periodsRequest = this.reconciliationService.getReconciliationDataById(updatedRecon.reconId, true, date, null, null, null, periods, cadence);
			combineLatest([dailyRequest, periodsRequest]).subscribe({
				next: async ([dailyDataResponse, periodDataResponse]) => {
					await firstValidValueFrom(context.dispatch(new AddReconDataToReconReport(updatedRecon, dailyDataResponse.body)));
					await firstValidValueFrom(context.dispatch(new AddReconDataToReconReport(updatedRecon, periodDataResponse.body)));
					resolve();
				},
				error: (httpError: HttpErrorResponse) => {
					updatedRecon.errorMessage = httpError.error.message;
					reject();
				},
			});
		});
	}

	private async lazyLoadVisibleReportsData(context: StateContext<ReconciliationStateModel>): Promise<void> {
		try {
			context.patchState({ preFetchInFlight: true });
			const visibleReportIds: string[] = await this.preferncesFacadeService.getVisibleSnackIds(SnackType.recon);
			const state: ReconciliationStateModel = context.getState();
			const reconsToLoad: ReconciliationReport[] = [];
			state.reconReports.forEach((eachReport: ReconciliationReport) => {
				if (!(eachReport.dailyData && eachReport.periodData) && visibleReportIds.includes(eachReport.reconId)) {
					reconsToLoad.push(eachReport);
				}
			});
			// by catching errors in promise all and returning this allows it to wait for all requests to either error out or complete succesfully
			// todo improve error handling
			await Promise.all(reconsToLoad.map((report: ReconciliationReport) => this.loadReconciliationData(context, report).catch(error => new Error(error))));
			context.patchState({ preFetchInFlight: false });
		} catch (error) {
			context.patchState({ preFetchInFlight: false });
			throw error;
		}
	}

	private reconciliationStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedReconciliationState: ReconciliationStateModel | undefined = deserializedState.reconciliation;
		if (deserializedReconciliationState?.reconReports && deserializedReconciliationState?.isCached) {
			return true;
		} else {
			return false;
		}
	}
}
