import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Select, Selector } from '@ngxs/store';
import {
	checkForBadHttpErrorMessage,
	checkToAddStateApiError,
	checkToRemoveStateApiError,
	StateApiError,
	TrovataAppState,
} from 'src/app/core/models/state.model';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import {
	GetTemplatesResponse,
	InstitutionFieldsDict,
	InstitutionFieldsResponse,
	InternationalPaymentsCountryDict,
	InternationalPaymentsCountryResponse,
	Template,
} from '../../../models/template.model';
import { TemplatesService } from '../../../services/templates/templates.service';
import {
	ClearTemplatesState,
	CreateTemplate,
	DeleteTemplate,
	GetInstitutionFields,
	GetInternationalPaymentsCountryData,
	GetTemplateById,
	GetTemplates,
	InitTemplatesState,
	PostTemplateAdminApproval,
	RemoveTemplateFromStoreById,
	ResetTemplatesState,
	UpdateTemplate,
} from '../../actions/templates.actions';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { AdminApprovalService } from 'src/app/shared/services/admin-approval.service';
import {
	AdminApprovalRecordStatus,
	ChangeRequest,
	ChangeRequestType,
	PaymentsRecordType,
	PostAdminApprovalResponse,
} from 'src/app/shared/models/admin-approval.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { PermissionId, PermissionMap } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AuthUser } from '@trovata/app/core/models/auth.model';
import { PaymentsAccount } from '../../../models/account.model';
import { PaymentsAccountsState } from '../accounts/accounts.state';
import { ReadyForReviewSnack, ReadyForReviewSnackParams, sortSnacksByDate } from 'src/app/shared/models/ready-for-review-snack.model';
import { DateTime } from 'luxon';
import { TrovataResourceType, TrovataResourceViewText } from 'src/app/shared/models/trovata.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class TemplatesStateModel extends EntitledStateModel {
	templates: Template[];
	institutionMap: InstitutionFieldsDict;
	countriesDict: InternationalPaymentsCountryDict;
	templateSnacks: ReadyForReviewSnack[];
	apiErrors: StateApiError[];
}

@State<TemplatesStateModel>({
	name: 'templates',
	defaults: {
		templates: null,
		institutionMap: null,
		countriesDict: null,
		templateSnacks: null,
		isCached: false,
		apiErrors: null,
	},
})
@Injectable()
export class TemplatesState {
	@Select(PaymentsAccountsState.paymentsAccounts) accounts$: Observable<PaymentsAccount[]>;
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	@Select(CustomerFeatureState.paymentsEnabled) paymentsEnabled$: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private authUser$: Observable<AuthUser>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	private authUser: AuthUser;
	private accounts: PaymentsAccount[];

	constructor(
		private templatesService: TemplatesService,
		private store: Store,
		private serializationService: SerializationService,
		private adminApprovalService: AdminApprovalService,
		private sanitizer: DomSanitizer
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.authUser$ = this.store.select((state: TrovataAppState) => state.auth.authUser);
	}

	@Selector()
	static templates(state: TemplatesStateModel): Template[] {
		return state.templates;
	}

	@Selector()
	static templatesIsCached(state: TemplatesStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static templatesApiErrors(state: TemplatesStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector()
	static templateSnacks(state: TemplatesStateModel): ReadyForReviewSnack[] {
		return state.templateSnacks;
	}

	@Action(InitTemplatesState)
	async initTemplatesState(context: StateContext<TemplatesStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const templatesStateIsCached: boolean = this.templatesStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$, this.authUser$, this.accounts$, this.paymentsEnabled$]).subscribe({
				next: ([appReady, permissions, authUser, accounts, paymentsEnabled]: [boolean, PermissionMap, AuthUser, PaymentsAccount[], boolean]) => {
					if (!this.isInitialized && appReady && permissions && authUser && accounts && paymentsEnabled) {
						this.authUser = authUser;
						this.accounts = accounts;
						if (permissions.has(PermissionId.readTemplates)) {
							if (templatesStateIsCached) {
								const state: TemplatesStateModel = deserializedState.templates;
								this.postProcessTemplatesData(context, state);
							} else {
								this.initDefaultTemplatesState(context);
							}
							this.isInitialized = true;
						} else {
							context.patchState({ templates: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetTemplates)
	getTemplates(context: StateContext<TemplatesStateModel>): Observable<GetTemplatesResponse> {
		return this.templatesService.getTemplates().pipe(
			tap((getTemplateResponse: GetTemplatesResponse) => {
				checkToRemoveStateApiError(context, GetTemplates);
				const templates: Template[] = getTemplateResponse.rows;
				this.postProcessTemplatesData(context, null, templates);
			}),
			catchError(error => throwError(() => checkToAddStateApiError(error, context, GetTemplates, 'Templates are down right now. Please try again later.')))
		);
	}

	@Action(GetInstitutionFields)
	getInstitutionFields(context: StateContext<TemplatesStateModel>): Observable<InstitutionFieldsResponse> {
		return this.templatesService.getInstitutionFields().pipe(
			tap((getInstitutionFieldsResponse: InstitutionFieldsResponse) => {
				const state: TemplatesStateModel = context.getState();
				const institutionMap: InstitutionFieldsDict = getInstitutionFieldsResponse.institutions;
				state.institutionMap = institutionMap;
				context.patchState(state);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Get Institution Fields is down right now. Please try again later.')))
		);
	}

	@Action(GetInternationalPaymentsCountryData)
	getInternationalPaymentsCountryData(context: StateContext<TemplatesStateModel>): Observable<InternationalPaymentsCountryResponse> {
		return this.templatesService.getInternationalPaymentsCountries().pipe(
			tap((getCountriesResponse: InternationalPaymentsCountryResponse) => {
				const countriesDict: InternationalPaymentsCountryDict = getCountriesResponse.countries;
				const state: TemplatesStateModel = context.getState();
				state.countriesDict = countriesDict;
				context.patchState(state);
			}),
			catchError(error =>
				throwError(() => checkForBadHttpErrorMessage(error, 'Get International PAyments Country Data is down right now. Please try again later.'))
			)
		);
	}

	@Action(CreateTemplate)
	createTemplates(context: StateContext<TemplatesStateModel>, action: CreateTemplate): Observable<Template> {
		return this.templatesService.createTemplate(action.templateToCreate).pipe(
			tap((template: Template) => {
				const state: TemplatesStateModel = context.getState();
				const templatesCopy: Template[] = [...state.templates];
				templatesCopy.push(template);
				this.postProcessTemplatesData(context, null, templatesCopy);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create template is down right now. Please try again later.')))
		);
	}

	@Action(UpdateTemplate)
	updateTemplates(context: StateContext<TemplatesStateModel>, action: UpdateTemplate): Observable<Template> {
		return this.templatesService.updateTemplate(action.templateToUpdate).pipe(
			tap((template: Template) => {
				const state: TemplatesStateModel = context.getState();
				const filteredTemplates: Template[] = state.templates.filter(
					(filterTemplate: Template) => filterTemplate.templateId !== action.templateToUpdate.templateId
				);
				filteredTemplates.push(template);
				this.postProcessTemplatesData(context, null, filteredTemplates);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Update template is down right now. Please try again later.')))
		);
	}

	@Action(DeleteTemplate)
	deleteTemplate(context: StateContext<TemplatesStateModel>, action: DeleteTemplate): Observable<Template> {
		return this.templatesService.deleteTemplate(action.templateToDelete).pipe(
			tap((template: Template) => {
				const state: TemplatesStateModel = context.getState();
				const filteredTemplates: Template[] = state.templates.filter(
					(filterTemplate: Template) => filterTemplate.templateId !== action.templateToDelete.templateId
				);
				if (template && Object.keys(template).length) {
					filteredTemplates.push(template);
				}
				this.postProcessTemplatesData(context, null, filteredTemplates);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Delete template is down right now. Please try again later.')))
		);
	}

	@Action(GetTemplateById)
	getTemplateById(context: StateContext<TemplatesStateModel>, action: GetTemplateById): Observable<GetTemplatesResponse> {
		return this.templatesService.getTemplateById(action.templateId).pipe(
			tap((getTemplatesResponse: GetTemplatesResponse) => {
				const state: TemplatesStateModel = context.getState();
				const gotTemplate: Template = getTemplatesResponse.rows.find((template: Template) => template.templateId === action.templateId);
				if (gotTemplate) {
					const filteredTemplates: Template[] = state.templates.filter((filterTemplate: Template) => filterTemplate.templateId !== gotTemplate.templateId);
					filteredTemplates.push(gotTemplate);
					this.postProcessTemplatesData(context, null, filteredTemplates);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Template is down right now. Please try again later.')))
		);
	}

	@Action(PostTemplateAdminApproval)
	postTemplateAdminApproval(context: StateContext<TemplatesStateModel>, action: PostTemplateAdminApproval): Observable<PostAdminApprovalResponse> {
		return this.adminApprovalService.postAdminApproval(action.adminApproval, PaymentsRecordType.TEMPLATE).pipe(
			tap((response: PostAdminApprovalResponse) => {
				const state: TemplatesStateModel = context.getState();
				const changeRequest: ChangeRequest = response.changeRequest;
				const approvalActionType: ChangeRequestType = changeRequest.changeType;
				const approvalStatus: AdminApprovalRecordStatus = changeRequest.status;
				const template: Template = response.record as Template;
				if (
					(approvalActionType === ChangeRequestType.CREATE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.REJECTED) ||
					(approvalActionType === ChangeRequestType.DELETE && approvalStatus === AdminApprovalRecordStatus.REJECTED)
				) {
					const filteredTemplates: Template[] = state.templates.filter((filterTemplate: Template) => filterTemplate.templateId !== template.templateId);
					filteredTemplates.push(template);
					this.postProcessTemplatesData(context, null, filteredTemplates);
				} else if (
					(approvalActionType === ChangeRequestType.DELETE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.CREATE && approvalStatus === AdminApprovalRecordStatus.REJECTED)
				) {
					const filteredTemplates: Template[] = state.templates.filter((filterTemplate: Template) => filterTemplate.templateId !== changeRequest.recordId);
					this.postProcessTemplatesData(context, null, filteredTemplates);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Template approval is down right now. Please try again later.')))
		);
	}

	@Action(ClearTemplatesState)
	clearTemplatesState(context: StateContext<TemplatesStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: TemplatesStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetTemplatesState)
	resetTemplatesState(context: StateContext<TemplatesStateModel>): void {
		context.dispatch(new ClearTemplatesState());
		context.dispatch(new GetTemplates());
		context.dispatch(new GetInstitutionFields());
		context.dispatch(new GetInternationalPaymentsCountryData());
	}

	private templatesStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedTemplatesState: TemplatesStateModel | undefined = deserializedState.templates;
		if (
			deserializedTemplatesState &&
			deserializedTemplatesState.templates &&
			deserializedTemplatesState.templateSnacks &&
			deserializedTemplatesState.isCached &&
			deserializedTemplatesState.apiErrors
		) {
			return true;
		} else {
			return false;
		}
	}

	private initDefaultTemplatesState(context: StateContext<TemplatesStateModel>): void {
		const state: TemplatesStateModel = context.getState();
		state.templates = [];
		state.templateSnacks = [];
		state.institutionMap = null;
		state.apiErrors = [];
		context.patchState(state);
		context.dispatch(new GetTemplates());
		context.dispatch(new GetInstitutionFields());
		context.dispatch(new GetInternationalPaymentsCountryData());
	}

	private postProcessTemplatesData(context: StateContext<TemplatesStateModel>, state?: TemplatesStateModel, templates?: Template[]): void {
		const stateToProcess: TemplatesStateModel = state ? state : context.getState();
		const templatesToProcess: Template[] = templates ? templates : stateToProcess.templates;
		const templateNotificationSnacks: ReadyForReviewSnack[] = this.getSnacksAndSetOtherData(templatesToProcess);
		const sortedTemplates: Template[] = this.sortTemplatesAlphabetically(templatesToProcess);
		stateToProcess.templateSnacks = templateNotificationSnacks;
		stateToProcess.templates = sortedTemplates;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private getSnacksAndSetOtherData(templates: Template[]): ReadyForReviewSnack[] {
		let templateNotificationSnacks: ReadyForReviewSnack[] = [];
		templates.forEach((template: Template) => {
			template.account = this.checkIfTemplatesHavePaymentsAccount(template);
			template.needsReview = this.checkIfTemplateNeedsReview(template);
			if (template.needsReview) {
				const requestor: string = 'A user '; // TODO: Update with actual user name once apis are updated
				const actionText: string = `${template.changeRequest.changeType.toLowerCase()}d a `;
				const templateNameText: string = `template (${template.name})`;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${templateNameText}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(template.changeRequest.createdAt).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: template,
					resourceId: template.templateId,
					resourceType: TrovataResourceType.template,
					resourceViewText: TrovataResourceViewText.Template,
					sortByDate: template.changeRequest.createdAt,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				templateNotificationSnacks.push(snack);
			}
		});
		templateNotificationSnacks = sortSnacksByDate(templateNotificationSnacks);
		return templateNotificationSnacks;
	}

	private checkIfTemplatesHavePaymentsAccount(template: Template): PaymentsAccount | undefined {
		const foundPaymentsAccount: PaymentsAccount | undefined = this.getAccountIdByAccountNumber(template.beneficiary.accountNumber);
		return foundPaymentsAccount;
	}

	private getAccountIdByAccountNumber(accountNumber: string): PaymentsAccount | undefined {
		return this.accounts.find((account: PaymentsAccount) => account.accountNumber === accountNumber);
	}

	private checkIfTemplateNeedsReview(template: Template): boolean {
		if (template.changeRequest && template.changeRequest.createdBy !== this.authUser['https://auth.trovata.io/userinfo/userId']) {
			return true;
		}
		return false;
	}

	private sortTemplatesAlphabetically(templates: Template[]): Template[] {
		const sortedTemplates: Template[] = templates.sort((templateA: Template, templateB: Template) => {
			if (templateA.name < templateB.name) {
				return -1;
			} else if (templateA.name > templateB.name) {
				return 1;
			}
			return 0;
		});
		return sortedTemplates;
	}

	@Action(RemoveTemplateFromStoreById)
	removeTemplateFromStoreById(context: StateContext<TemplatesStateModel>, action: RemoveTemplateFromStoreById): void {
		const state: TemplatesStateModel = context.getState();
		state.templates = state.templates.filter((filterTemplate: Template) => filterTemplate.templateId !== action.templateId);
		context.patchState(state);
	}
}
