import { AccountTargetV3 } from './account-target.model';

export class Currency {
	symbol: string;
	name: string;
	/* eslint-disable */
	symbol_native: string;
	decimal_digits: number;
	rounding: number;
	code: string;
	name_plural: string;
	/* eslint-enable */
}

export const defaultCurrency: Currency = {
	symbol: null,
	name: 'Default',
	symbol_native: null,
	decimal_digits: null,
	rounding: null,
	code: 'default',
	name_plural: null,
};

export type CurrencyDict = Record<string, Currency>;
export type AccountsDict = Map<string, AccountTargetV3>;

export class CurrencyBalance {
	balance: number;
	nativeBalance: number;
	currency: string;
	conversionRate: number;
	overallPercentage: number;
	conversionRateTimestamp: string;
}

export class CurrencySummaryResponse {
	currencyBalances: CurrencyBalance[];
	currencyConverted: string;
}

export enum CurrencyControlSize {
	small = 'small',
	mini = 'mini',
}
