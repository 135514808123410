import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AllFiltersModel } from '../store/state/filter-object/filter-object.state';
import { ParameterType } from '../models/search-parameter.model';
import { AccountFilter, Filter, GenericFilter, InvoiceFilter, QueryType, TransactionFilter } from '../models/abstract-filter.model';
import { AccountFiltersService } from 'src/app/features/settings/services/account-filters.service';
import { AccountFilterType } from 'src/app/features/reports/models/account-filters.model';
import { InvoiceFiltersService } from './invoice-filters.service';
import { TransactionFiltersService } from './transaction-filters.service';
import { Select } from '@ngxs/store';
import { PermissionId, PermissionMap } from '../../features/settings/models/feature.model';
import { CustomerFeatureState } from '../../features/settings/store/state/customer-feature.state';
import { TransactionFilterType } from '../models/transaction-filters.model';
import { GlCodeFacadeService } from '../../features/transactions/services/facade/gl-code.facade.service';
import { firstValidValueFrom } from '../utils/firstValidValueFrom';
import { GLCode } from '../../features/transactions/models/gl-code.model';
import { Entity } from '@trovata/app/features/entities/models/entity.model';
import { EntitiesState } from '@trovata/app/features/entities/store/state/entities.state';

@Injectable({
	providedIn: 'root',
})
export class FilterObjectService {
	private destroyed$: Subject<boolean>;
	@Select(EntitiesState.entities) entities$: Observable<Entity[]>;
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	userAvailablePermissions: PermissionMap;
	constructor(
		private transactionFilterService: TransactionFiltersService,
		private accountFilterService: AccountFiltersService,
		private invoiceFilterService: InvoiceFiltersService,
		private glCodeFacadeService: GlCodeFacadeService
	) {
		this.destroyed$ = new Subject<boolean>();
		// this.getUserAvailablePermissions();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private async getUserAvailablePermissions(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				this.userAvailablePermissions = await firstValidValueFrom(this.userAvailablePermissions$);
				resolve();
			} catch {
				reject();
			}
		});
	}

	public loadAllFilters(): Observable<any> {
		const trxnAccountFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.accounts).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const trxnInstitutionFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.institutions).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const trxnCurrenciesFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.currencies).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const trxnTagsFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.tags).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const trxnGlTagsFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.glTags).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const trxnGlAutoTagsFilters = this.transactionFilterService.getTransactionFilters(TransactionFilterType.glAutoTags).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const glCodes: Observable<GLCode[]> =
			this.userAvailablePermissions && !this.userAvailablePermissions.has(PermissionId.readGlTags)
				? of([])
				: this.userAvailablePermissions
					? from(this.glCodeFacadeService.getGlCodes())
					: from(
							this.getUserAvailablePermissions().then(() =>
								!this.userAvailablePermissions.has(PermissionId.readGlTags) ? [] : this.glCodeFacadeService.getGlCodes()
							)
						);
		const accAccountTagFilters = this.accountFilterService.getAccountFilters(AccountFilterType.accountTags).pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const invoiceFilters = this.invoiceFilterService.getInvoiceFilters().pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const invoiceFiltersAP = this.invoiceFilterService.getInvoiceFilters('AP').pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const invoiceFiltersAR = this.invoiceFilterService.getInvoiceFilters('AR').pipe(
			catchError(err => {
				throwError(() => err);
				return of({});
			})
		);
		const entityList = firstValidValueFrom(this.entities$).catch(err => {
			throwError(() => err);
			return of({});
		});
		return combineLatest([
			trxnAccountFilters,
			trxnInstitutionFilters,
			trxnCurrenciesFilters,
			trxnTagsFilters,
			trxnGlTagsFilters,
			trxnGlAutoTagsFilters,
			glCodes,
			accAccountTagFilters,
			invoiceFilters,
			invoiceFiltersAP,
			invoiceFiltersAR,
			entityList,
		]).pipe(
			map(
				([
					trxnAccountResult,
					trxnInstitutionResult,
					trxnCurrenciesResult,
					trxnTagsResult,
					trxnGlTagsResult,
					trxnGlAutoTagsResult,
					glCodesResult,
					accAccountTagResult,
					invoiceResult,
					invoiceAPResult,
					invoiceARResult,
					entities,
				]) => {
					const ogFilters: Object = {
						transactionFilters: {
							...trxnAccountResult,
							...trxnInstitutionResult,
							...trxnCurrenciesResult,
							...trxnTagsResult,
							...trxnGlTagsResult,
							...trxnGlAutoTagsResult,
							glCodes: glCodesResult,
						},
						accountFilters: {
							...trxnAccountResult,
							...trxnInstitutionResult,
							...trxnCurrenciesResult,
							...accAccountTagResult,
						},
						invoiceFilters: invoiceResult['body'],
						invoiceAPFilters: invoiceAPResult['body'],
						invoiceARFilters: invoiceARResult['body'],
					};
					const allFilters = this.setAllFilters(ogFilters, entities);

					return { allFilters, ogFilters };
				}
			),
			catchError(error => throwError(() => error))
		);
	}

	setAllFilters(ogFilters, entityList) {
		return {
			transactionOptions: [new TransactionFilter('type', [])],
			accountOptions: this.setAccountFilters(ogFilters['accountFilters'], entityList),
			invoiceOptions: this.setInvoiceFilters(ogFilters['invoiceFilters']),
			invoiceAPOptions: this.setInvoiceFilters(ogFilters['invoiceAPFilters']),
			invoiceAROptions: this.setInvoiceFilters(ogFilters['invoiceARFilters']),
			genericOptions: this.setTransactionFilters(ogFilters['transactionFilters']),
		};
	}

	// Todo Fix ANY
	loadFilter(filterCall: any, filterType: any): Observable<any> {
		if (filterCall === 'transaction') {
			if (filterType === TransactionFilterType.glAutoTags || filterType === TransactionFilterType.glTags) {
				return combineLatest([
					this.transactionFilterService.getTransactionFilters(TransactionFilterType.glAutoTags),
					this.transactionFilterService.getTransactionFilters(TransactionFilterType.glTags),
				]).pipe(
					map(([trxnGlTagsResult, trxnGlAutoTagsResult]) => {
						const combinedResult = {
							...trxnGlAutoTagsResult,
							...trxnGlTagsResult,
						};
						const ogFilter = combinedResult;
						const filterOptions = this.setTransactionFilters(combinedResult);
						return { filterOptions, ogFilter };
					})
				);
			} else {
				return this.transactionFilterService.getTransactionFilters(filterType).pipe(
					map(res => {
						const ogFilter = res;
						const filterOptions = this.setTransactionFilters(res);
						return { filterOptions, ogFilter };
					})
				);
			}
		} else if (filterCall === 'account') {
			return this.accountFilterService.getAccountFilters(filterType).pipe(
				map(res => {
					const ogFilter = res;
					const filterOptions = this.setAccountFilters(res, []);
					return { filterOptions, ogFilter };
				})
			);
		} else if (filterCall === 'glCode') {
			if (!this.userAvailablePermissions?.has(PermissionId.readGlTags)) {
				return of({
					filterOptions: [
						{
							container: 'glTag',
							type: 'glCode1',
							displayType: 'Debit G/L Code',
							options: [],
						},
						{
							container: 'glTag',
							type: 'glCode2',
							displayType: 'Credit G/L Code',
							options: [],
						},
					],
					ogFilter: { glCodes: [] },
				});
			} else {
				return from(this.glCodeFacadeService.getGlCodes()).pipe(
					map(glCodes => {
						const ogFilter = { glCodes: glCodes };
						const filterOptions = this.setTransactionFilters({
							glCodes: glCodes,
						});
						return { filterOptions, ogFilter };
					})
				);
			}
		}
	}

	private setTransactionFilters(filters) {
		const genericOptions = [];
		const glTags: TransactionFilter[] = [];
		for (const key in filters) {
			if (filters.hasOwnProperty(key) && !key.startsWith('total')) {
				if (key !== 'tags' && key !== 'glCodes' && key !== 'glTags' && key !== 'glAutoTags') {
					genericOptions.push(new TransactionFilter(key, filters[key]));
				} else if (key === 'glTags' || key === 'glAutoTags') {
					glTags.push(new TransactionFilter('glTags', filters[key]));
				} else if (key === 'glCodes') {
					genericOptions.push(new TransactionFilter(ParameterType.debitGLCode, filters[key]));
					genericOptions.push(new TransactionFilter(ParameterType.creditGLCode, filters[key]));
				} else {
					genericOptions.push(new GenericFilter('tags', filters[key]));
					genericOptions.push(new GenericFilter('excludeTags', filters[key]));
				}
			}
		}
		// combine/add GLTags, they are 2 separate filters but 1 transaction filter type
		if (glTags.length > 0) {
			let combinedFilter: TransactionFilter;
			glTags.forEach(filter => {
				if (combinedFilter === undefined) {
					combinedFilter = filter;
				} else {
					combinedFilter.options = [...combinedFilter.options, ...filter.options];
				}
			});
			genericOptions.push(combinedFilter);
		}
		return genericOptions;
	}

	private setAccountFilters(filters, entityList: Entity[]) {
		const accountOptions = [];
		for (const key in filters) {
			if (filters.hasOwnProperty(key) && !key.startsWith('total')) {
				if (key !== 'accountTags') {
					accountOptions.push(new TransactionFilter(key, filters[key]));
				} else {
					accountOptions.push(
						new AccountFilter(
							'entity',
							filters.accountTags.sort((a, b) => a.tagTitle?.trim().localeCompare(b.tagTitle?.trim(), { sensitivity: 'base' }))
						)
					);
					accountOptions.push(
						new AccountFilter(
							'region',
							filters.accountTags.sort((a, b) => a.tagTitle?.trim().localeCompare(b.tagTitle?.trim(), { sensitivity: 'base' }))
						)
					);
					accountOptions.push(
						new AccountFilter(
							'division',
							filters.accountTags.sort((a, b) => a.tagTitle?.trim().localeCompare(b.tagTitle?.trim(), { sensitivity: 'base' }))
						)
					);
				}
			}
		}
		accountOptions.push(new AccountFilter('entityId', entityList));
		accountOptions.push(new AccountFilter('entityDivision', entityList));
		accountOptions.push(new AccountFilter('entityRegion', entityList));
		return accountOptions;
	}

	private setInvoiceFilters(filters, type?: string) {
		const options = [];
		for (const key in filters) {
			if (filters.hasOwnProperty(key)) {
				if (typeof filters[key] === 'object') {
					options.push(new InvoiceFilter(key, filters[key]));
				}
			}
		}
		options.push(new InvoiceFilter(ParameterType.invoiceType, []));
		options.push(new InvoiceFilter(ParameterType.invoiceProvider, []));
		return options;
	}

	getFilterOptions(allFilters: AllFiltersModel, queryType?: QueryType, customFilters?: string[]): Filter[] {
		let filterOptions: Filter[] = [];
		if (allFilters) {
			if (queryType === QueryType.transaction) {
				filterOptions = [...allFilters.transactionOptions, ...allFilters.genericOptions];
			} else if (queryType === QueryType.invoice || queryType === QueryType.invoiceAP || queryType === QueryType.invoiceAR) {
				if (queryType === QueryType.invoiceAP) {
					filterOptions = [...allFilters.invoiceAPOptions];
				} else if (queryType === QueryType.invoiceAR) {
					filterOptions = [...allFilters.invoiceAROptions];
				} else {
					filterOptions = [...allFilters.invoiceOptions];
				}
				if (customFilters && customFilters.length > 0) {
					filterOptions = [...allFilters.genericOptions];
				}
			} else if (queryType === QueryType.account) {
				filterOptions = [...allFilters.accountOptions];
			} else {
				filterOptions = [...allFilters.transactionOptions, ...allFilters.invoiceOptions, ...allFilters.genericOptions, ...allFilters.accountOptions];
			}
			if (customFilters && customFilters.length > 0) {
				filterOptions = filterOptions.filter(filter => customFilters.some(custom => custom === filter.type));
				[...allFilters.transactionOptions, ...allFilters.invoiceOptions, ...allFilters.genericOptions, ...allFilters.accountOptions]
					.filter(filter => customFilters.some(custom => custom === filter.type) && !filterOptions.some(option => option.type === filter.type))
					.forEach(filter => filterOptions.push(filter));
			}
		}
		const result: Filter[] = []; // eliminate duplicated opts
		filterOptions.forEach(opt => {
			if (!result.find(flt => flt.type === opt.type)) {
				result.push(opt);
			}
		});
		return result;
	}

	getFilterMap(allFilters: AllFiltersModel, queryType?: QueryType, customFilters?: string[]): Map<string, Filter> {
		const filters: Filter[] = this.getFilterOptions(allFilters, queryType, customFilters);
		const filterObject: Map<string, Filter> = new Map();
		filters.forEach(flt => (filterObject[flt['type']] = flt));
		return filterObject;
	}
}
