import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { DefaultEnvironment, ENVIRONMENT_TOKEN, STORE_TOKEN } from './config.model';
import { SessionWebWorkerService } from '../services/session-web-worker.service';
import { HeapAnalyticsService } from '../services/heap-analytics.service';

class Logout {
	static readonly type: string = '[Auth] Logout';
}

class SetErrorMessage {
	static readonly type: string = '[Auth] SetErrorMessage';
	constructor(public errorMessage: string) {}
}

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	private accessToken: string;

	constructor(
		@Inject(ENVIRONMENT_TOKEN) private environment: DefaultEnvironment,
		@Inject(STORE_TOKEN) private store: Store,
		private sessionService: SessionWebWorkerService,
		private heapService: HeapAnalyticsService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (localStorage.auth) {
			this.accessToken = localStorage.auth;
		}

		const secureReq: HttpRequest<any> = req.clone({
			url: req.url.replace('http://', 'https://'),
		});

		const allowWithoutAuth: boolean = this.allowWithoutAuth(secureReq.url);

		if (allowWithoutAuth) {
			return next.handle(secureReq.clone());
		}
		if (this.accessToken && this.isApi(secureReq.url)) {
			if (this.sessionService.isAccessTokenValid()) {
				const clonedReq: HttpRequest<any> = secureReq.clone({
					headers: secureReq.headers.set('Authorization', `Bearer ${this.accessToken}`),
				});
				return this.handleClonedRequest(clonedReq, next);
			} else {
				// not catch needed, log outs of app on failure to refresh
				this.getNewAccessToken().then((newAccessToken: string) => {
					const clonedReq: HttpRequest<any> = secureReq.clone({
						headers: secureReq.headers.set('Authorization', `Bearer ${newAccessToken}`),
					});
					this.handleClonedRequest(clonedReq, next);
				});
			}
		} else {
			return next.handle(req);
		}
	}

	private handleClonedRequest(clonedReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(clonedReq).pipe(
			tap(
				() => {},
				async (httpError: HttpErrorResponse) => {
					this.heapService.apiError(JSON.stringify(httpError));

					const route: string = document.location.href.split(document.location.origin)[1];

					if (route === '/login' && clonedReq.url === `${this.environment.edgeAPI()}/user` && httpError.error && httpError.error.message) {
						const errorMessage: string = httpError.error.message;
						this.store.dispatch(new SetErrorMessage(errorMessage));
					}

					if (
						((httpError && httpError.status && httpError.status === 401) ||
							(httpError && httpError.error && httpError.error.status && httpError.error.status === 401)) &&
						// TODO remove this when change password failure is not 401: https://trovata.atlassian.net/browse/CP-274
						!(httpError && httpError.url?.includes('/auth/password'))
					) {
						this.store.dispatch(new Logout());
					}

					if (httpError && httpError.error && httpError.error.message) {
						return throwError(httpError.error.message);
					} else if (httpError && httpError.message) {
						return throwError(httpError.message);
					} else {
						return throwError('Something bad happened; please try again later.');
					}
				}
			)
		);
	}

	private getNewAccessToken(): Promise<string> {
		return new Promise(resolve => {
			let accessToken: string;
			const cleanupSubject$: Subject<void> = new Subject();
			this.sessionService.newTokenRetrievalInFlight$.pipe(takeUntil(cleanupSubject$)).subscribe(async (isInFlight: boolean) => {
				if (!isInFlight) {
					// not catch needed, log outs of app on failure to refresh
					accessToken = await this.sessionService.refreshTokens();
					cleanupSubject$.next();
					cleanupSubject$.complete();
					resolve(accessToken);
				}
			});
		});
	}

	private isApi(url: string): boolean {
		if (
			url.includes(this.environment.edgeApi) ||
			url.includes(this.environment.trovataApi) ||
			url.includes(this.environment.developerApi) ||
			url.includes('https://api.trovata.io/manage') ||
			url.includes('https://api.trovata.io/payments-sandbox')
		) {
			return true;
		} else {
			return false;
		}
	}

	private allowWithoutAuth(url: string): boolean {
		if (
			url.includes('/auth/token') ||
			url.includes('/login/netsuite') ||
			url.includes('/login/forgotPassword') ||
			url.includes('/connections/stripe/prices') ||
			url.includes('/connections/stripe/customer') ||
			url.includes('/connections/stripe/publishablekey') ||
			url.includes('/connections/stripe/paymentmethod') ||
			url.includes('/connections/stripe/checkout/sessions') ||
			url.includes('/connections/customer') ||
			url.includes('/connections/recaptcha/sitekey') ||
			url.includes('/connections/recaptcha') ||
			url.includes('/connections/stripe/subscriptions') ||
			url.includes('/auth/token/verification') ||
			url.includes('/auth/token/verification/request') ||
			url.includes('/connections/stripe/coupon')
		) {
			return true;
		} else {
			return false;
		}
	}
}
