import { ChangeRequest } from 'src/app/shared/models/admin-approval.model';
import { of } from 'rxjs';
import { PaymentExecutionStage, PaymentExecutionStageApproval, PaymentExecutionStageSignature } from './payment.model';
import { WorkflowRow } from './paginated-workflows-table-view.model';
import { CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';

export interface GetWorkflowsResponse {
	count: number;
	workflows: Workflow[];
}

export interface Workflow {
	active: boolean;
	allowPreparerApproval: boolean;
	changeRequest: ChangeRequest | null;
	createdAt: string;
	customerId: string;
	isBatch: boolean;
	maximumAmount: number | null;
	name: string;
	stages: WorkflowStage[];
	uniqueApproval: boolean;
	updatedAt: string;
	version: number;
	workflowId: string;

	needsReview?: boolean;
	accounts: WorkflowAccount[];
}

export interface WorkflowAccount {
	accountId: string;
	name: string;
	accountNumber: string;
}

export interface WorkflowToCreateOrUpdate {
	name: string;
	allowPreparerApproval: boolean;
	maximumAmount: number;
	uniqueApproval: boolean;
	stages: WorkflowStage[];
	workflowId: string;
	isBatch: boolean;
	accountIds: string[];
}

export interface WorkflowStageRule {
	approval: WorkflowStageRuleApproval;
	condition: WorkflowStageRuleCondition;
	numericConstant: string | number;
	variable: WorkflowStageRuleVariable;

	ruleId?: string;
	addedRuleToSave?: boolean;
}

export interface WorkflowStageRuleApproval {
	numApprovals: number;
	userIds: string[];
	users?: ApprovalUser[];
}

export interface StageRuleDetail {
	approver: string;
	approvalsRequired: number;
	email: string;
	rule: string;
	status: string;
	needAuthUserApproval: boolean;
}

export interface WorkflowStage {
	approval: WorkflowStageApproval;
	name: string;
	order?: number;
	rules?: WorkflowStageRule[];
	stageId?: string;
}

export enum StageVariableOption {
	PAYMENT_AMOUNT = 'Payment Amount',
}

export enum StageConditionOption {
	GREATER_THAN = 'Payment amount is greater than',
}

export enum WorkflowStageRuleCondition {
	GREATER_THAN = '>',
}

export const workflowStageRuleConditions: WorkflowStageRuleCondition[] = [WorkflowStageRuleCondition.GREATER_THAN];

export interface ConditionOption {
	label: StageConditionOption;
	condition: WorkflowStageRuleCondition;
}

export const CONDITION_OPTIONS: ConditionOption[] = [
	{
		label: StageConditionOption.GREATER_THAN,
		condition: WorkflowStageRuleCondition.GREATER_THAN,
	},
];

export enum WorkflowStageRuleVariable {
	PAYMENT_AMOUNT = 'paymentAmount',
}

export interface WorkflowStageRuleVariableOption {
	variable: WorkflowStageRuleVariable;
	label: string;
}

export const workflowStageRuleVariableOptions: WorkflowStageRuleVariableOption[] = [
	{
		variable: WorkflowStageRuleVariable.PAYMENT_AMOUNT,
		label: 'Payment Amount',
	},
];

export interface WorkflowStageApproval {
	numApprovals: number;
	userIds: string[];
	users: ApprovalUser[];
}

export interface ApprovalUser {
	email: string;
	familyName: string;
	givenName: string;
	userId: string;
}

export enum PaymentStatus {
	OPEN = 'open',
	SUBMITTED = 'submitted',
	PENDING = 'pending',
	APPROVED = 'approved',
	REJECTED = 'rejected',
	FAILED = 'failed',
	PROCESSING = 'processing',
	CANCELLED = 'canceled',
	CHANGE = 'change',
	ACCEPTED = 'accepted',
	COMPLETE = 'complete', // TODO: remove after all data no longer uses this
	COMPLETED = 'completed',
	REJECTED_BY_BANK = 'rejected by bank',
}

export interface WorkflowToDelete {
	workflowId: string;
}

export enum CreateEditWorkflowFormPrefix {
	initiate = 'initiate',
	review = 'review',
	approve = 'approve',
	release = 'release',
}

export interface WorkflowMenuEvent {
	option: CustomMenuOption;
	row: WorkflowRow;
}

export const MOCK_APPROVAL_USER: ApprovalUser = {
	email: 'test',
	familyName: 'test',
	givenName: 'test',
	userId: 'test',
};

export const MOCK_STAGE_APPROVAL_USERS: ApprovalUser[] = [MOCK_APPROVAL_USER];

export const MOCK_STAGE_APPROVAL: WorkflowStageApproval = {
	numApprovals: 1,
	userIds: ['test'],
	users: MOCK_STAGE_APPROVAL_USERS,
};

export const MOCK_STAGE_SIGNATURE: PaymentExecutionStageSignature = {
	email: 'test',
	familyName: 'test',
	givenName: 'test',
	timestamp: 'test',
	userId: 'test',
	comment: 'test',
};

export const MOCK_STAGE: WorkflowStage = {
	approval: MOCK_STAGE_APPROVAL,
	stageId: 'test',
	name: 'test',
	order: 1,
	rules: [], // TODO
};

export const MOCK_PAYMENT_EXECUTION_STAGE_APPROVAL: PaymentExecutionStageApproval = {
	numApprovals: 1,
	userIds: ['test'],
	users: [],
};

export const MOCK_PAYMENT_EXECUTION_STAGE: PaymentExecutionStage = {
	approval: MOCK_PAYMENT_EXECUTION_STAGE_APPROVAL,
	stageId: 'test',
	name: 'test',
	rules: [],
	signatures: [],
	status: PaymentStatus.ACCEPTED,
};

export const MOCK_WORKFLOW: Workflow = {
	active: true,
	allowPreparerApproval: false,
	changeRequest: null,
	createdAt: '',
	customerId: 'test',
	isBatch: false,
	maximumAmount: 0,
	name: 'test',
	stages: [MOCK_STAGE],
	uniqueApproval: false,
	updatedAt: '',
	version: 1,
	workflowId: 'test',
	accounts: [],
};

export const MOCK_APPROVALS: WorkflowStageApproval[] = [MOCK_STAGE_APPROVAL];

export const MOCK_STAGES: WorkflowStage[] = [MOCK_STAGE];

export const MOCK_WORKFLOWS: Workflow[] = [MOCK_WORKFLOW];

export const MOCK_WORKFLOW_TO_DELETE: WorkflowToDelete = {
	workflowId: 'test',
};

export const MOCK_WORKFLOWS_SERVICE = {
	getWorkflows: () => of(MOCK_WORKFLOWS),
	createWorkflow: () => of(MOCK_WORKFLOW),
	updateWorkflow: (body: Workflow) => of(MOCK_WORKFLOW),
	deleteWorkflow: (body: WorkflowToDelete) => of({}),
};
