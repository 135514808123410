import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig, webSocket } from 'rxjs/webSocket';
import { Subject, repeat, retry, takeUntil, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { environment } from '@trovata/environments/environment';
import { Message } from '../models/web-socket.model';
import { PaymentsSocketHandler } from '@trovata/app/features/payments/store/state/socket-handler';
import { DefaultEnvironment } from '../models/config.model';
import { TrovataAppState } from '../models/state.model';
import { AuthStateModel } from '../store/state/auth/auth.state';

@Injectable({
	providedIn: 'root',
})
export class WebSocketService implements OnDestroy {
	private webSocket$: WebSocketSubject<object>;
	private environment: DefaultEnvironment;
	private currentAccessToken: string;
	private pingInterval: any;

	private onDestroy$: Subject<boolean>;

	constructor(
		private store: Store,
		private paymentsSocketHandler: PaymentsSocketHandler
	) {
		this.environment = environment;
		this.onDestroy$ = new Subject();
	}

	ngOnDestroy(): void {
		this.closeConnection();
	}

	private setAccessToken(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.store
				.select((state: TrovataAppState) => state.auth)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((authState: AuthStateModel) => {
					if (authState?.accessToken && authState.accessToken !== this.currentAccessToken) {
						this.currentAccessToken = authState?.accessToken;
						resolve();
					}
				});
		});
	}

	async initializeConnection(): Promise<void> {
		await this.setAccessToken();
		if (this.webSocket$) {
			this.webSocket$.complete();
		}
		this.webSocket$ = this.createWebSocket(this.environment.trovataGeneralSocket, this.currentAccessToken);
		this.webSocket$
			.pipe(
				retry({
					delay: () => timer(2000),
				}),
				repeat()
			)
			.subscribe({
				next: (msg: Message) => this.routeMessage(msg), // Called whenever there is a message from the server.
				error: (err: any) => {
					setTimeout(() => {
						this.initializeConnection();
					}, 3000);
					throw err;
				},
			});

		this.startPing();
	}

	closeConnection(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
		this.stopPing();
	}

	private createWebSocket(socketUrl: string, authToken: string): WebSocketSubject<object> {
		const endpoint: URL = new URL(socketUrl);
		endpoint.searchParams.set('authorizationToken', 'Bearer ' + authToken);
		const socketConfig: WebSocketSubjectConfig<object> = {
			url: endpoint.toString(),
			serializer: (value: object) => JSON.stringify(value),
		};
		return webSocket(socketConfig);
	}

	private routeMessage(msg: Message): void {
		switch (msg.category) {
			case 'payments': {
				this.paymentsSocketHandler.routePayments(msg);
			}
		}
	}

	private startPing(): void {
		this.pingInterval = setInterval(
			() => {
				this.webSocket$.next({});
			},
			8 * 60 * 1000
		); // Ping every 8 minutes
	}

	private stopPing(): void {
		if (this.pingInterval) {
			clearInterval(this.pingInterval);
			this.pingInterval = null;
		}
	}
}
