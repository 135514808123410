import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CurrencyDict, Currency, CurrencySummaryResponse } from 'src/app/shared/models/currency.model';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { ClearCurrencyState, GetCurrencies, GetCurrencySummary, InitCurrencyState, ResetCurrencyState } from 'src/app/shared/store/actions/currency.actions';

export class CurrencyStateModel {
	currencies: Currency[];
	currenciesDict: CurrencyDict;
	currencySummary: CurrencySummaryResponse;
}

@State<CurrencyStateModel>({
	name: 'currency',
	defaults: {
		currencies: null,
		currenciesDict: null,
		currencySummary: null,
	},
})
@Injectable()
export class CurrencyState {
	@Selector()
	static currencies(state: CurrencyStateModel): Currency[] {
		return state.currencies;
	}
	@Selector()
	static currenciesDict(state: CurrencyStateModel): CurrencyDict {
		return state.currenciesDict;
	}
	@Selector()
	static currencySummary(state: CurrencyStateModel): CurrencySummaryResponse {
		return state.currencySummary;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private currencyService: CurrencyService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitCurrencyState)
	async initCurrencyState(context: StateContext<CurrencyStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const currencyStateIsCached: boolean = this.currencyStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					let state: CurrencyStateModel = context.getState();
					if (currencyStateIsCached && appReady) {
						state = deserializedState.currency;
						context.patchState(state);
					} else if (!currencyStateIsCached && appReady) {
						// needs investigation, this empty dict patch is likely not needed and may cause errors
						state.currenciesDict = {};
						context.patchState(state);
						context.dispatch(new GetCurrencies());
						context.dispatch(new GetCurrencySummary());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetCurrencies)
	getCurrencies(context: StateContext<CurrencyStateModel>, action: GetCurrencies): Promise<void> {
		return this.currencyService.getCurrencies(action.shouldRefresh).pipe(
			tap((response: HttpResponse<Currency[]>) => {
				const currencies: Currency[] = response.body;
				const currenciesDict: CurrencyDict = {};
				currencies.forEach((eachCurrency: Currency) => {
					currenciesDict[eachCurrency.code] = eachCurrency;
				});
				context.patchState({ currencies: currencies, currenciesDict: currenciesDict });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(GetCurrencySummary)
	getCurrencySummary(context: StateContext<CurrencyStateModel>, action: GetCurrencySummary) {
		return this.currencyService.getCurrenciesSummary().pipe(
			tap((resp: HttpResponse<CurrencySummaryResponse>) => {
				const currencySummary: CurrencySummaryResponse = resp.body;
				context.patchState({ currencySummary: currencySummary });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ResetCurrencyState)
	resetCurrencyState(context: StateContext<CurrencyStateModel>): void {
		context.dispatch(new ClearCurrencyState());
		context.dispatch(new InitCurrencyState());
	}

	@Action(ClearCurrencyState)
	clearCurrencyState(context: StateContext<CurrencyStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: CurrencyStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private currencyStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedCurrencyState: CurrencyStateModel | undefined = deserializedState.currency;
		if (
			deserializedCurrencyState &&
			deserializedCurrencyState.currencies &&
			deserializedCurrencyState.currenciesDict &&
			deserializedCurrencyState.currencySummary
		) {
			return true;
		} else {
			return false;
		}
	}
}
