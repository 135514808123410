import { AdminApproval } from 'src/app/shared/models/admin-approval.model';
import { Template, TemplateToCreate, TemplateToDelete } from '../../models/template.model';

export class InitTemplatesState {
	static readonly type: string = '[Templates] InitTemplatesState';
}

export class ClearTemplatesState {
	static readonly type: string = '[Templates] ClearTemplatesState';
}

export class ResetTemplatesState {
	static readonly type: string = '[Templates] ResetTemplatesState';
}

export class GetTemplates {
	static readonly type: string = '[Templates] GetTemplates';
}

export class GetInstitutionFields {
	static readonly type: string = '[Templates] GetInstitutionFields';
}

export class GetInternationalPaymentsCountryData {
	static readonly type: string = '[Templates] GetInternationalPaymentsCountryData';
}

export class CreateTemplate {
	static readonly type: string = '[Templates] CreateTemplate';
	constructor(public templateToCreate: TemplateToCreate) {}
}

export class UpdateTemplate {
	static readonly type: string = '[Templates] UpdateTemplate';
	constructor(public templateToUpdate: Template) {}
}

export class DeleteTemplate {
	static readonly type: string = '[Templates] DeleteTemplate';
	constructor(public templateToDelete: TemplateToDelete) {}
}

export class GetTemplateById {
	static readonly type: string = '[Templates] GetTemplateById';
	constructor(public templateId: string) {}
}

export class PostTemplateAdminApproval {
	static readonly type: string = '[Templates] PostTemplateAdminApproval';
	constructor(public adminApproval: AdminApproval) {}
}

export class RemoveTemplateFromStoreById {
	static readonly type: string = '[Payments] RemoveTemplateFromStoreById';
	constructor(public templateId: string) {}
}
