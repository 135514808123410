import { Currency } from '@trovata/app/shared/models/currency.model';
import { Tag } from './tag.model';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';

export enum ParameterType {
	accountId = 'accountId',
	currency = 'currency',
	endDate = 'endDate',
	endIngestionTimestamp = 'endIngestionTimestamp',
	excludedFromTags = 'excludedFromTags',
	excludeTags = 'excludeTags',
	filter = 'filter',
	institutionId = 'institutionId',
	sort = 'sort',
	startDate = 'startDate',
	startIngestionTimestamp = 'startIngestionTimestamp',
	tag = 'tag',
	tagId = 'tagId',
	type = 'type',
}

export class TrxnSearchData {
	filter: string = '';
	parameters: SearchParameter[] = [];
	startDateParameter: SearchParameter;
	endDateParameter: SearchParameter;
	groupBy: string = '';
	currencyConverted: Currency;

	static fromTrxnSearchData(data: TrxnSearchData): TrxnSearchData {
		const newData = new TrxnSearchData();
		newData.filter = data.filter;
		newData.parameters = [...data.parameters];
		newData.endDateParameter = data.endDateParameter;
		newData.startDateParameter = data.startDateParameter;
		return newData;
	}

	validateSearchData(): boolean {
		let valid = false;
		if (this) {
			if (this.filter && this.filter !== '') {
				valid = true;
			} else if (this.parameters && this.parameters.length > 0) {
				valid = true;
			} else if (this.endDateParameter && this.endDateParameter.value !== '') {
				valid = true;
			} else if (this.startDateParameter && this.startDateParameter.value !== '') {
				valid = true;
			}
		}
		return valid;
	}
}

export class TransactionSearchParameter {
	type: ParameterType;
	displayValue: string;
	value: Object;

	constructor(type: ParameterType, displayValue: string, value: Object) {
		this.type = type;

		if (type === ParameterType.accountId) {
			this.displayValue = value['accountNumber'] ? value['accountNumber'] : this.displayValue;
		} else if (type === ParameterType.institutionId) {
			this.displayValue = value['institutionNickname']
				? value['institutionNickname']
				: value['institutionName']
					? value['institutionName'].split('(')[0]
					: displayValue;
		} else {
			this.displayValue = displayValue;
		}

		if (type === ParameterType.type) {
			this.value = value.toString().toUpperCase();
		} else {
			this.value = value;
		}
	}

	getDisplayType(): string {
		switch (this.type) {
			case ParameterType.institutionId: {
				return 'Institution: ';
				break;
			}
			case ParameterType.accountId: {
				return 'Account: ';
				break;
			}
			case ParameterType.currency: {
				return 'Currency: ';
				break;
			}
			case ParameterType.tag: {
				return 'Included Tag: ';
				break;
			}
			case ParameterType.tagId: {
				return 'Included Tag: ';
				break;
			}
			case ParameterType.startDate: {
				return 'Start Date: ';
				break;
			}
			case ParameterType.endDate: {
				return 'End Date: ';
				break;
			}
			case ParameterType.type: {
				return 'Type: ';
				break;
			}
			case ParameterType.excludeTags: {
				return 'Excluded Tag: ';
				break;
			}
			case ParameterType.excludedFromTags: {
				return 'Excluded From Tag: ';
				break;
			}
		}
	}

	getDiscription(): string {
		if ((<Tag>this.value).tagDescription !== undefined) {
			return (<Tag>this.value).tagDescription;
		} else {
			return '';
		}
	}
}
