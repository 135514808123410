import { Component, Inject, LOCALE_ID } from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import { Settings } from 'luxon';

@Component({
	selector: 'trovata-angular-app',
	template: '<router-outlet></router-outlet>',
	styles: [],
})
export class AppComponent {
	constructor(@Inject(LOCALE_ID) public locale: string) {
		Settings.defaultLocale = this.locale;
		wjcCore.setLicenseKey(
			// eslint-disable-next-line max-len
			'uat.trovata.io|d4.trovata.io|d5.trovata.io|d1.trovata.io|beta.trovata.io|d2.trovata.io|dev.trovata.io|small-business.trovata.io|d3.trovata.io|app.trovata.io|nab.trovata.io|dev.nab.trovata.io,581972163338753#B0HdcVnI0IyctRkIsIyYulEIhRXY63mcUJiOiEmTDJCLiMTN7gzMzMjNxIzN9EDO5IiOiQWSiwSfdtlOicGbmJCLiEjd4IDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7Zlb5dFWrljS4VFMnNla7xWcppGaXtWe5g5T7c4M0l4bXRzL64GbyYHWqNHO5d6Mr3CeGV4SHhzZyc4KFJEM8E4c7REeLZVVvQXWY36VDdja7dUe4AjRnNXN0FmQKZzVUJWd7hXculTS6JkW8IGejtCRzV5Ruh6KtlzK4J6UDVWcyVXZsdEUItiM0lTdiVUWNV7R7hHTthnQnNDMPdESk3SV4hTbzJVbGZ7LvgTOVJEUrVDMGNlRLVVdCdTQBJ7TZJXatF6KjVVNLZFZ7d4QWFjZQlTOwQHOQ5mYBh5NCh4MvV4NyMWW5QTanlmMRRmWVNXT9kEZzYmMyIXO8olYNZ7ZKlGNSV4MyJlSkVXTKdlY59mRWdFdKpUTVxmdlZnZr2EWYZzbOh7TP3CN6cVcnVmRaxWdsVDOHdlSqtkZl36L5NmauFmMt56dXpFMqFWTzpVY6NkRiojITJCLiIDN9M4NDVTMiojIIJCL8YDM8UzMxATM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiIjMxQjMwAyMxMDM4IDMyIiOiQncDJCLi2WauEGdhZ7byRnLiFmbuYXZkxybp9SY4FmdvJHduIWYuxybp9SY4FmdvJHduAHchxybp9SY4FmdvJHduMDZs2WauEGdhZ7byRnLzNXZul6c5JWLsxWYtNHLvlmLhRXY63mc49idlRGLvlmLhRXY63mc49iMkxybp9SY4FmdvJHduEGdlJGLvlmLhRXY63mc49SMkxybp9SY4FmdvJHduUDZs2WauEGdhZ7byRnL4QGLvlmLhRXY631cM5'
		);
	}
}
