export const generateSHA256Hashc = async (jsonString: string): Promise<string> => {
	// Convert the JSON string to an ArrayBuffer
	const encoder: TextEncoder = new TextEncoder();
	const data: ArrayBuffer = encoder.encode(jsonString);

	// Generate the SHA-256 hash
	const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', data);

	// Convert the hash ArrayBuffer to a hex string
	const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));
	const hashHex: string = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

	return hashHex;
};
