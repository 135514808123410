import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN = new InjectionToken('environment');
export const STORE_TOKEN = new InjectionToken('store');

export class DefaultEnvironment {
	environmentName: string;
	apiSuffix: string;
	beta?: boolean;
	production?: boolean;
	development?: boolean;
	productionToggles?: boolean;
	testing?: boolean;
	stripe?: string;
	stripePublishableKey?: string;
	writeToConsole?: boolean;
	plaidRedirectUri?: string;
	edgeApi: string;
	trovataApi: string;
	intercomAppId: string;
	developerApi: string;
	workspaceApi: string;
	auth0Domain: string;
	auth0Audience: string;
	auth0ClientId: string;
	auth0TestClientId: string;
	authSocket: string;
	notifSocket: string;
	searchSocket: string;
	trovataAISocket: string;
	trovataGeneralSocket: string;
	useTrovataAIMock?: boolean;
	paymentsUat: boolean;
	defaultCurrency: string;
	sideNavIconPath?: string;
	logoPath?: string;
	edgeAPI(microService?: string): string {
		return this.edgeApi + (microService ?? 'api') + this.apiSuffix;
	}
	trovataAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.trovataApi + (microService ?? 'api') + apiSuffix;
	}
	workspaceAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.workspaceApi + (microService ?? 'api') + apiSuffix;
	}
}

export enum EnvironmentType {
	NAB = 'nab',
	JPM = 'jpm',
	Trovata = 'trovata',
}

export const parseEnvironmentType: (defaultEnv: DefaultEnvironment) => EnvironmentType = (defaultEnv: DefaultEnvironment) => {
	if (defaultEnv.environmentName.startsWith('nab')) {
		return EnvironmentType.NAB;
	} else if (defaultEnv.environmentName.startsWith('jpm')) {
		return EnvironmentType.JPM;
	} else {
		return EnvironmentType.Trovata;
	}
};

export const DEFAULT_ENVIRONMENT_CONFIG: DefaultEnvironment = {
	environmentName: 'local',
	edgeApi: 'https://edge.trovata.io/',
	apiSuffix: '',
	trovataApi: 'https://api.trovata.io/',
	developerApi: 'https://developer-api.trovata.io/v1',
	workspaceApi: 'https://api.trovata.io/workspace',
	auth0Domain: 'login.trovata.io',
	auth0Audience: 'https://edge.trovata.io',
	auth0ClientId: '2V1hJn3u4DvP31ZYfaPQ6tA1KAcdwgOA',
	auth0TestClientId: '5ZYVhVlxRGM8NwD6eAHB8bFISLAA0VdI',
	authSocket: 'wss://auth-ws.trovata.io/prod',
	notifSocket: 'wss://edge-ws.trovata.io/prod',
	plaidRedirectUri: 'https://app.trovata.io/plaid-oauth',
	searchSocket: 'wss://search.trovata.io/prod',
	stripePublishableKey: 'pk_live_ie9dXwN1zhU1u47M2VYzHErp',
	trovataAISocket: 'wss://chat.trovata.io/dev',
	trovataGeneralSocket: 'wss://events-ws.trovata.io/dev',
	logoPath: 'src/assets/images/environments/base',
	defaultCurrency: 'USD',
	sideNavIconPath: 'src/assets/svgs',
	intercomAppId: 'arev3lw3',
	paymentsUat: false,
	useTrovataAIMock: false,
	edgeAPI(microService?: string): string {
		return this.edgeApi + (microService ?? 'api') + this.apiSuffix;
	},
	trovataAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.trovataApi + (microService ?? 'api') + apiSuffix;
	},
	workspaceAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.workspaceApi + apiSuffix + '/data/' + (microService ?? 'api');
	},
};
