import { Injectable } from '@angular/core';
import {
	TQLOperatorOption,
	SymbolPlacement,
	TQLElement,
	TQLElementType,
	TQLInputSource,
	TQLOperatorKey,
	TQLOptionType,
	TQLPropertyKey,
	TQLQuery,
	TQLValue,
	TQLValueControlType,
	TQLFieldsResponse,
	TQLFieldContext,
	TQLFieldDict,
	TQLField,
	TQLFieldType,
	booleanOptions,
	BooleanOption,
} from '../models/tql.model';
import { Select } from '@ngxs/store';
import { AllFiltersModel, FilterObjectState } from '../store/state/filter-object/filter-object.state';
import { Observable } from 'rxjs';
import { firstValidValueFrom } from '../utils/firstValidValueFrom';
import { GenericOption, GenericOptionValue } from '../models/option.model';
import { DateTime } from 'luxon';
import { CalendarSettings } from '../models/date-range-picker.model';
import { getDateRangeFromCalendarSettings } from '../utils/date-picker';
import { DateRange } from '@angular/material/datepicker';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@trovata/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TqlService {
	@Select(FilterObjectState.allFilters) filters$: Observable<AllFiltersModel>;
	private filters: AllFiltersModel;

	constructor(private httpClient: HttpClient) {}

	getTQLFields(context: TQLFieldContext): Observable<HttpResponse<TQLFieldsResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/tql/fields`;
		return this.httpClient.post<any>(url, { context }, { observe: 'response' });
	}

	getTQLValues(fields: string[]): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/tql/values`;
		return this.httpClient.post<any>(url, { fields }, { observe: 'response' });
	}

	async getFilters(): Promise<void> {
		this.filters = await firstValidValueFrom(this.filters$);
	}

	async getTQLQueryFromJSON(query: TQLQuery, tqlJSONExpression?: Object, calendarSettings?: CalendarSettings, embedDatesInTQL?: boolean): Promise<TQLQuery> {
		const tqlJSONExpressionCopy: Object = JSON.parse(JSON.stringify(tqlJSONExpression));
		query.isRoot = true;
		if (tqlJSONExpressionCopy) {
			if (!this.filters) {
				await this.getFilters();
			}
			const jsonWithoutDates: Object = this.extractDates(tqlJSONExpressionCopy, query, embedDatesInTQL);
			// if ((query.startDate || query.endDate) && (calendarSettings.startDate || calendarSettings.endDate)) {
			// 	throw new Error('Dates provided in calendar settings and tqlJSON');
			// } else
			if (calendarSettings && embedDatesInTQL) {
				const dateRange: DateRange<DateTime> = getDateRangeFromCalendarSettings(calendarSettings);
				query.startDate = dateRange.start;
				query.endDate = dateRange.end;
			}
			if (jsonWithoutDates) {
				this.loadTQLJSONExpression(jsonWithoutDates, query);
			}
		}
		query.containsNestedQueries = true;
		return query;
	}

	private extractDates(json: Object, query: TQLQuery, embedDatesInTQL: boolean): Object {
		if (json['lhs'] && json['lhs'].field === TQLPropertyKey.date) {
			// only start OR end date
			if (embedDatesInTQL) {
				if (json['op'] === '<=') {
					query.endDate = DateTime.fromISO(json['rhs']);
				} else {
					query.startDate = DateTime.fromISO(json['rhs']);
				}
			}

			return null;
		} else if (
			json['lhs'] &&
			json['lhs']['lhs'] &&
			json['lhs']['lhs'].field === TQLPropertyKey.date &&
			json['rhs'] &&
			json['rhs']['lhs'] &&
			json['rhs']['lhs'].field === TQLPropertyKey.date
		) {
			// only start AND end date
			if (embedDatesInTQL) {
				query.endDate = DateTime.fromISO(json['lhs']['rhs']);
				query.startDate = DateTime.fromISO(json['rhs']['rhs']);
			}
			return null;
		} else if (json['lhs'] && json['lhs']['lhs'] && json['lhs']['lhs'].field === TQLPropertyKey.date) {
			// start OR end date
			if (embedDatesInTQL) {
				if (json['lhs'].op === '<=') {
					query.endDate = DateTime.fromISO(json['lhs']['rhs']);
				} else {
					query.startDate = DateTime.fromISO(json['lhs']['rhs']);
				}
			}
			return json['rhs'];
		} else if (json['lhs'] && json['lhs']['lhs'] && json['lhs']['lhs']['lhs'] && json['lhs']['lhs']['lhs'].field === TQLPropertyKey.date) {
			// start AND end dates
			if (embedDatesInTQL) {
				query.endDate = DateTime.fromISO(json['lhs']['lhs']['rhs']);
				query.startDate = DateTime.fromISO(json['lhs']['rhs']['rhs']);
			}
			return json['rhs'];
		} else {
			return json;
		}
	}

	private loadTQLJSONExpression(jsonAtCurrentRoot: Object, query: TQLQuery, jsonNestedLevel: number = 0, not?: boolean): void {
		const tqlJSONOperatorKey: string = (jsonAtCurrentRoot['meta'] ? jsonAtCurrentRoot['meta'].op : '') || jsonAtCurrentRoot['op'];
		if (jsonAtCurrentRoot['op'] === TQLOperatorKey.not) {
			if (jsonNestedLevel < 1) {
				query.addTQLControl(TQLElementType.operator, TQLOperatorKey.not);
				const newQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, null, null, null, false, false, true);
				if (newQuery instanceof TQLQuery) {
					newQuery.tqlFields = query.tqlFields;
					this.loadTQLJSONExpression(jsonAtCurrentRoot['expr'], newQuery, jsonNestedLevel);
				}
			} else {
				const newQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, null, null, null, false, false, true);
				if (newQuery instanceof TQLQuery) {
					newQuery.tqlFields = query.tqlFields;
					this.loadTQLJSONExpression(jsonAtCurrentRoot['expr'], newQuery, jsonNestedLevel, true);
				}
			}
			return;
		}

		if (jsonAtCurrentRoot['meta'] && jsonAtCurrentRoot['meta']['nested']) {
			if (query.nestedLevel > 0) {
				delete jsonAtCurrentRoot['meta']['nested'];
				query.containsNestedQueries = true;
				this.loadTQLJSONExpression(jsonAtCurrentRoot, query, jsonNestedLevel);
			} else {
				const nestedQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, null, null, null, false, false, true);
				if (nestedQuery instanceof TQLQuery) {
					delete jsonAtCurrentRoot['meta']['nested'];
					nestedQuery.containsNestedQueries = true;
					nestedQuery.tqlFields = query.tqlFields;
					this.loadTQLJSONExpression(jsonAtCurrentRoot, nestedQuery, jsonNestedLevel);
				}
			}
			return;
		}

		if (jsonAtCurrentRoot['lhs'] && !jsonAtCurrentRoot['lhs'].field) {
			if (jsonAtCurrentRoot['lhs'].op === TQLOperatorKey.not) {
				this.loadTQLJSONExpression(jsonAtCurrentRoot['lhs'], query, jsonNestedLevel);
			} else {
				const nestedLeftQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, null, null, null, false, false, true);
				if (nestedLeftQuery instanceof TQLQuery) {
					nestedLeftQuery.tqlFields = query.tqlFields;
					this.loadTQLJSONExpression(jsonAtCurrentRoot['lhs'], nestedLeftQuery, jsonNestedLevel + 1);
				}
			}
			query.addTQLControl(TQLElementType.operator, this.getOperatorKey(tqlJSONOperatorKey, null, this.isNegativeRoot(jsonAtCurrentRoot['rhs'])));
			if (jsonAtCurrentRoot['rhs']['lhs'] && jsonAtCurrentRoot['rhs']['lhs'].field) {
				const nestedRightQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, null, null, null, false, false, true);
				if (nestedRightQuery instanceof TQLQuery) {
					this.loadTQLJSONExpression(jsonAtCurrentRoot['rhs'], nestedRightQuery, jsonNestedLevel + 1);
				}
				query.blankElementIndex = null;
			} else {
				this.loadTQLJSONExpression(jsonAtCurrentRoot['rhs'], query, jsonNestedLevel + 1, this.isNegativeRoot(jsonAtCurrentRoot['rhs']));
			}
		} else if (query.isRoot) {
			const newQuery: TQLElement = query.addNewQueryElement(TQLElementType.query, false, null, null, false, false, true);
			if (newQuery instanceof TQLQuery && not) {
				newQuery.tqlFields = query.tqlFields;
				newQuery.addTQLControl(TQLElementType.operator, TQLOperatorKey.not);
			}
			if (newQuery instanceof TQLQuery) {
				this.loadTQLJSONExpression(jsonAtCurrentRoot, newQuery, jsonNestedLevel);
			}
		} else {
			const operatorKey: string = this.getOperatorKey(tqlJSONOperatorKey, jsonAtCurrentRoot['rhs']);
			const filterTypeKey: string = jsonAtCurrentRoot['lhs'].field;
			query.addTQLControl(TQLElementType.property, filterTypeKey);
			const operatorElement: TQLElement = query.addTQLControl(TQLElementType.operator, operatorKey);
			query.onOptionSelected(
				operatorElement.abstractControl.value.option,
				operatorElement.abstractControl.value.group,
				operatorElement,
				1,
				TQLInputSource.loading
			);
			query.tqlElements[2].abstractControl.setValue(
				this.getPrefillValue(jsonAtCurrentRoot['rhs'], jsonAtCurrentRoot['op'], jsonAtCurrentRoot['lhs'].field, query.tqlFields, jsonAtCurrentRoot['meta'])
			);
		}
	}

	private isNegativeRoot(jsonRoot: Object): boolean {
		return jsonRoot['op'] === TQLOperatorKey.not || (jsonRoot['lhs'] && jsonRoot['lhs'].op === TQLOperatorKey.not);
	}

	private getOperatorKey(op: string, value?: any, not?: boolean): string {
		if (op.includes('like')) {
			return this.getStringMatchOperator(op, value);
		} else if (not) {
			switch (op) {
				case 'or':
					return TQLOperatorKey.orNot;
				case 'and':
					return TQLOperatorKey.andNot;
			}
		} else {
			return op;
		}
	}

	private getStringMatchOperator(operatorAPIKey: string, value: string): TQLOperatorKey {
		const hasStartModulo: boolean = value[value.length - 1] === '%';
		const hasEndModulo: boolean = value[value.length - 1] === '%';
		switch (operatorAPIKey) {
			case 'like':
				if (hasEndModulo && hasStartModulo) {
					return TQLOperatorKey.contains;
				} else if (hasEndModulo) {
					return TQLOperatorKey.endsWith;
				} else if (hasStartModulo) {
					return TQLOperatorKey.startsWith;
				}
				break;
			case 'not like':
				if (hasEndModulo && hasStartModulo) {
					return TQLOperatorKey.doesNotContain;
				} else if (hasEndModulo) {
					return TQLOperatorKey.doesNotEndWith;
				} else if (hasStartModulo) {
					return TQLOperatorKey.doesNotStartWith;
				}
				break;
		}
	}

	private getPrefillValue(value: any, operatorAPIKey: string, propertyKey: TQLPropertyKey, tqlFields: TQLFieldDict, meta: Object = {}): unknown {
		const tqlField: TQLField = tqlFields[propertyKey];
		const options: GenericOption[] = tqlField.options;

		switch (tqlField.type) {
			case TQLFieldType.boolean:
				return {
					group: tqlField,
					option: booleanOptions.find((option: BooleanOption) => option.id === value),
				};
			case TQLFieldType.id:
				switch (operatorAPIKey) {
					case TQLOperatorKey.in:
					case TQLOperatorKey.notIn:
						return options.filter((option: GenericOption) => value.includes(option.id)).map((option: GenericOption) => ({ option: option, group: tqlField }));
					case TQLOperatorKey.equal:
					case TQLOperatorKey.notEqual:
						return {
							group: tqlField,
							option: options.find((option: GenericOption) => option.id === value),
						};
				}
				break;
			case TQLFieldType.number:
				return value;
			case TQLFieldType.text:
				if (propertyKey === TQLPropertyKey.fuzzy) {
					return value.slice(1, -1);
				} else {
					switch (meta['op']) {
						case TQLOperatorKey.startsWith:
						case TQLOperatorKey.doesNotStartWith:
							return value.slice(0, -1);
						case TQLOperatorKey.endsWith:
						case TQLOperatorKey.doesNotEndWith:
							return value.slice(1);
						case TQLOperatorKey.contains:
						case TQLOperatorKey.doesNotContain:
							return value.slice(1, -1);
						default:
							return value;
					}
				}
		}
	}

	addParameterToTQLExpression(tqlExpression: object, fieldId: string, valueId: string | string[], { not = false }: { not?: boolean } = {}): object {
		const expression: object = {
			lhs: { field: fieldId },
			op: not ? TQLOperatorKey.notIn : TQLOperatorKey.in,
			rhs: Array.isArray(valueId) ? valueId : [valueId],
		};

		return tqlExpression ? { lhs: tqlExpression, op: TQLOperatorKey.and, rhs: expression } : expression;
	}

	addDatesToTQLExpression(tqlExpression: Object, startDate: DateTime, endDate: DateTime): Object {
		if ((startDate && !startDate.isValid) || (endDate && !endDate.isValid)) {
			throw new Error('Invalid Date Provided');
		}
		if (startDate && endDate) {
			if (tqlExpression) {
				return {
					lhs: {
						lhs: {
							lhs: {
								field: 'date',
							},
							op: TQLOperatorKey.lessThanEqual,
							rhs: endDate.toFormat('yyyy-MM-dd'),
						},
						op: 'and',
						rhs: {
							lhs: {
								field: 'date',
							},
							op: TQLOperatorKey.greaterThanEqual,
							rhs: startDate.toFormat('yyyy-MM-dd'),
						},
					},
					op: 'and',
					rhs: tqlExpression,
				};
			} else {
				return {
					lhs: {
						lhs: {
							field: 'date',
						},
						op: TQLOperatorKey.lessThanEqual,
						rhs: endDate.toFormat('yyyy-MM-dd'),
					},
					op: 'and',
					rhs: {
						lhs: {
							field: 'date',
						},
						op: TQLOperatorKey.greaterThanEqual,
						rhs: startDate.toFormat('yyyy-MM-dd'),
					},
				};
			}
		} else if (startDate || endDate) {
			let operator: TQLOperatorKey;
			let date: DateTime;
			if (startDate) {
				operator = TQLOperatorKey.greaterThanEqual;
				date = startDate;
			} else {
				operator = TQLOperatorKey.lessThanEqual;
				date = endDate;
			}
			if (tqlExpression) {
				return {
					lhs: {
						lhs: {
							field: 'date',
						},
						op: operator,
						rhs: date.toFormat('yyyy-MM-dd'),
					},
					op: 'and',
					rhs: tqlExpression,
				};
			} else {
				return {
					lhs: {
						field: 'date',
					},
					op: operator,
					rhs: date.toFormat('yyyy-MM-dd'),
				};
			}
		} else {
			return tqlExpression;
		}
	}

	buildTQLJSONExpression(tqlQuery: TQLQuery, startDate: DateTime, endDate: DateTime, embedDatesInTQL?: boolean): Object {
		if (startDate && endDate && embedDatesInTQL) {
			if (tqlQuery.tqlElements.length) {
				return {
					lhs: {
						lhs: {
							lhs: {
								field: 'date',
							},
							op: TQLOperatorKey.lessThanEqual,
							rhs: endDate.toFormat('yyyy-MM-dd'),
						},
						op: 'and',
						rhs: {
							lhs: {
								field: 'date',
							},
							op: TQLOperatorKey.greaterThanEqual,
							rhs: startDate.toFormat('yyyy-MM-dd'),
						},
					},
					op: 'and',
					rhs: this.buildTQLJSONFromTQLQuery(tqlQuery),
				};
			} else {
				return {
					lhs: {
						lhs: {
							field: 'date',
						},
						op: TQLOperatorKey.lessThanEqual,
						rhs: endDate.toFormat('yyyy-MM-dd'),
					},
					op: 'and',
					rhs: {
						lhs: {
							field: 'date',
						},
						op: TQLOperatorKey.greaterThanEqual,
						rhs: startDate.toFormat('yyyy-MM-dd'),
					},
				};
			}
		} else if ((startDate || endDate) && embedDatesInTQL) {
			let operator: TQLOperatorKey;
			let date: DateTime;
			if (startDate) {
				operator = TQLOperatorKey.greaterThanEqual;
				date = startDate;
			} else {
				operator = TQLOperatorKey.lessThanEqual;
				date = endDate;
			}
			if (tqlQuery.tqlElements.length) {
				return {
					lhs: {
						lhs: {
							field: 'date',
						},
						op: operator,
						rhs: date.toFormat('yyyy-MM-dd'),
					},
					op: 'and',
					rhs: this.buildTQLJSONFromTQLQuery(tqlQuery),
				};
			} else {
				return {
					lhs: {
						field: 'date',
					},
					op: operator,
					rhs: date.toFormat('yyyy-MM-dd'),
				};
			}
		} else {
			return this.buildTQLJSONFromTQLQuery(tqlQuery);
		}
	}

	private buildTQLJSONFromTQLQuery(tqlQuery: TQLQuery, startIndex: number = 0, not?: boolean): Object {
		const ASTJSON: Object = {};
		if (tqlQuery.containsNestedQueries && !tqlQuery.isRoot) {
			if (!ASTJSON['meta']) {
				ASTJSON['meta'] = { nested: true };
			} else {
				ASTJSON['meta'].nested = true;
			}
		}
		for (let i = startIndex; i < tqlQuery.tqlElements.length; i++) {
			const previousElement: TQLElement = tqlQuery.tqlElements[i - 1];
			const currentElement: TQLElement = tqlQuery.tqlElements[i];
			const nextElement: TQLElement = tqlQuery.tqlElements[i + 1];
			if (currentElement instanceof TQLQuery) {
				if (nextElement) {
					if (not) {
						ASTJSON['lhs'] = {
							op: TQLOperatorKey.not,
							expr: this.buildTQLJSONFromTQLQuery(currentElement),
						};
					} else {
						ASTJSON['lhs'] = this.buildTQLJSONFromTQLQuery(currentElement);
					}
					if (nextElement.abstractControl.value.option.id === TQLOperatorKey.orNot) {
						ASTJSON['op'] = TQLOperatorKey.or;
						ASTJSON['rhs'] = this.buildTQLJSONFromTQLQuery(tqlQuery, i + 2, true);
					} else if (nextElement.abstractControl.value.option.id === TQLOperatorKey.andNot) {
						ASTJSON['op'] = TQLOperatorKey.and;
						ASTJSON['rhs'] = this.buildTQLJSONFromTQLQuery(tqlQuery, i + 2, true);
					} else {
						ASTJSON['op'] = nextElement.abstractControl.value.option.id;
						ASTJSON['rhs'] = this.buildTQLJSONFromTQLQuery(tqlQuery, i + 2);
					}
					break;
				} else {
					if (not) {
						return {
							op: TQLOperatorKey.not,
							expr: this.buildTQLJSONFromTQLQuery(currentElement),
						};
					} else {
						return this.buildTQLJSONFromTQLQuery(currentElement);
					}
				}
			} else {
				switch (currentElement.type) {
					case TQLElementType.property:
						ASTJSON['lhs'] = {
							field: currentElement.abstractControl.value.option.id,
						};
						break;
					case TQLElementType.operator:
						if (nextElement instanceof TQLValue && nextElement.valueType === TQLValueControlType.string) {
							if (previousElement.abstractControl.value.option.id === TQLPropertyKey.fuzzy) {
								const operatorOpt: TQLOperatorOption = currentElement.abstractControl.value.option;
								if (operatorOpt.isLike) {
									ASTJSON['op'] = 'like';
								} else {
									ASTJSON['op'] = 'not like';
								}
							} else if (currentElement.abstractControl.value.option.symbolPlacement) {
								const operatorOpt: TQLOperatorOption = currentElement.abstractControl.value.option;
								if (operatorOpt.isLike) {
									ASTJSON['op'] = 'like';
								} else {
									ASTJSON['op'] = 'not like';
								}
								if (!ASTJSON['meta']) {
									ASTJSON['meta'] = { op: operatorOpt.id };
								} else {
									ASTJSON['meta'].op = operatorOpt.id;
								}
							} else {
								ASTJSON['op'] = currentElement.abstractControl.value.option.id;
							}
						} else {
							const operatorOpt: GenericOption = currentElement.abstractControl.value.option;
							if (operatorOpt.id === TQLOperatorKey.not && nextElement instanceof TQLQuery) {
								return this.buildTQLJSONFromTQLQuery(tqlQuery, i + 1, true);
							} else {
								ASTJSON['op'] = operatorOpt.id;
							}
						}
						break;
					case TQLElementType.value:
						if (Array.isArray(currentElement.abstractControl.value)) {
							ASTJSON['rhs'] = currentElement.abstractControl.value.map((value: GenericOptionValue) => value.option.id);
						} else if (currentElement instanceof TQLValue && currentElement.valueType === TQLValueControlType.string) {
							const operatorOpt: TQLOperatorOption = previousElement.abstractControl.value.option;
							if (ASTJSON['lhs'].field === TQLPropertyKey.fuzzy) {
								ASTJSON['rhs'] = this.stringCompareValueGenerator(SymbolPlacement.both, currentElement.abstractControl.value);
								if (!ASTJSON['meta']) {
									ASTJSON['meta'] = { op: operatorOpt.id };
								} else {
									ASTJSON['meta'].op = operatorOpt.id;
								}
							} else {
								ASTJSON['rhs'] = this.stringCompareValueGenerator(
									previousElement.abstractControl.value.option.symbolPlacement,
									currentElement.abstractControl.value
								);
							}
						} else if (currentElement instanceof TQLValue && currentElement.valueType === TQLValueControlType.number) {
							ASTJSON['rhs'] = +currentElement.abstractControl.value;
						} else if (currentElement instanceof TQLValue && currentElement.valueType === TQLValueControlType.date) {
							ASTJSON['rhs'] = currentElement.abstractControl.value.toFormat('yyyy-MM-dd');
						} else {
							switch (currentElement.abstractControl.value.group.type) {
								case TQLOptionType.boolean:
									ASTJSON['rhs'] = currentElement.abstractControl.value.option.id === true;
									break;
								case TQLOptionType.string:
									ASTJSON['rhs'] = currentElement.abstractControl.value.option.displayValue;
									break;
								default:
									ASTJSON['rhs'] = currentElement.abstractControl.value.option.id;
							}
						}
						break;
				}
			}
		}
		return ASTJSON;
	}

	private stringCompareValueGenerator(symbolPlacement: SymbolPlacement, patternMatchingString: string): string {
		switch (symbolPlacement) {
			case SymbolPlacement.before:
				return '%' + patternMatchingString;
			case SymbolPlacement.after:
				return patternMatchingString + '%';
			case SymbolPlacement.both:
				return '%' + patternMatchingString + '%';
			default:
				return patternMatchingString;
		}
	}

	extractChildTagsFromTQLExpression(jsonAtCurrentRoot: Object, not?: boolean, nested?: boolean): string[] {
		let tags: string[] = [];
		if (jsonAtCurrentRoot) {
			if (jsonAtCurrentRoot['op'] === TQLOperatorKey.not) {
				tags = [...tags, ...this.extractChildTagsFromTQLExpression(jsonAtCurrentRoot['expr'], !not)];
				return tags;
			}

			if (jsonAtCurrentRoot['meta'] && jsonAtCurrentRoot['meta']['nested'] && !nested) {
				tags = [...tags, ...this.extractChildTagsFromTQLExpression(jsonAtCurrentRoot, null, true)];
				return tags;
			}

			if (jsonAtCurrentRoot['lhs'] && !jsonAtCurrentRoot['lhs'].field) {
				tags = [...tags, ...this.extractChildTagsFromTQLExpression(jsonAtCurrentRoot['lhs'], not)];
				tags = [...tags, ...this.extractChildTagsFromTQLExpression(jsonAtCurrentRoot['rhs'], not)];
			} else {
				if (jsonAtCurrentRoot['op'] === TQLOperatorKey.notIn) {
					not = !not;
				}
				if (jsonAtCurrentRoot['lhs'].field === 'tag' && !not) {
					return jsonAtCurrentRoot['rhs'];
				} else {
					return [];
				}
			}
		}
		return tags;
	}
}
