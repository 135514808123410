export class Institution {
	indexTime: string;
	dataProviderId: string;
	institutionId: string;
	trovataInstitutionId: string;
	institutionName: string;
	institutionNickname: string;
	connectionType: string;
	institutionType: string;
	url: string;
	metadata?: Metadata;
}

export interface Metadata {
	logoUrl?: string;
	supportsAccountIdentification?: boolean;
	supportsAccountStatement?: boolean;
	supportsAccountVerification?: boolean;
	supportsTransactionHistory?: boolean;
	fullDescription?: [string];
	description?: string;
	position?: number;
	tags?: string[];
	oauth: boolean;
	fields?: InstitutionMetadataField[];
	reconnectFields?: InstitutionMetadataField[];
	versions?: string[];
	routingNumbers?: string[];
}

export interface InstitutionMetadataField {
	id: string;
	label: string;
	type: string;
	required: boolean;
}

export interface InstitutionDetails {
	name: string;
	smallLogoUrl: string;
	url: string;
}

export class InstitutionApiGetResponse {
	institutions: Institution[];
	total: number;
}

export interface InstitutionCreateResponse {
	message: string;
	resourceId: string;
}

export class InstitutionSelectModel {
	customerInstitutionGroups: InstitutionGroup[];
	trovataInstitutions: Institution[];
}

export class InstitutionGroup {
	name: string;
	institutions: Institution[];
}

export enum ConnectionType {
	OFX = 'OFX',
	SFTP = 'SFTP',
	BAI = 'BAI',
	PLAID = 'PLAID',
	DIRECT = 'DIRECT',
}
