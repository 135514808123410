import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { State, Select, Selector, Store, Action, StateContext } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AllPreferences } from 'src/app/shared/models/preferences.model';
import { PreferencesState } from 'src/app/shared/store/state/preferences/preferences.state';
import { Observable, Subscription, combineLatest, throwError, firstValueFrom, takeUntil, Subject } from 'rxjs';
import { FeatureId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import {
	CashPosition,
	CashPositionGrouping,
	CashPositionListResponse,
	CashPositionData,
	CashPositionVersionResponseBody,
	CashPositionVersionsResponse,
	CashPositionVersionOverviewResponse,
	CashPositionVersionOverview,
	CashPositionPagePreferences,
} from '../../models/cash-position.model';
import { CashPositionService } from '../../services/cash-position.service';
import {
	InitCashPositionState,
	ClearCashPositionState,
	GetCashPositions,
	AddDataToCashPosition,
	LazyLoadCashPositionData,
	UpdateCashPosition,
	CreateCashPosition,
	SaveNewCashPositionVersion,
	LazyLoadVersionList,
	AddVersionsToCashPosition,
	AddLatestVersionToCashPosition,
	DeleteCashPosition,
	AddVersionOverviewsToCashPosition,
	LazyLoadVersionOverviews as LazyLoadCashPositionVersionOverviews,
} from '../actions/cash-position.actions';
import { PreferencesFacadeService } from 'src/app/shared/services/facade/preferences.facade.service';
import { SnackType } from 'src/app/shared/models/snacks.model';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, CashPositionUpdatedEvent, ItemType, UpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { DateTime } from 'luxon';

export class CashPositionStateModel extends EntitledStateModel {
	cashPositions: CashPosition[];
	preFetchInFlight: boolean;
}

@State<CashPositionStateModel>({
	name: 'cashPosition',
	defaults: {
		cashPositions: null,
		preFetchInFlight: false,
		isCached: false,
	},
})
@Injectable()
export class CashPositionState implements OnDestroy {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	private destroyed$: Subject<boolean>;
	@Select(CustomerFeatureState.hasPermission(FeatureId.cashPosition))
	shouldSeeCashPosition$: Observable<boolean>;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;
	@Select(PreferencesState.preferences)
	preferences$: Observable<AllPreferences>;

	@Selector() static cashPositions(cashPositionState: CashPositionStateModel): CashPosition[] {
		return cashPositionState.cashPositions;
	}
	@Selector() static cashPosition(cashPositionState: CashPositionStateModel, cashPositioningId: string): CashPosition {
		return cashPositionState.cashPositions.find((cashPosition: CashPosition) => cashPosition.cashPositioningId === cashPositioningId);
	}

	@Selector() static preFetchInFlight(cashPositionState: CashPositionStateModel): boolean {
		return cashPositionState.preFetchInFlight;
	}

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private cashPositionService: CashPositionService,
		private updatedEventsService: UpdatedEventsService,
		private preferencesFacadeService: PreferencesFacadeService
	) {
		this.destroyed$ = new Subject();
		this.setupUpdatedEvents();
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	setupUpdatedEvents(): void {
		this.updatedEventsService.updatedItem$.pipe(takeUntil(this.destroyed$)).subscribe(async (event: UpdatedEvent) => {
			if (
				event.actionType === ActionType.update &&
				event.itemType === ItemType.cashPosition &&
				event.itemId === 'preferences' &&
				event.data?.cashPositioningId
			) {
				this.store.dispatch(new LazyLoadCashPositionData(event.data?.cashPositioningId));
			}
		});
	}

	@Action(InitCashPositionState)
	async InitCashPositionState(context: StateContext<CashPositionStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const CashPositionStateIsCached: boolean = this.cashPositionStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeCashPosition$]).subscribe({
				next: ([appReady, shouldSeeCashPosition]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady) {
						if (shouldSeeCashPosition) {
							if (CashPositionStateIsCached) {
								const state: CashPositionStateModel = deserializedState.cashPosition;
								// check for cash positions that never got their data
								this.lazyLoadVisibleCashPositionData(context);
								context.patchState(state);
							} else {
								context.dispatch(new GetCashPositions());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ cashPositions: [], isCached: false });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetCashPositions)
	getCashPositions(context: StateContext<CashPositionStateModel>): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const resp: HttpResponse<CashPositionListResponse> = await firstValueFrom(this.cashPositionService.getAllCashPositions());
				const cashPositions: CashPosition[] = resp.body.reports;
				context.patchState({ cashPositions: cashPositions, isCached: true });
				this.lazyLoadVisibleCashPositionData(context);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateCashPosition)
	updateCashPositionSettings(context: StateContext<CashPositionStateModel>, action: UpdateCashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.cashPositionService.saveCashPositionSettings(action.cashPosition));
				const state: CashPositionStateModel = context.getState();
				const filteredPositions: CashPosition[] = state.cashPositions.filter(
					(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
				);
				delete action.cashPosition.data;
				delete action.cashPosition.versions;
				delete action.cashPosition.latestVersion;
				action.cashPosition.lastModifiedDate = DateTime.now().toISO();
				filteredPositions.push(action.cashPosition);
				state.cashPositions = this.sortCashPositionsByName(filteredPositions);
				context.patchState(state);
				this.updatedEventsService.updateItem(new CashPositionUpdatedEvent(ActionType.update, action.cashPosition.cashPositioningId, action.cashPosition));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(SaveNewCashPositionVersion)
	saveNewCashPositionVersion(context: StateContext<CashPositionStateModel>, action: SaveNewCashPositionVersion): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const response: HttpResponse<CashPositionVersionResponseBody> = await firstValueFrom(
					this.cashPositionService.saveNewVersion(action.cashPosition, action.cashPositionVersionPostBody)
				);
				const state: CashPositionStateModel = context.getState();
				const filteredPositions: CashPosition[] = state.cashPositions.filter(
					(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
				);
				delete action.cashPosition.data;
				delete action.cashPosition.versions;
				delete action.cashPosition.latestVersion;
				action.cashPosition.lastModifiedDate = response.body.createdAt;
				filteredPositions.push(action.cashPosition);
				state.cashPositions = this.sortCashPositionsByName(filteredPositions);
				context.patchState(state);
				this.updatedEventsService.updateItem(new CashPositionUpdatedEvent(ActionType.update, action.cashPosition.cashPositioningId, action.cashPosition));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(ClearCashPositionState)
	clearCashPositionState(context: StateContext<CashPositionStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: CashPositionStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(LazyLoadCashPositionData)
	async lazyLoadCashPositionData(context: StateContext<CashPositionStateModel>, action: LazyLoadCashPositionData): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: CashPositionStateModel = context.getState();
				const cashPosition: CashPosition = state.cashPositions.find(
					(findPosition: CashPosition) => findPosition.cashPositioningId === action.cashPositioningId
				);
				cashPosition.data = undefined;
				await this.lazyLoadData(context, cashPosition);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(LazyLoadVersionList)
	async lazyLoadCashPositionVersionsList(context: StateContext<CashPositionStateModel>, action: LazyLoadVersionList): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: CashPositionStateModel = context.getState();
				const cashPosition: CashPosition = state.cashPositions.find(
					(findPosition: CashPosition) => findPosition.cashPositioningId === action.cashPositioningId
				);
				await this.lazyLoadVersions(context, cashPosition);
				await this.lazyLoadLatestVersionData(context, cashPosition);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(LazyLoadCashPositionVersionOverviews)
	async lazyLoadCashPositionVersionOverviews(context: StateContext<CashPositionStateModel>, action: LazyLoadCashPositionVersionOverviews): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: CashPositionStateModel = context.getState();
				const cashPosition: CashPosition = state.cashPositions.find(
					(findPosition: CashPosition) => findPosition.cashPositioningId === action.cashPositioningId
				);
				await this.lazyLoadVersionOverviews(context, cashPosition);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(AddDataToCashPosition)
	addDataToCashPosition(context: StateContext<CashPositionStateModel>, action: AddDataToCashPosition): void {
		const state: CashPositionStateModel = context.getState();
		const cashPosition: CashPosition = action.cashPosition;
		cashPosition.data = action.cashPositionData;
		const filteredPositions: CashPosition[] = state.cashPositions.filter(
			(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
		);
		filteredPositions.push(cashPosition);
		state.cashPositions = this.sortCashPositionsByName(filteredPositions);
		context.patchState({ cashPositions: state.cashPositions });
	}

	@Action(AddVersionsToCashPosition)
	addVersionsToCashPosition(context: StateContext<CashPositionStateModel>, action: AddVersionsToCashPosition): void {
		const state: CashPositionStateModel = context.getState();
		const cashPosition: CashPosition = action.cashPosition;
		cashPosition.versions = action.cashPositionVersions;
		const filteredPositions: CashPosition[] = state.cashPositions.filter(
			(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
		);
		filteredPositions.push(cashPosition);
		state.cashPositions = this.sortCashPositionsByName(filteredPositions);
		context.patchState({ cashPositions: state.cashPositions });
	}

	@Action(AddLatestVersionToCashPosition)
	addLatestVersionToCashPosition(context: StateContext<CashPositionStateModel>, action: AddLatestVersionToCashPosition): void {
		const state: CashPositionStateModel = context.getState();
		const cashPosition: CashPosition = action.cashPosition;
		cashPosition.latestVersion = action.versionData;
		const filteredPositions: CashPosition[] = state.cashPositions.filter(
			(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
		);
		filteredPositions.push(cashPosition);
		state.cashPositions = this.sortCashPositionsByName(filteredPositions);
		context.patchState({ cashPositions: state.cashPositions });
	}

	@Action(AddVersionOverviewsToCashPosition)
	addVersionOverviewsToCashPosition(context: StateContext<CashPositionStateModel>, action: AddVersionOverviewsToCashPosition) {
		const state: CashPositionStateModel = context.getState();
		const cashPosition: CashPosition = action.cashPosition;
		cashPosition.versionOverviews = action.versionOverviews;
		const filteredPositions: CashPosition[] = state.cashPositions.filter(
			(filterPosition: CashPosition) => filterPosition.cashPositioningId !== action.cashPosition.cashPositioningId
		);
		filteredPositions.push(cashPosition);
		state.cashPositions = this.sortCashPositionsByName(filteredPositions);
		context.patchState({ cashPositions: state.cashPositions });
	}

	@Action(CreateCashPosition)
	createCashPosition(context: StateContext<CashPositionStateModel>, action: CreateCashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const cashPositionPayload: CashPosition = action.cashPosition;
				const createResponse: HttpResponse<CashPosition> = await firstValueFrom(this.cashPositionService.createCashPosition(cashPositionPayload));
				const newPositionId: string = createResponse.body.cashPositioningId;
				this.cashPositionService.getAllCashPositions().subscribe(resp => {
					const cashPositions: CashPosition[] = resp.body.reports;
					context.patchState({ cashPositions: cashPositions });
					const newPosition: CashPosition = cashPositions.find((position: CashPosition) => position.cashPositioningId === newPositionId);
					this.lazyLoadData(context, newPosition);
					this.updatedEventsService.updateItem(new CashPositionUpdatedEvent(ActionType.create, newPosition.cashPositioningId));
					resolve();
				});
			} catch (err) {
				reject(err);
			}
		});
	}

	@Action(DeleteCashPosition)
	deleteCashPosition(context: StateContext<CashPositionStateModel>, action: DeleteCashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const cashPositionToDelete: string = action.cashPositioningId;
				await firstValueFrom(this.cashPositionService.deleteCashPosition(cashPositionToDelete));
				const state: CashPositionStateModel = context.getState();
				const cashPositions: CashPosition[] = state.cashPositions;
				context.patchState({
					cashPositions: cashPositions.filter((cashPosition: CashPosition) => cashPosition.cashPositioningId !== cashPositionToDelete),
				});
				this.updatedEventsService.updateItem(new CashPositionUpdatedEvent(ActionType.delete, cashPositionToDelete));
				resolve();
			} catch (err) {
				reject(new Error('Could not delete cash position'));
			}
		});
	}

	private sortCashPositionsByName(cashPositions: CashPosition[]): CashPosition[] {
		cashPositions = cashPositions.sort((reportA: CashPosition, reportB: CashPosition) => {
			if (reportA.name?.toLowerCase() < reportB.name?.toLowerCase()) {
				return -1;
			} else if (reportA.name?.toLowerCase() > reportB.name?.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return cashPositions;
	}

	private async lazyLoadVisibleCashPositionData(context: StateContext<CashPositionStateModel>): Promise<void> {
		try {
			context.patchState({ preFetchInFlight: true });
			const visiblecashPositionIds: string[] = await this.preferencesFacadeService.getVisibleSnackIds(SnackType.cashPosition);
			const state: CashPositionStateModel = context.getState();
			const reportsToLoad: CashPosition[] = [];
			state.cashPositions.forEach((eachReport: CashPosition) => {
				if (!eachReport.data && visiblecashPositionIds.includes(eachReport.cashPositioningId)) {
					reportsToLoad.push(eachReport);
				}
			});
			// by catching errors in promise all and returning this allows it to wait for all requests to either error out or complete succesfully
			await Promise.all(reportsToLoad.map((report: CashPosition) => this.lazyLoadData(context, report).catch(error => new Error(error))));
			context.patchState({ preFetchInFlight: false });
		} catch (error) {
			context.patchState({ preFetchInFlight: false });
		}
	}

	private lazyLoadData(context: StateContext<CashPositionStateModel>, report: CashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const preferences: CashPositionPagePreferences = await this.preferencesFacadeService.getPreferenceByKey('cashPositionPagePreferences');
			const grouping: CashPositionGrouping =
				preferences?.cashPositionIndividualPreferences?.[report.cashPositioningId]?.grouping ?? CashPositionGrouping.accountId;
			this.cashPositionService.getCashPositionData(report.cashPositioningId, grouping).subscribe({
				next: async (response: HttpResponse<CashPositionData>) => {
					const reportData: CashPositionData = response.body;
					if (report.dataErrorMessage && reportData) {
						delete report.dataErrorMessage;
					}
					context.dispatch(new AddDataToCashPosition(report, reportData));
					resolve();
				},
				error: (httpError: HttpErrorResponse) => {
					report.dataErrorMessage = httpError.message;
					reject();
				},
			});
		});
	}

	private lazyLoadVersions(context: StateContext<CashPositionStateModel>, report: CashPosition): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.cashPositionService.getCashPositionVersionList(report).subscribe({
				next: async (response: HttpResponse<CashPositionVersionsResponse>) => {
					const listResponse: CashPositionVersionsResponse = response.body;
					if (report.versionsErrorMessage && listResponse) {
						delete report.versionsErrorMessage;
					}
					context.dispatch(new AddVersionsToCashPosition(report, listResponse.versions || []));
					resolve();
				},
				error: (httpError: HttpErrorResponse) => {
					report.versionsErrorMessage = httpError.message;
					reject(httpError);
				},
			});
		});
	}

	private lazyLoadVersionOverviews(context: StateContext<CashPositionStateModel>, cashPosition: CashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const response: HttpResponse<CashPositionVersionOverviewResponse> = await firstValueFrom(
					this.cashPositionService.getCashPositionHistoricalOverview(cashPosition)
				);
				const versionOverviews: CashPositionVersionOverview[] = response.body.versions;
				if (cashPosition.versionOverviewsErrorMessage && versionOverviews) {
					delete cashPosition.versionOverviewsErrorMessage;
				}
				context.dispatch(new AddVersionOverviewsToCashPosition(cashPosition, versionOverviews));
				resolve();
			} catch (httpError) {
				cashPosition.versionOverviewsErrorMessage = httpError.message;
				reject(httpError);
			}
		});
	}

	private lazyLoadLatestVersionData(context: StateContext<CashPositionStateModel>, report: CashPosition): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			if (report.versions && !report.versions.length) {
				resolve();
			} else {
				try {
					const preferences: CashPositionPagePreferences = await this.preferencesFacadeService.getPreferenceByKey('cashPositionPagePreferences');
					const grouping: CashPositionGrouping =
						preferences?.cashPositionIndividualPreferences?.[report.cashPositioningId]?.grouping ?? CashPositionGrouping.accountId;
					const response: HttpResponse<CashPositionData> = report.versions[0].versionId
						? await firstValueFrom(this.cashPositionService.getCashPositionVersionData(report.cashPositioningId, report.versions[0].versionId, grouping))
						: await firstValueFrom(this.cashPositionService.getCashPositionAutosaveData(report.cashPositioningId, grouping, report.versions[0].createdAt));
					const versionData: CashPositionData = response.body;
					if (report.latestVersionErrorMessage && versionData) {
						delete report.latestVersionErrorMessage;
					}
					context.dispatch(new AddLatestVersionToCashPosition(report, versionData));
					resolve();
				} catch (httpError) {
					report.latestVersionErrorMessage = httpError.message;
					reject(httpError);
				}
			}
		});
	}

	private cashPositionStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedCashPositionState: CashPositionStateModel | undefined = deserializedState.cashPosition;
		if (deserializedCashPositionState?.cashPositions && deserializedCashPositionState?.isCached) {
			return true;
		} else {
			return false;
		}
	}
}
