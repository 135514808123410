import { SearchData } from '@trovata/app/shared/models/search-parameter.model';

export enum SelectionPanelType {
	account = 'account',
	entity = 'entity',
}

export enum UndoAction {
	addIds = 'addIds',
	removeIds = 'removeIds',
}

export interface SelectionPanelChange<T> {
	selected: T[];
	allSelected?: boolean;
}

export interface SelectionPaneOptions<T> {
	type: SelectionPanelType;
	selectedIds?: string[];
	selectedItems?: T[];
	excludeFromListIds?: string[];
	searchData?: SearchData;
	emptySelectDisabled?: boolean;
	allSelectedInitially?: boolean;
	shownColumns?: string[];
	filterStyleSelection?: boolean;

	// display options
	customTitle?: string;
	headerChip?: string;
	stackControls?: boolean;

	// control toggles
	hideAllCntrls?: boolean;
	hideGroupByCntrl?: boolean;
	hideColDisplayCntrl?: boolean;
	hideConvertedCurrCntrl?: boolean;

	// accts specific opts
	hideTotalBalances?: boolean;
	hideUpdateDates?: boolean;
	currencyOverrideCode?: string;
}

export class SelectionPanesViewModel<T> {
	pluralLabel: string;
	singularLabel: string;
	options: SelectionPaneOptions<T>;

	items: T[];
	selectedIds: string[];
	stagedSelectedIds: string[];
	stagedUnselectedIds: string[];
	undoIds: string[];
	undoType: UndoAction;
	constructor(options: SelectionPaneOptions<T>, items: T[]) {
		this.options = options;
		this.selectedIds = this.options.selectedIds || this.options.selectedItems?.map((item: T) => this.getId(item)) || [];
		if (this.items && !this.selectedIds?.length && (this.options.filterStyleSelection || this.options.allSelectedInitially)) {
			this.selectedIds = this.items.map((item: T) => this.getId(item));
		}
		this.stagedSelectedIds = [];
		this.stagedUnselectedIds = [];
		this.undoIds = [];
		this.items = items.filter((item: T) => !this.options?.excludeFromListIds?.length || this.options.excludeFromListIds.indexOf(this.getId(item)) < 0);
	}

	get allSelected(): boolean {
		return this.selectedIds.length === this.items.length;
	}

	get unselectedIdCount(): number {
		if (this.items) {
			return this.items.length - this.selectedIds.length;
		}
		return undefined;
	}

	selectAll(isIncluded: boolean): void {
		if (isIncluded) {
			this.stagedSelectedIds = [...this.selectedIds];
		} else {
			this.stagedUnselectedIds = this.items.map((item: T) => this.getId(item)).filter(acctId => !this.selectedIds.includes(acctId));
		}
	}

	deselectAll(isIncluded: boolean): void {
		if (isIncluded) {
			this.stagedSelectedIds = [];
		} else {
			this.stagedUnselectedIds = [];
		}
	}

	selectedChanged(selectedItems: T[], isIncluded: boolean, groupItems?: T[]): void {
		if (isIncluded) {
			if (groupItems) {
				this.stagedSelectedIds = this.stagedSelectedIds.filter((id: string) => !groupItems.find((item: T) => this.getId(item) === id));
				this.stagedSelectedIds = [...this.stagedSelectedIds, ...selectedItems.map((item: T) => this.getId(item))];
			} else {
				this.stagedSelectedIds = selectedItems.map((item: T) => this.getId(item));
			}
		} else {
			if (groupItems) {
				this.stagedUnselectedIds = this.stagedUnselectedIds.filter((id: string) => !groupItems.find((item: T) => this.getId(item) === id));
				this.stagedUnselectedIds = [...this.stagedUnselectedIds, ...selectedItems.map((item: T) => this.getId(item))];
			} else {
				this.stagedUnselectedIds = selectedItems.map((item: T) => this.getId(item));
			}
		}
	}

	includeStaged(): void {
		this.selectedIds = [...this.selectedIds, ...this.stagedUnselectedIds];
		this.undoIds = this.stagedUnselectedIds;
		this.undoType = UndoAction.addIds;
		this.stagedUnselectedIds = [];
	}

	removeStaged(): void {
		this.selectedIds = this.selectedIds.filter(acctId => !this.stagedSelectedIds.includes(acctId));
		this.undoIds = this.stagedSelectedIds;
		this.undoType = UndoAction.removeIds;
		this.stagedSelectedIds = [];
	}

	undo(): void {
		if (this.undoType === UndoAction.addIds) {
			this.selectedIds = this.selectedIds.filter(acctId => !this.undoIds.includes(acctId));
			this.stagedUnselectedIds = [...this.stagedUnselectedIds, ...this.undoIds];
		} else {
			this.selectedIds = [...this.selectedIds, ...this.undoIds];
			this.stagedSelectedIds = [...this.stagedSelectedIds, ...this.undoIds];
		}
		this.undoIds = [];
	}

	getSelectionChange(): SelectionPanelChange<T> {
		if (this.options.filterStyleSelection && (!this.selectedIds || this.allSelected)) {
			return { selected: null };
		} else if (this.selectedIds) {
			return {
				selected: this.items.filter((item: T) => this.selectedIds?.includes(this.getId(item))),
				allSelected: this.selectedIds.length === this.items.length,
			};
		}
	}

	protected getId(item: T): string {
		return item['id'];
	}
}
