import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { GetPlaidItemsResponse, PlaidItem } from 'src/app/shared/models/widgets.model';
import { WidgetsService } from 'src/app/shared/services/widgets.service';
import { catchError, combineLatest, Observable, Subscription, tap, throwError } from 'rxjs';
import { ClearPlaidState, GetPlaidItems, InitPlaidState, ResetPlaidState } from '../actions/plaid.actions';
import { CustomerFeatureState } from './customer-feature.state';
import { FeatureId } from '../../models/feature.model';

export class PlaidStateModel {
	plaidItems: PlaidItem[];
}

@State<PlaidStateModel>({
	name: 'plaid',
	defaults: {
		plaidItems: null,
	},
})
@Injectable()
export class PlaidState {
	@Select(CustomerFeatureState.restrictedFeatureIds) restrictedFeatureIds$: Observable<number[]>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private widgetsService: WidgetsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitPlaidState)
	async initPlaidState(context: StateContext<PlaidStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const plaidStateIsCached: boolean = this.plaidStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.restrictedFeatureIds$]).subscribe({
				next: ([appReady, restrictedFeatureIds]: [boolean, number[]]) => {
					if (appReady && restrictedFeatureIds) {
						if (plaidStateIsCached) {
							const state: PlaidStateModel = deserializedState.plaid;
							context.patchState(state);
						} else {
							if (!restrictedFeatureIds.includes(FeatureId.plaid)) {
								context.dispatch(new GetPlaidItems());
							}
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(ClearPlaidState)
	clearPlaidState(context: StateContext<PlaidStateModel>) {
		this.appReadySub.unsubscribe();
		const state: PlaidStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetPlaidState)
	resetPlaidState(context: StateContext<PlaidStateModel>) {
		context.dispatch(new GetPlaidItems());
	}

	@Action(GetPlaidItems)
	getPlaidItems(context: StateContext<PlaidStateModel>) {
		return this.widgetsService.getPlaidItems().pipe(
			tap((getPlaidItemsResponse: GetPlaidItemsResponse) => {
				const state: PlaidStateModel = context.getState();
				state.plaidItems = this.sortPlaidItemsByName(getPlaidItemsResponse.items);
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	private plaidStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.plaid && deserializedState.plaid.plaidItems) {
			return true;
		} else {
			return false;
		}
	}

	private sortPlaidItemsByName(plaidItems: PlaidItem[]): PlaidItem[] {
		plaidItems = plaidItems.sort((plaidItemA: PlaidItem, plaidItemB: PlaidItem) => plaidItemA.institutionName.localeCompare(plaidItemB.institutionName));
		return plaidItems;
	}
}
