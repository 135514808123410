import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { PreferenceV2 } from '../models/preferences.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PreferencesV2Service {
	refreshPreferencesCache = {};
	preferenceKey: string = 'currency';

	constructor(private httpClient: HttpClient) {}

	/**
	 * @description Returns all the preferences associated with a resource.
	 * @param {boolean?} refreshCache If you want to force a cache refresh on this request.
	 * @return {Observable<HttpResponse<any[]>>}  The standard return object we have been using for requests.
	 */
	public getAllPreferences(ownerFlag?: string, preferenceKey?: string, refreshCache?: boolean): Observable<HttpResponse<PreferenceV2[]>> | Observable<Object> {
		const url = environment.trovataAPI('workspace') + '/data/preferences';

		let parameters = new HttpParams();
		if (ownerFlag) {
			parameters = parameters.append('ownerFlag', ownerFlag);
		}
		if (ownerFlag) {
			parameters = parameters.append('preferenceKey', preferenceKey);
		}

		return this.httpClient
			.get<any[]>(url, {
				params: parameters,
				observe: 'response',
			})
			.pipe(
				catchError(err => {
					throwError(err);
					return of({});
				})
			);
	}

	public postCurrencyPreference(ownerFlag: string, currencyCode: string): Observable<HttpResponse<Object>> {
		const body = {};
		body['preference'] = { currency: currencyCode };
		body['preferenceKey'] = this.preferenceKey;
		body['ownerFlag'] = ownerFlag;

		const url = environment.trovataAPI('workspace') + '/data/preferences';
		return this.httpClient
			.post(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public putCurrencyPreference(ownerFlag: string, currencyCode: string): Observable<HttpResponse<Object>> {
		const body = {};
		body['preference'] = { currency: currencyCode };
		body['preferenceKey'] = this.preferenceKey;
		body['ownerFlag'] = ownerFlag;

		const url = environment.trovataAPI('workspace') + '/data/preferences';
		return this.httpClient
			.put(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}
}
