import { DateTime } from 'luxon';

export function getPeriods(endDate: DateTime, startDateParam: DateTime, cadence: string, trimWeekends: boolean, returnCompletePeriods?: boolean): number {
	const startDate: DateTime = startDateParam;
	endDate = endDate.startOf('day');
	if (cadence === 'daily') {
		if (trimWeekends) {
			let dayCheck: DateTime = startDate;
			let diff: number = 0;
			while (dayCheck <= endDate) {
				if (dayCheck.weekday !== 6 && dayCheck.weekday !== 7) {
					diff += 1;
				}
				if (returnCompletePeriods) {
					if (dayCheck.weekday === 6 || dayCheck.weekday === 7) {
						diff -= 1;
					}
				}
				dayCheck = dayCheck.plus({ days: 1 });
			}
			return diff || 1;
		}
		const dayDiff: number = endDate.diff(startDate.startOf('day')).as('days') + 1;
		return dayDiff || 1;
	} else if (cadence === 'weekly') {
		const weekDiff: number = endDate.diff(startDate).as('weeks');
		return weekDiff || 1;
	} else if (cadence === 'monthly') {
		const monthDiff: number = endDate.diff(startDate.startOf('month')).as('months');
		return monthDiff || 1;
	} else if (cadence === 'quarterly') {
		const quarterDiff: number = endDate.diff(startDate.startOf('quarter')).as('quarters');
		return quarterDiff || 1;
	}
}

export function getStartDate(endDate: Date, cadence: string, periods: number, trimWeekends: boolean): Date {
	const startDate: Date = new Date(endDate);
	if (cadence === 'daily' && trimWeekends) {
		while (startDate.getDay() === 6 || startDate.getDay() === 0) {
			startDate.setDate(startDate.getDate() - 1);
		}
	}
	for (let i: number = 0; i < periods; i++) {
		if (cadence === 'daily' && i < periods - 1) {
			startDate.setDate(startDate.getDate() - 1);
			while ((startDate.getDay() === 6 || startDate.getDay() === 0) && trimWeekends) {
				startDate.setDate(startDate.getDate() - 1);
			}
		} else if (cadence === 'weekly') {
			startDate.setDate(startDate.getDate() - 7);
		} else if (cadence === 'monthly') {
			startDate.setMonth(startDate.getMonth() - 1);
		} else if (cadence === 'quarterly') {
			startDate.setMonth(startDate.getMonth() - 3);
		}
	}
	return startDate;
}

export function getEndDate(startDateLuxon: DateTime, cadence: string, periods: number, trimWeekends: boolean): string {
	const startDate: Date = startDateLuxon.toJSDate();
	if (cadence === 'daily' && trimWeekends) {
		while (startDate.getDay() === 6 || startDate.getDay() === 0) {
			startDate.setDate(startDate.getDate() + 1);
		}
	}
	for (let i: number = 0; i < periods; i++) {
		if (cadence === 'daily' && i < periods - 1) {
			startDate.setDate(startDate.getDate() + 1);
			while ((startDate.getDay() === 6 || startDate.getDay() === 0) && trimWeekends) {
				startDate.setDate(startDate.getDate() + 1);
			}
		} else if (cadence === 'weekly') {
			startDate.setDate(startDate.getDate() + 7);
		} else if (cadence === 'monthly') {
			startDate.setMonth(startDate.getMonth() + 1);
		} else if (cadence === 'quarterly') {
			startDate.setMonth(startDate.getMonth() + 3);
		}
	}
	const monthString: string = (startDate.getUTCMonth() + 1 + '').padStart(2, '0');
	const dayString: string = (startDate.getUTCDate() + '').padStart(2, '0');
	const dateString: string = `${startDate.getUTCFullYear()}-${monthString}-${dayString}`;
	return dateString;
}
