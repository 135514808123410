import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateGlCodeResponse, CreateBulkGlCodesResponse, GLCode, GlCodePayload, DeleteGlCodePayload, BulkGlCodesPayload } from '../models/gl-code.model';
import { GetGlCodesResponse } from '../models/tag.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class GlCodeService {
	constructor(private httpClient: HttpClient) {}

	getAllGLCodes(): Observable<HttpResponse<GetGlCodesResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tags/gl/gl-code';
		return this.httpClient.get<GetGlCodesResponse>(url, {
			observe: 'response',
		});
	}

	createGLCode(glCodePayload: GlCodePayload): Observable<HttpResponse<CreateGlCodeResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tags/gl/gl-code';
		return this.httpClient.post<CreateGlCodeResponse>(url, glCodePayload, {
			observe: 'response',
		});
	}

	createBulkGlCodes(bulkGlCodesPayload: BulkGlCodesPayload): Observable<HttpResponse<CreateBulkGlCodesResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tags/gl/gl-codes';
		return this.httpClient.post<CreateBulkGlCodesResponse>(url, bulkGlCodesPayload, {
			observe: 'response',
		});
	}

	editGLCode(glCodePayload: GlCodePayload, glCodeId: string): Observable<HttpResponse<GLCode>> {
		const url: string = environment.trovataAPI('workspace') + `/data/tags/gl/gl-code/${glCodeId}`;
		return this.httpClient.put<GLCode>(url, glCodePayload, {
			observe: 'response',
		});
	}

	deleteGLCode(glCodeId: string): Observable<HttpResponse<void>> {
		const body: DeleteGlCodePayload = {
			codeId: glCodeId,
			godDelete: false,
		};
		const url: string = environment.trovataAPI('workspace') + '/data/tags/gl/gl-code/deletion';
		return this.httpClient.post<void>(url, body, {
			observe: 'response',
		});
	}
}
