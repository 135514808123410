import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Message } from '@trovata/app/core/models/web-socket.model';
import {
	GetBatchPaymentById,
	GetPaymentById,
	RemoveBatchPaymentFromStoreById,
	RemovePaymentFromStoreById,
} from '@trovata/app/features/payments/store/actions/payments.actions';
import { GetTemplateById, RemoveTemplateFromStoreById } from '@trovata/app/features/payments/store/actions/templates.actions';
import { GetWorkflowById, RemoveWorkflowFromStoreById } from '@trovata/app/features/payments/store/actions/workflows.actions';
import { GetPaymentsAccountById } from '../actions/accounts.actions';

@Injectable({
	providedIn: 'root',
})
export class PaymentsSocketHandler {
	constructor(private store: Store) {}

	public routePayments(msg: Message): void {
		switch (msg.objectType) {
			case 'payment':
				switch (msg.action) {
					case 'create':
					case 'update':
						this.store.dispatch(new GetPaymentById(msg.objectId));
						break;
					case 'delete':
						this.store.dispatch(new RemovePaymentFromStoreById(msg.objectId));
						break;
				}
				break;
			case 'batchpayment':
				switch (msg.action) {
					case 'create':
					case 'update':
						this.store.dispatch(new GetBatchPaymentById(msg.objectId));
						break;
					case 'delete':
						this.store.dispatch(new RemoveBatchPaymentFromStoreById(msg.objectId));
						break;
				}
				break;
			case 'template':
				switch (msg.action) {
					case 'create':
					case 'update':
						this.store.dispatch(new GetTemplateById(msg.objectId));
						break;
					case 'delete':
						this.store.dispatch(new RemoveTemplateFromStoreById(msg.objectId));
						break;
				}
				break;
			case 'workflow':
				switch (msg.action) {
					case 'create':
					case 'update':
						this.store.dispatch(new GetWorkflowById(msg.objectId));
						break;
					case 'delete':
						this.store.dispatch(new RemoveWorkflowFromStoreById(msg.objectId));
						break;
				}
				break;
			case 'paymentAccount':
				switch (msg.action) {
					case 'create':
					case 'update':
						this.store.dispatch(new GetPaymentsAccountById(msg.objectId));
						break;
					case 'delete':
						this.store.dispatch(new RemovePaymentFromStoreById(msg.objectId));
						break;
				}
				break;
		}
	}
}
