import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { AuthInterceptor } from '../models/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from '@trovata/environments/environment';
import { ENVIRONMENT_TOKEN, STORE_TOKEN } from '../models/config.model';
import { ConfigService } from '../services/config.service';

// @dynamic
@NgModule({
	imports: [
		CommonModule,
		AuthModule.forRoot({
			domain: environment.auth0Domain,
			cacheLocation: 'localstorage',
			clientId: environment.auth0ClientId,
			useRefreshTokens: true,
			authorizationParams: {
				audience: environment.auth0Audience,
				redirect_uri: window.location.origin,
			},
		}),
	],
	providers: [
		AuthService,
		ConfigService,
		{
			provide: ENVIRONMENT_TOKEN,
			useValue: environment,
		},
		{
			provide: STORE_TOKEN,
			useValue: Store,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
})
export class AuthenticationModule {}
