<div *ngIf="!openTrovataAIGrid; else trovataAIDownloadPage">
	<div class="transactions-grid-tab" (click)="deselectGrid()" role="main">
		<div class="grid-container" *ngIf="view" [ngClass]="{ hidden: loading }">
			<div class="grid-header">
				<div class="grid-header-left">
					<h1 class="card-title">Transactions Spreadsheet</h1>
					<div class="total-transactions">
						Total Transactions: {{ transactions.length }} &nbsp;&nbsp;&nbsp;&nbsp; CSV Download:
						<button mat-icon-button (click)="exportCSV(flex); $event.stopPropagation()" alt="Download CSV">
							<mat-icon>get_app</mat-icon>
						</button>
						<span class="included-tags-checkbox">
							Single Tag Column:
							<mat-checkbox [(ngModel)]="isSingleTagColumn" aria-label="Single Tag Column Toggle"> </mat-checkbox>
						</span>
					</div>
				</div>
				<div class="grid-header-right">
					<button mat-icon-button aria-label="Open Grid Settings" (click)="toggleMenu()">
						<mat-icon>settings</mat-icon>
					</button>
					<button mat-stroked-button color="primary" class="copy-button" (click)="copyAll(); $event.stopPropagation()" aria-label="Copy All Transactions">
						Copy All
					</button>
				</div>
			</div>
			<div *ngIf="showMenu && flex" class="column-menu">
				<button mat-icon-button (click)="toggleMenu()">
					<mat-icon>close</mat-icon>
				</button>

				<app-column-selector
					class="column-selector"
					*ngIf="transactionsGrid"
					[initialColumns]="defaultColumnsKeys"
					[dataSource]="DataSource.transactions"
					[displayColumnsMenu]="transactionsGrid.displayColumnsMenu"
					(newSelectedColumns)="onNewSelectedColumns($event)">
				</app-column-selector>
			</div>

			<wj-flex-grid
				#flex
				class="grid hoverRow trxn-grid-downloader"
				[allowMerging]="'All'"
				[stickyHeaders]="true"
				[itemsSource]="view"
				[isReadOnly]="true"
				(initialized)="flexInitialized(flex)"
				[selectionMode]="'MultiRange'"
				(updatedView)="setColumnVisibility()"
				(click)="$event.stopPropagation()">
				<wj-flex-grid-column [header]="'Date'" [binding]="'date'" [minWidth]="100" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Description'" [binding]="'description'" [minWidth]="200" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Description Detail'" [binding]="'descriptionDetail'" [minWidth]="200" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Bank'" [binding]="'institutionName'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Bank Reference'" [binding]="'bankReference'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Account Number'" [binding]="'accountNumber'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Account Name'" [binding]="'accountName'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Type'" [binding]="'type'" [minWidth]="50" width="*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Amount'" [binding]="'amount'" [align]="'right'" [minWidth]="150" width="2*" [cellTemplate]="wijmoTemplate">
				</wj-flex-grid-column>
				<wj-flex-grid-column [header]="'CUR'" [binding]="'currency'" [minWidth]="50" width="*"> </wj-flex-grid-column>
				<wj-flex-grid-column
					[header]="'Amount' + (currencyConverted ? ' (' + currencyConverted + ' Eq.)' : 'Eq.')"
					[binding]="'amountConverted'"
					[align]="'right'"
					[minWidth]="150"
					width="2*"
					[cellTemplate]="wijmoTemplate">
				</wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Category'" [binding]="'category'" [minWidth]="100" width="*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Status'" [binding]="'status'" [minWidth]="100" width="*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Intraday'" [binding]="'isIntraday'" [minWidth]="100" width="*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Memo'" [binding]="'memo'" [minWidth]="200" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column *ngFor="let data of transactionsGrid.metadataDisplay" [header]="data" [binding]="data" [minWidth]="150" width="*">
				</wj-flex-grid-column>
				<wj-flex-grid-column [header]="'G/L Tag'" [binding]="'glTag'" [align]="'center'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'G/L Tag Description'" [binding]="'glTagDescription'" [align]="'center'" [minWidth]="200" width="2*">
				</wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Debit G/L Code'" [binding]="'debitGlCode'" [align]="'center'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column [header]="'Credit G/L Code'" [binding]="'creditGlCode'" [align]="'center'" [minWidth]="150" width="2*"> </wj-flex-grid-column>
				<wj-flex-grid-column *ngIf="isSingleTagColumn" [header]="'Tags'" [binding]="'singleTagColumn'" [minWidth]="200" width="2*"> </wj-flex-grid-column>
				<ng-container *ngIf="!isSingleTagColumn">
					<wj-flex-grid-column
						*ngFor="let tag of transactionsGrid.includedTags"
						[header]="tag.tagTitle"
						[align]="'center'"
						[minWidth]="150"
						[binding]="tag.tagId"
						width="2*">
					</wj-flex-grid-column>
				</ng-container>
			</wj-flex-grid>
		</div>
		<div class="loading-container" *ngIf="loading">
			<h1 class="loading-text">{{ loadingText }}</h1>
			<mat-progress-bar color="primary" class="loading-bar" mode="determinate" [value]="loadingProgress" aria-label="Downloading Transactions Progress Bar">
			</mat-progress-bar>
		</div>
	</div>
</div>

<ng-template #trovataAIDownloadPage>
	<div class="trovata-ai-export-page">
		<trovata-data-export-page [columns]="gridCols" [interactionId]="interactionId" (downloadClick)="exportCSV($event)"></trovata-data-export-page>
	</div>
</ng-template>
