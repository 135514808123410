import { AdminApproval } from 'src/app/shared/models/admin-approval.model';
import { WorkflowToCreateOrUpdate, WorkflowToDelete } from '../../models/workflow.model';

export class InitWorkflowsState {
	static readonly type: string = '[Workflows] InitWorkflowsState';
}

export class ClearWorkflowsState {
	static readonly type: string = '[Workflows] ClearWorkflowsState';
}

export class ResetWorkflowsState {
	static readonly type: string = '[Workflows] ResetWorkflowsState';
}

export class GetWorkflows {
	static readonly type: string = '[Workflows] GetWorkflows';
}

export class CreateWorkflow {
	static readonly type: string = '[Workflows] CreateWorkflow';
	constructor(public workflowToCreate: WorkflowToCreateOrUpdate) {}
}

export class DeleteWorkflow {
	static readonly type: string = '[Workflows] DeleteWorkflow';
	constructor(public workflowToDelete: WorkflowToDelete) {}
}

export class UpdateWorkflow {
	static readonly type: string = '[Workflows] UpdateWorkflow';
	constructor(public workflowToUpdate: WorkflowToCreateOrUpdate) {}
}

export class GetWorkflowById {
	static readonly type: string = '[Workflows] GetWorkflowById';
	constructor(public workflowId: string) {}
}

export class PostWorkflowAdminApproval {
	static readonly type: string = '[Workflows] PostWorkflowAdminApproval';
	constructor(public adminApproval: AdminApproval) {}
}

export class RemoveWorkflowFromStoreById {
	static readonly type: string = '[Payments] RemoveWorkflowFromStoreById';
	constructor(public workflowId: string) {}
}
