import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { defaultReconDailyColumnPreferences, ReconciliationReportPreferences } from 'src/app/features/reports/models/reconciliation.model';
import { AllPreferences } from '../models/preferences.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {
	refreshPreferencesCache = {};
	private preferencesCache = {};

	reconReportPreferences: {};

	tagChartData = {};

	private _vectorPreference: Subject<any> = new Subject<any>();
	private _streamPreference: Subject<any> = new Subject<any>();

	constructor(private httpClient: HttpClient) {}

	vectorPreference(): Observable<any> {
		return this._vectorPreference.asObservable();
	}

	streamPreference(): Observable<any> {
		return this._streamPreference.asObservable();
	}

	/**
	 * @description Returns all the preferences associated with a resource.
	 * @param {boolean?} refreshCache If you want to force a cache refresh on this request.
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public getAllPreferences(resourceId: string, refreshCache?: boolean) {
		if (this.refreshPreferencesCache || refreshCache || this.preferencesCache[resourceId].length === 0) {
			const url = environment.edgeAPI() + '/preferences/' + resourceId;

			return this.httpClient
				.get(url, {
					observe: 'response',
				})
				.pipe(
					map((resp: HttpResponse<AllPreferences>) => {
						this.preferencesCache[resourceId] = resp.body;
						return resp;
					}),
					catchError(err => throwError(err))
				);
		} else {
			const resp = { body: this.preferencesCache[resourceId] };
			this.refreshPreferencesCache = false;
			return of(resp);
		}
	}

	/**
	 * @description Returns the preference associated with a resource and specific key.
	 * @param {string} resourceId The resource associated with this preference
	 * @param {string} key The key associated with this preference
	 * @param {boolean?} refreshCache If you want to force a cache refresh on this request.
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public getPreferenceByKey(resourceId: string, key: string, refreshCache?: boolean): Observable<HttpResponse<Object>> | any {
		if (this.refreshPreferencesCache || refreshCache || this.preferencesCache[resourceId][key].length === 0) {
			const url = environment.edgeAPI() + '/preferences/' + resourceId + '/' + key;

			return this.httpClient
				.get(url, {
					observe: 'response',
				})
				.pipe(
					map(resp => {
						if (resp.body != null) {
							if (!this.preferencesCache) {
								this.preferencesCache = {};
							}
							if (!this.preferencesCache[resourceId]) {
								this.preferencesCache[resourceId] = {};
							}
							this.preferencesCache[resourceId][key] = resp.body;
							return resp;
						} else {
							const respEmpty = { body: '' };
							return respEmpty;
						}
					}),
					catchError(err => throwError(err))
				);
		} else {
			const resp = { body: this.preferencesCache[resourceId][key] };
			this.refreshPreferencesCache = false;
			return of(resp);
		}
	}

	/**
	 * @description Posts the preference associated with a resource and specific key.
	 * @param {string} resourceId The resource associated with this preference
	 * @param {string} key The key associated with this preference
	 * @param {any} value The JSON formated value of this preference
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public postPreference(resourceId: string, key: string, value: any): Observable<HttpResponse<Object>> {
		this.refreshPreferencesCache = true;
		const url = environment.edgeAPI() + '/preferences/' + resourceId + '/' + key;
		return this.httpClient
			.post(url, value, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	/**
	 * @description Delete the preference associated with a resource and specific key.
	 * @param {string} resourceId The resource associated with this preference
	 * @param {string} key The key associated with this preference
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public deletePreference(resourceId: string, key: string): Observable<HttpResponse<Object>> {
		this.refreshPreferencesCache = true;
		const url = environment.edgeAPI() + '/preferences/' + resourceId + '/' + key;

		return this.httpClient
			.delete(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public assignTagChartPreference(globalRes) {
		this.tagChartData = globalRes.tagChartData ? globalRes.tagChartData : {};
	}

	public assignReconReportPreference(globalRes: AllPreferences) {
		this.reconReportPreferences = globalRes.reconReportPreferences || {};
	}

	public getReconReportPreferences(reconId?: string): ReconciliationReportPreferences {
		const reportPreference: ReconciliationReportPreferences = {
			currency: '',
			periodView: {
				shownRows: {
					openingBalanceConverted: true,
					closingBalanceConverted: true,
					changeInBalanceConverted: true,
					netTransactionActivityConverted: true,
				},
				order: {},
			},
			dailyView: {
				order: {},
				shownColumns: JSON.parse(JSON.stringify(defaultReconDailyColumnPreferences)),
			},
		};
		if (reconId && this.reconReportPreferences && this.reconReportPreferences[reconId]) {
			Object.assign(reportPreference, JSON.parse(JSON.stringify(this.reconReportPreferences[reconId])));
		}
		return reportPreference;
	}

	public getTagChartData() {
		return this.tagChartData;
	}

	public setReconReportPreferences(payload, whichToUpdate?: string) {
		if (!this.reconReportPreferences) {
			this.reconReportPreferences = {};
		}
		if (whichToUpdate) {
			this.reconReportPreferences[whichToUpdate] = payload;
		} else {
			this.reconReportPreferences = payload;
		}
	}

	public setTagChartData(payload, whichToUpdate) {
		if (whichToUpdate === 'tagChartData') {
			this.tagChartData = payload;
		}
	}

	public postReconReportPreferences() {
		return this.postPreference('global', 'reconReportPreferences', this.reconReportPreferences);
	}

	public unsubscribeStreamPreference() {
		this._streamPreference.complete();
		this._streamPreference = new Subject<any>();
	}
}
