import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeveloperApplication } from '../models/developer-application.model';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class DeveloperPortalApplicationsService {
	constructor(private httpClient: HttpClient) {}

	getApplications(): Observable<HttpResponse<DeveloperApplication[]>> {
		return this.httpClient
			.get<DeveloperApplication[]>(environment.trovataAPI('workspace') + '/developer/applications', {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	createApplication(application: DeveloperApplication): Observable<HttpResponse<{ appId: string }>> {
		const url = environment.trovataAPI('workspace') + '/developer/applications';
		return this.httpClient
			.post<{ appId: string }>(url, application, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	updateApplication(application: DeveloperApplication): Observable<HttpResponse<void>> {
		const url = environment.trovataAPI('workspace') + '/developer/applications/' + application.appId;
		return this.httpClient
			.put<void>(url, application, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteApplication(application: DeveloperApplication): Observable<HttpResponse<void>> {
		const url = environment.trovataAPI('workspace') + '/developer/applications/' + application.appId;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}
}
