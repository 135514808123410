import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GetSharesForResourceResponse, ResourceShareMap, ResourceType, Share } from '@trovata/app/shared/models/share.model';
import {
	ClearShareState,
	CreateShare,
	DeleteShare,
	GetSharesForResource,
	GetSharesForResourceId,
	InitShareState as InitShareState,
	UpdateShare,
} from '../../actions/share.actions';
import { TrovataAppState } from '@trovata/app/core/models/state.model';
import { SerializationService } from '@trovata/app/core/services/serialization.service';
import { Observable, Subscription, firstValueFrom, throwError } from 'rxjs';
import { ShareService } from '@trovata/app/shared/services/share.service';
import { HttpResponse } from '@angular/common/http';

export type ShareStateModel = {
	[K in ResourceType]: ResourceShareMap;
};
@State<ShareStateModel>({
	name: 'share',
	defaults: {
		workbook: null,
	},
})
@Injectable()
export class ShareState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private store: Store,
		private shareService: ShareService,
		private serializationService: SerializationService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static sharesByResourceId(state: ShareStateModel) {
		return (resourceType: ResourceType, resourceId: string): Share[] => (state && state[resourceType] ? state[resourceType][resourceId] : null);
	}

	@Selector()
	static sharesByResourceType(state: ShareStateModel) {
		return (resourceType: ResourceType): ResourceShareMap => (state ? state[resourceType] : null);
	}

	@Action(InitShareState)
	async initShareState(context: StateContext<ShareStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const sharesStateIsCached: boolean = this.shareStateIsCached(deserializedState);
			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (sharesStateIsCached && appReady) {
						const state: ShareStateModel = deserializedState.share;
						context.patchState(state);
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetSharesForResourceId)
	async getSharesForResourceId(context: StateContext<ShareStateModel>, action: GetSharesForResourceId): Promise<void> {
		try {
			const state: ShareStateModel = context.getState();
			if (action.resourceId) {
				const response: HttpResponse<GetSharesForResourceResponse> = await firstValueFrom(
					this.shareService.getSharesForResource(action.resourceType, action.resourceId)
				);
				const shares: Share[] = response.body.resourceShares.length ? response.body.resourceShares[0].shares : [];
				const newResourceTypeState: ResourceShareMap = {
					...state[action.resourceType],
					[action.resourceId]: shares,
				};
				const newState: { [resourceType: string]: ResourceShareMap } = {
					...state,
					[action.resourceType]: newResourceTypeState,
				};

				context.patchState(newState);
			} else {
				throw new Error('resourceId is required to fetch shares by ID');
			}
		} catch (error) {
			throw error;
		}
	}

	@Action(GetSharesForResource)
	async getSharesForResource(context: StateContext<ShareStateModel>, action: GetSharesForResource): Promise<void> {
		try {
			let state: ShareStateModel = context.getState();
			const response: HttpResponse<GetSharesForResourceResponse> = await firstValueFrom(this.shareService.getSharesForResource(action.resourceType));
			const shares: ResourceShareMap = {};
			if (response.body.resourceShares.length) {
				response.body.resourceShares.forEach(resource => {
					shares[resource.resourceId] = resource.shares;
				});
			}
			state = { [action.resourceType]: shares };
			context.patchState(state);
		} catch (error) {
			throw error;
		}
	}

	@Action(CreateShare)
	async createShare(context: StateContext<ShareStateModel>, action: CreateShare): Promise<Share> {
		try {
			const response: HttpResponse<Share> = await firstValueFrom(this.shareService.createShare(action.share));
			await firstValueFrom(context.dispatch(new GetSharesForResourceId(action.share.resourceType, action.share.resourceId)));
			return response.body;
		} catch (error) {
			throw error;
		}
	}

	@Action(UpdateShare)
	async updateShare(context: StateContext<ShareStateModel>, action: UpdateShare): Promise<void> {
		try {
			const response: HttpResponse<Share> = await firstValueFrom(this.shareService.updateShare(action.share));
			const state: ShareStateModel = context.getState();
			const filteredShares: Share[] = state[action.share.resourceType][action.share.resourceId].filter(
				(share: Share) => share.shareId !== action.share.shareId
			);
			filteredShares.push(response.body);
			state[action.share.resourceType][action.share.resourceId] = filteredShares;
			context.patchState(state);
			await firstValueFrom(context.dispatch(new GetSharesForResourceId(action.share.resourceType, action.share.resourceId)));
		} catch (error) {
			throw error;
		}
	}

	@Action(DeleteShare)
	async deleteShare(context: StateContext<ShareStateModel>, action: DeleteShare): Promise<void> {
		try {
			await firstValueFrom(this.shareService.deleteShare(action.shareId));
			await firstValueFrom(context.dispatch(new GetSharesForResourceId(action.resourceType, action.resourceId)));
		} catch (error) {
			throw error;
		}
	}

	@Action(ClearShareState)
	clearShareState(context: StateContext<ShareStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: ShareStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private shareStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.share) {
			return true;
		} else {
			return false;
		}
	}
}
