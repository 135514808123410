import { StorageEngine } from '@ngxs/storage-plugin';
import { WorkerPoolManagerService } from '../../services/worker-pool-manager.service';
import { WorkerAction, WorkerMessage } from '../../models/worker.model';
import { v4 as uuid } from 'uuid';
import { TrovataAppState, TrovataAppStateKey } from '../../models/state.model';

export class IndexedDBStorageEngine implements StorageEngine {
	private storageLength: number = 0;

	constructor(private workerPoolManagerService: WorkerPoolManagerService) {}

	get length(): number {
		return this.storageLength;
	}

	getItem(key: string): void {
		this.workerPoolManagerService
			.awaitTask({
				action: WorkerAction.getCachedState,
				taskId: uuid(),
				key: key,
			})
			.then((workerResponse: WorkerMessage) => workerResponse.data)
			.catch((error: Error) => {
				throw error;
			});
	}

	setItem(key: typeof TrovataAppStateKey, state: TrovataAppState): void {
		this.workerPoolManagerService
			.awaitTask({
				action: WorkerAction.cacheState,
				taskId: uuid(),
				key: key,
				data: state,
			})
			.then(() => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	removeItem(key: string): void {
		this.workerPoolManagerService
			.awaitTask({
				action: WorkerAction.removeCachedItem,
				taskId: uuid(),
				key: key,
			})
			.then(() => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	clear(): void {
		this.workerPoolManagerService
			.awaitTask({
				action: WorkerAction.clearDB,
				taskId: uuid(),
			})
			.then(() => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	private setStorageLength(): void {
		this.workerPoolManagerService
			.awaitTask({
				action: WorkerAction.getStorageLength,
				taskId: uuid(),
			})
			.then((workerResponse: WorkerMessage<number>) => {
				this.storageLength = workerResponse.data;
			})
			.catch((error: Error) => {
				throw error;
			});
	}
}
