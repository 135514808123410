import { DateRange } from '@angular/material/datepicker';
import { CalendarSettings } from '../models/date-range-picker.model';
import { DateTime } from 'luxon';

export const getDateRangeFromCalendarSettings = (calendarSettings: CalendarSettings): DateRange<DateTime> => {
	if (calendarSettings.isRolling) {
		const currentDate: DateTime = DateTime.now();
		let startDate: DateTime;
		if (calendarSettings.periodsOffset < 0) {
			startDate = currentDate.minus({ days: Math.abs(calendarSettings.periodsOffset) });
		} else {
			startDate = currentDate.plus({ days: calendarSettings.periodsOffset });
		}
		const endDate: DateTime = startDate.plus({ days: calendarSettings.periods - 1 });

		return new DateRange(startDate, endDate);
	} else {
		return new DateRange(
			calendarSettings.startDate ? DateTime.fromISO(calendarSettings.startDate) : null,
			calendarSettings.endDate ? DateTime.fromISO(calendarSettings.endDate) : null
		);
	}
};
