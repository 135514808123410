import { BalancesAggregationLegacy } from '../../reports/models/analysis.legacy.model';
import { AnalysisBalanceProperty, AnalysisBalanceValue } from '../../reports/models/analysis.model';

export class CurrentCashData {
	balancesAggregation: BalancesAggregationLegacy[];
	currencyConverted: string;
	summary: {
		balances: AnalysisBalanceValue[];
	};
}
export class CurrentCash {
	accountIds: string[];
	currencyOverride: string;
	name: string;
	description: string;
	preferences: CurrentCashPreferences;
	lastModifiedDate: string;
	createdDate: string;
	currentCashId: string;
	data?: CurrentCashData;
	hasError?: boolean;
}

export class CurrentCashPreferences {
	rounding: number;
	balanceType: AnalysisBalanceProperty;
	index?: number;
}
