import { TrovataAppStateKey, TrovataAppState } from './state.model';

export interface WorkerEventData {
	action: WorkerEventDataAction;
	type: WorkerEventDataType;
	id: string;
	param: any;
}

export enum WorkerEventDataAction {
	parseString = 'parseString',
	decompressString = 'decompressString',
	decompressedString = 'decompressedString',
	getReportsHashDict = 'getReportsHashDict',
	reportsHashDict = 'reportsHashDict',
	getCurrentCashHashDict = 'getCurrentCashHashDict',
	currentCashHashDict = 'currentCashHashDict',
}

export enum WorkerEventDataType {
	reportData = 'reportData',
	currentCashData = 'currentCashData',
	reportArray = 'reportArray',
	currentCashArray = 'currentCashArray',
	forecastData = 'forecastData',
	state = 'state',
}

export enum WorkerAction {
	cacheState = 'cacheState',
	stateCached = 'stateCached',
	getCachedState = 'getCachedState',
	cachedStateRetrieved = 'cachedStateRetrieved',
	removeCachedItem = 'removeCachedItem',
	cachedItemRemoved = 'cachedItemRemoved',
	clearDB = 'clearDB',
	dbCleared = 'dbCleared',
	getStorageLength = 'getStorageLength',
	storageLengthRetrieved = 'storageLengthRetrieved',
}

export type WorkerMessageData<T = TrovataAppState | number> = T;

export interface WorkerMessage<T = WorkerMessageData> {
	action: WorkerAction;
	taskId: string;
	data?: T;
	key?: typeof TrovataAppStateKey;
}
