import { Injectable } from '@angular/core';
import { AnalyticsUserActions } from '../utils/analyticsEnums';

@Injectable({
	providedIn: 'root',
})
export class VitallyService {
	// eslint-disable-next-line @typescript-eslint/typedef
	private theWindow = <any>window;
	private accountId: string;
	private userId: string;

	setAccount(parameters: object): void {
		// eslint-disable-next-line @typescript-eslint/typedef
		const Vitally = this.theWindow.Vitally;
		const customerId: string = parameters['customerId'];
		const customerName: string = parameters['customerName'];
		this.accountId = customerId;

		Vitally?.account({
			accountId: customerId,
			traits: {
				name: customerName,
			},
		});
	}

	setUser(parameters: object): void {
		// eslint-disable-next-line @typescript-eslint/typedef
		const Vitally = this.theWindow.Vitally;
		const customerId: string = parameters['customerId'];
		const userId: string = parameters['userId'];
		this.userId = userId;

		const email: string = parameters['email'];
		const name: string = parameters['name'];
		Vitally?.user({
			userId: userId,
			accountId: customerId,
			traits: {
				email: email,
				name: name,
			},
		});
	}

	userAction(action: AnalyticsUserActions | string): void {
		// eslint-disable-next-line @typescript-eslint/typedef
		const Vitally = this.theWindow.Vitally; // ('trackEvent', action);
		const userId: string = this.userId ?? null;
		Vitally?.track({
			event: action,
			userId: userId,
			properties: {},
		});
	}

	userActionWithParams(action: AnalyticsUserActions | string, parameters: object): void {
		// eslint-disable-next-line @typescript-eslint/typedef
		const Vitally = this.theWindow.Vitally;
		const userId: string = this.userId ?? null;
		Vitally?.track({
			event: action,
			userId: userId,
			properties: parameters,
		});
	}
}
