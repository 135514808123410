import { ReportV4, ReportV4ElementData } from '../../models/report.model';

export class InitReportsState {
	static readonly type: string = '[Reports] InitReportsState';
}

export class ClearReportsState {
	static readonly type: string = '[Reports] ClearReportsState';
}

export class ResetReportsState {
	static readonly type: string = '[Reports] ResetReportsState';
}

export class CreateReport {
	static readonly type: string = '[Reports] CreateReport';
	constructor(public report: ReportV4) {}
}
export class GetReports {
	static readonly type: string = '[Reports] GetReports';
}

export class UpdateReport {
	static readonly type: string = '[Reports] UpdateReport';
	constructor(public report: ReportV4) {}
}

export class DeleteReport {
	static readonly type: string = '[Reports] DeleteReport';
	constructor(public reportId: string) {}
}

export class AddReportDataToReport {
	static readonly type: string = '[Reports] AddReportDataToReport';
	constructor(
		public report: ReportV4,
		public reportData: ReportV4ElementData[]
	) {}
}

export class LazyLoadReportsData {
	static readonly type: string = '[Reports] LazyLoadReportsData';
	constructor(public reportId: string) {}
}

export class UpdateChangedReportsState {
	static readonly type: string = '[Reports] UpdateChangedReportsState';
	constructor(public reports: ReportV4[]) {}
}
