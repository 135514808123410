import { DateTime } from 'luxon';
import { CollectionView } from '@grapecity/wijmo';
import { AccountTargetV3 } from './account-target.model';
import { ICellTemplateFunction } from '@grapecity/wijmo.grid';
import { FilterOption } from './abstract-filter.model';
import { CurrencyDict, Currency } from './currency.model';
import { PaginatedTableColumn, ColumnType, EditType, PaginatedTableViewModel } from './paginated-table-view-model';
import { Formatter } from '../utils/formatter';
import { ParameterType, SearchData, SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { Predicate } from '@angular/core';
import { PermissionId, PermissionMap } from '../../features/settings/models/feature.model';
import { UtilityText } from '../../core/models/utility-text';
import { Institution } from './institution.model';
import { SortDirection } from '@angular/material/sort';
import { AccountTagType } from '@trovata/app/features/transactions/models/tag.model';
import { Entity } from '@trovata/app/features/entities/models/entity.model';
import { TQLPropertyKey, TQLValues, TQLValuesDict, getFieldPropertyKeyFromAcctGroup } from './tql.model';
import { GenericOption } from './option.model';
import { CustomMenuOption } from './custom-menu.model';

export enum UndoAction {
	addAccounts = 'addAccounts',
	removeAccounts = 'removeAccounts',
}

export const accountGroupAutoCompleteTypes: ParameterType[] = [
	ParameterType.currency,
	ParameterType.entity,
	ParameterType.division,
	ParameterType.region,
	ParameterType.institutionId,
	ParameterType.accountId,
	ParameterType.isManual,
	ParameterType.entityId,
];

export enum AcctGroupColumns {
	acctNum = 'acctNum',
	type = 'type',
	acctName = 'acctName',
	compactLabel = 'compactLabel',
	institutionNickname = 'institutionId',
	entityName = 'entityName',
	entityAlias = 'entityAlias',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	entityAcctTag = 'entityGroupingId',
	regionAcctTag = 'regionGroupingId',
	divisionAcctTag = 'divisionGroupingId',
	balance = 'balance',
	currency = 'currency',
	convertedBalance = 'convertedBalance',
	convertedCurrency = 'convertedCurrency',
	lastUpdate = 'lastUpdate',
	lastRequested = 'lastRequested',
	manualHTML = 'manualHTML',
	actions = 'actions',
}
export class AcctGroupType {
	value: AcctGroupTypeOption;
	view: string;
}

export enum AcctGroupTypeOption {
	none = '',
	institutionId = 'institutionId',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	entityGroupingId = 'entityGroupingId',
	regionGroupingId = 'regionGroupingId',
	divisionGroupingId = 'divisionGroupingId',
	currency = 'currency',
	isManual = 'isManual',
	type = 'type',
}

export const acctGroupTypes: AcctGroupType[] = [
	{ value: AcctGroupTypeOption.none, view: 'None' },
	{ value: AcctGroupTypeOption.type, view: 'Type' },
	{ value: AcctGroupTypeOption.institutionId, view: 'Bank' },
	{ value: AcctGroupTypeOption.entityId, view: 'Entity Alias' },
	{ value: AcctGroupTypeOption.entityRegion, view: 'Entity Region' },
	{ value: AcctGroupTypeOption.entityDivision, view: 'Entity Division' },
	{ value: AcctGroupTypeOption.entityGroupingId, view: AccountTagType.entity },
	{ value: AcctGroupTypeOption.regionGroupingId, view: AccountTagType.region },
	{ value: AcctGroupTypeOption.divisionGroupingId, view: AccountTagType.division },
	{ value: AcctGroupTypeOption.currency, view: 'Currency' },
	{ value: AcctGroupTypeOption.isManual, view: 'Manual/Automated' },
];

export const filterAcctGroupTypesByPermission: (userAvailablePermissions: PermissionMap) => AcctGroupType[] = (userAvailablePermissions: PermissionMap) =>
	acctGroupTypes.filter((type: AcctGroupType) => {
		if (
			!userAvailablePermissions.has(PermissionId.readEntities) &&
			(type.value === AcctGroupTypeOption.entityId || type.value === AcctGroupTypeOption.entityRegion || type.value === AcctGroupTypeOption.entityDivision)
		) {
			return false;
		}
		if (
			!userAvailablePermissions.has(PermissionId.readAccountTags) &&
			(type.value === AcctGroupTypeOption.entityGroupingId ||
				type.value === AcctGroupTypeOption.regionGroupingId ||
				type.value === AcctGroupTypeOption.divisionGroupingId)
		) {
			return false;
		}
		return true;
	});

export const acctGroupPagColumns: PaginatedTableColumn[] = [
	new PaginatedTableColumn(AcctGroupColumns.acctNum, ColumnType.string, { header: 'Acct.#', sortable: true, colGroup: 1, defaultToEnabled: true }),
	new PaginatedTableColumn(AcctGroupColumns.type, ColumnType.string, { header: 'Type', sortable: true, colGroup: 1 }),
	new PaginatedTableColumn(AcctGroupColumns.acctName, ColumnType.string, {
		header: 'Alias',
		sortable: true,
		colGroup: 1,
		templateFields: { tooltipBinding: 'acctName' },
	}),
	new PaginatedTableColumn(AcctGroupColumns.compactLabel, ColumnType.string, { header: 'Account', sortable: true, defaultToEnabled: true }),
	new PaginatedTableColumn(AcctGroupColumns.institutionNickname, ColumnType.string, {
		header: 'Bank',
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'institutionNickname',
			displayBinding: 'institutionNickname',
		},
		defaultToEnabled: true,
	}),
	new PaginatedTableColumn(AcctGroupColumns.entityName, ColumnType.string, {
		header: 'Entity',
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'entityName',
			displayBinding: 'entityName',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.entityAlias, ColumnType.string, {
		header: 'Entity Alias',
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'entityAlias',
			displayBinding: 'entityAlias',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.entityRegion, ColumnType.string, {
		header: 'Entity Region',
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'entityRegion',
			displayBinding: 'entityRegion',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.entityDivision, ColumnType.string, {
		header: 'Entity Division',
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'entityDivision',
			displayBinding: 'entityDivision',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.entityAcctTag, ColumnType.string, {
		header: AccountTagType.entity,
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'entityAcctTag',
			displayBinding: 'entityAcctTag',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.regionAcctTag, ColumnType.string, {
		header: AccountTagType.region,
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'regionAcctTag',
			displayBinding: 'regionAcctTag',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.divisionAcctTag, ColumnType.string, {
		header: AccountTagType.division,
		sortable: true,
		colGroup: 1,
		templateFields: {
			tooltipBinding: 'divisionAcctTag',
			displayBinding: 'divisionAcctTag',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.balance, ColumnType.string, {
		header: 'Amount',
		sortable: true,
		colGroup: 1,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Balance',
			displayBinding: 'balanceDisplay',
		},
		defaultToEnabled: true,
	}),
	new PaginatedTableColumn(AcctGroupColumns.currency, ColumnType.string, { header: 'Currency', sortable: true, colGroup: 1, defaultToEnabled: true }),
	new PaginatedTableColumn(AcctGroupColumns.convertedBalance, ColumnType.string, {
		header: 'Converted',
		sortable: true,
		colGroup: 2,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Converted Balance',
			displayBinding: 'convertedBalanceDisplay',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.convertedCurrency, ColumnType.string, {
		header: 'Currency',
		sortable: true,
		colGroup: 2,
		templateFields: { detailedHeader: 'Converted Currency' },
		defaultToEnabled: true,
	}),
	new PaginatedTableColumn(AcctGroupColumns.lastUpdate, ColumnType.string, {
		header: 'As of',
		colGroup: 3,
		templateFields: {
			textAlign: 'left',
		},
		headerTemplateFields: { icon: 'info_outlined', tooltip: '"As of" shows the last balance date sent by the bank' },
	}),
	new PaginatedTableColumn(AcctGroupColumns.lastRequested, ColumnType.string, {
		header: 'Requested',
		colGroup: 3,
		templateFields: {
			textAlign: 'left',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.manualHTML, ColumnType.customHTML, {
		header: 'Manual',
		colGroup: 3,
		templateFields: {
			textAlign: 'center',
			tooltipBinding: 'manualHTMLTooltip',
		},
	}),
	new PaginatedTableColumn(AcctGroupColumns.actions, ColumnType.menuButton, {
		header: 'Actions',
		footer: null,
		sortable: false,
		included: true,
		colGroup: 1,
		templateFields: { textAlign: 'center', icon: 'more_vert' },
		editFields: { btnId: 'openAccountActions', icon: 'more_vert', type: EditType.button },
		defaultToEnabled: true,
	}),
];

export const filterAcctGroupColDef: (userAvailablePermissions: PermissionMap, col: AcctGroupColumns | string) => boolean = (
	userAvailablePermissions: PermissionMap,
	col: AcctGroupColumns | string
) => {
	if (
		!userAvailablePermissions.has(PermissionId.readEntities) &&
		(col === AcctGroupColumns.entityName ||
			col === AcctGroupColumns.entityAlias ||
			col === AcctGroupColumns.entityRegion ||
			col === AcctGroupColumns.entityDivision)
	) {
		return false;
	}
	return true;
};

export const filterAcctGroupColsByPermission: (userAvailablePermissions: PermissionMap) => PaginatedTableColumn[] = (userAvailablePermissions: PermissionMap) =>
	acctGroupPagColumns.filter((col: PaginatedTableColumn) => filterAcctGroupColDef(userAvailablePermissions, col.colDef));

export const quickEditColumns: (tqlValuesDict: TQLValuesDict, userAvailablePermissions: PermissionMap) => PaginatedTableColumn[] = (
	tqlValuesDict: TQLValuesDict,
	userAvailablePermissions: PermissionMap
) => {
	const noneOpt: FilterOption = {
		displayValue: '--',
		key: '',
		id: null,
	};
	const entityOpts: TQLValues = tqlValuesDict[TQLPropertyKey.entity];
	const regionOpts: TQLValues = tqlValuesDict[TQLPropertyKey.region];
	const divisionOpts: TQLValues = tqlValuesDict[TQLPropertyKey.division];
	const acctTypeOpts: FilterOption[] = tqlValuesDict[TQLPropertyKey.accountType].values;
	return [
		new PaginatedTableColumn(AcctGroupColumns.acctNum, ColumnType.string, {
			header: 'Acct.#',
			colGroup: 1,
			templateFields: { disabled: true, textAlign: 'left' },
		}),
		new PaginatedTableColumn(AcctGroupColumns.acctName, ColumnType.string, {
			header: 'Alias',
			colGroup: 2,
			templateFields: {
				textAlign: 'left',
				tooltip: !userAvailablePermissions.has(PermissionId.updateAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.text,
				disabled: !userAvailablePermissions.has(PermissionId.updateAccounts),
			},
		}),
		new PaginatedTableColumn(AcctGroupColumns.type, ColumnType.string, {
			header: 'Type',
			colGroup: 3,
			templateFields: {
				textAlign: 'left',
				tooltip: !userAvailablePermissions.has(PermissionId.updateAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.select,
				selectOpts: acctTypeOpts,
				asyncOpts: true,
				valuePath: 'id',
				displayPath: 'displayValue',
				disabled: !userAvailablePermissions.has(PermissionId.updateAccounts),
			},
		}),
		new PaginatedTableColumn(AcctGroupColumns.entityAcctTag, ColumnType.string, {
			header: AccountTagType.entity,
			colGroup: 5,
			templateFields: {
				textAlign: 'left',
				tooltip: !userAvailablePermissions.has(PermissionId.updateAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.select,
				selectOpts: [noneOpt, ...entityOpts.values],
				asyncOpts: true,
				valuePath: 'id',
				displayPath: 'displayValue',
				disabled: !userAvailablePermissions.has(PermissionId.updateAccounts),
			},
		}),
		new PaginatedTableColumn(AcctGroupColumns.regionAcctTag, ColumnType.string, {
			header: AccountTagType.region,
			colGroup: 6,
			templateFields: {
				textAlign: 'left',
				tooltip: !userAvailablePermissions.has(PermissionId.updateAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.select,
				selectOpts: [noneOpt, ...regionOpts.values],
				asyncOpts: true,
				valuePath: 'id',
				displayPath: 'displayValue',
				disabled: !userAvailablePermissions.has(PermissionId.updateAccounts),
			},
		}),
		new PaginatedTableColumn(AcctGroupColumns.divisionAcctTag, ColumnType.string, {
			header: AccountTagType.division,
			colGroup: 7,
			templateFields: {
				textAlign: 'left',
				tooltip: !userAvailablePermissions.has(PermissionId.updateAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.select,
				selectOpts: [noneOpt, ...divisionOpts.values],
				asyncOpts: true,
				valuePath: 'id',
				displayPath: 'displayValue',
				disabled: !userAvailablePermissions.has(PermissionId.updateAccounts),
			},
		}),
		new PaginatedTableColumn(AcctGroupColumns.currency, ColumnType.string, {
			header: 'Currency',
			colGroup: 8,
			templateFields: { disabled: true, textAlign: 'left' },
		}),
		new PaginatedTableColumn(AcctGroupColumns.manualHTML, ColumnType.customHTML, {
			header: '<div class="account-distinction"><img class="account-distinction-icon" src="src/assets/svgs/supports-payments.svg"></div>',
			colGroup: 8,
			templateFields: {
				textAlign: 'center',
				sortingDataAccessor: 'isManual',
				tooltip: !userAvailablePermissions.has(PermissionId.deleteAccounts) ? UtilityText.permissionTooltip : '',
			},
			editFields: {
				type: EditType.button,
				icon: 'remove_circle_outline',
				btnId: 'deleteManualAcct',
				disabled: !userAvailablePermissions.has(PermissionId.deleteAccounts),
			},
		}),
	];
};

const accountGroupRowFilter: (searchData: SearchData) => Predicate<AccountGroupRow> = (searchData: SearchData) => (row: AccountGroupRow) => {
	if (!searchData?.parameters?.length && !searchData?.filter) {
		return true;
	}
	let returnVal: boolean = true;
	if (searchData?.parameters?.length) {
		accountGroupAutoCompleteTypes.forEach(paramType => {
			const typeParams: SearchParameter[] = searchData.parameters.filter(param => param.type === paramType);
			if (typeParams.length) {
				if (
					!typeParams.find(param => param.value === row.accountTarget[typeParams[0].getObjectType()]) &&
					!(typeParams[0].getObjectType() === ParameterType.isManual && row.accountTarget[typeParams[0].getObjectType()])
				) {
					returnVal = false;
				}
			}
		});
	}
	if (searchData?.filter) {
		const lowerCaseFilter: string = searchData.filter.toLowerCase();
		if (
			!(
				(row.acctNum != null && row.acctNum.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.currency != null && row.currency.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.institutionNickname != null && row.institutionNickname.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.entityAcctTag != null && row.entityAcctTag.indexOf(lowerCaseFilter) !== -1) ||
				(row.regionAcctTag != null && row.regionAcctTag.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.divisionAcctTag != null && row.divisionAcctTag.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.type != null && row.type.toLowerCase().indexOf(lowerCaseFilter) !== -1) ||
				(row.acctName != null && row.acctName.toLowerCase().indexOf(lowerCaseFilter) !== -1)
			)
		) {
			returnVal = false;
		}
	}
	return returnVal;
};

export class AccountGroupRow {
	acctNum: string;
	compactLabel: string;
	type: string;
	acctName: string;
	accountId: string;
	institutionId: string;
	institutionNickname: string;
	entityId: string;
	entityName: string;
	entityAlias: string;
	entityRegion: string;
	entityDivision: string;
	entityAcctTag: string;
	entityGroupingId: string;
	regionAcctTag: string;
	regionGroupingId: string;
	divisionAcctTag: string;
	divisionGroupingId: string;
	balanceDisplay: string;
	balance: number;
	currency: string;
	convertedBalanceDisplay: string;
	convertedBalance: number;
	convertedCurrency: string;
	isManual: boolean;
	manualHTML: string;
	manualHTMLTooltip: string;
	lastUpdate: string;
	lastRequested: string;
	accountTarget: AccountTargetV3;
	selected?: boolean;
	actions: string;

	menuOptions: CustomMenuOption[];

	constructor(
		account: AccountTargetV3,
		tqlValuesDict: TQLValuesDict,
		currencyDict: CurrencyDict,
		entities: Entity[],
		private userAvailablePermissions?: PermissionMap,
		public clickable: boolean = true
	) {
		this.accountTarget = account;
		this.accountId = account.accountId;
		this.acctNum = account.accountNumber;
		this.acctName = account.nickname;
		this.compactLabel = this.acctNum + ' - ' + this.acctName;
		this.type = account.type;
		this.institutionId = account.institutionId;
		this.entityId = account.entityId;
		this.entityGroupingId = account.entityGroupingId;
		this.regionGroupingId = account.regionGroupingId;
		this.divisionGroupingId = account.divisionGroupingId;
		this.institutionNickname = account.institutionNickname;
		if (tqlValuesDict) {
			this.entityAcctTag = tqlValuesDict[TQLPropertyKey.entity]?.values?.find((opt: FilterOption) => opt.id === this.entityGroupingId)?.displayValue;
			this.regionAcctTag = tqlValuesDict[TQLPropertyKey.region]?.values?.find((opt: FilterOption) => opt.id === this.regionGroupingId)?.displayValue;
			this.divisionAcctTag = tqlValuesDict[TQLPropertyKey.division]?.values?.find((opt: FilterOption) => opt.id === this.divisionGroupingId)?.displayValue;
			this.institutionNickname =
				this.institutionNickname || tqlValuesDict[TQLPropertyKey.institution]?.values?.find((opt: FilterOption) => opt.id === this.institutionId)?.displayValue;
		}
		if (entities?.length) {
			const entity: Entity = entities.find((ent: Entity) => ent.entityId === this.entityId);
			this.entityName = entity?.name;
			this.entityAlias = entity?.nickname;
			this.entityDivision = entity?.division;
			this.entityRegion = entity?.region;
		}
		this.currency = account.currency;
		this.convertedCurrency = account.currencyConverted;
		const formatter: Formatter = new Formatter();
		this.balance = account.balance;
		this.convertedBalance = account.bankBalanceConverted;
		if (currencyDict) {
			this.balanceDisplay = formatter.formatValue(this.balance, currencyDict[this.currency]);
			this.convertedBalanceDisplay = formatter.formatValue(this.convertedBalance, currencyDict[this.convertedCurrency]);
		}
		this.isManual = account.isManual;
		if (this.isManual) {
			this.manualHTML = '<div class="account-distinction"><img class="account-distinction-icon" src="src/assets/svgs/manual-accounts.svg"></div>';
			this.manualHTMLTooltip = 'Manual account';
		} else if (account.paymentEnabled) {
			this.manualHTML = '<div class="account-distinction"><img class="account-distinction-icon" src="src/assets/svgs/supports-payments.svg"></div>';
			this.manualHTMLTooltip = 'Supports payments';
		}

		if (account.balanceTimestamp) {
			this.lastUpdate = DateTime.fromISO(account.balanceTimestamp, { zone: 'utc' }).toLocaleString(DateTime.DATETIME_SHORT);
		} else if (account.balanceDate) {
			this.lastUpdate = DateTime.fromFormat(account.balanceDate, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT);
		}

		if (account.lastRefreshTs) {
			this.lastRequested = DateTime.fromISO(account.lastRefreshTs).toLocaleString(DateTime.DATETIME_SHORT);
		}
		if (this.userAvailablePermissions) {
			this.actions = 'actions';
			this.setMenuOptions();
		}
	}

	private setMenuOptions(): void {
		const isDisabled: boolean = !this.userAvailablePermissions.has(PermissionId.markAccountsAsClosed);
		this.menuOptions = [
			new CustomMenuOption('closeAccount', 'Close Account', true, isDisabled, {
				optionClass: 'red-text',
				optionTooltip: isDisabled ? UtilityText.permissionTooltip : '',
			}),
		];
	}

	isDirty(): boolean {
		return (
			this.acctName !== this.accountTarget.nickname ||
			this.type !== this.accountTarget.type ||
			this.institutionId !== this.accountTarget.institutionId ||
			this.entityGroupingId !== this.accountTarget.entityGroupingId ||
			this.regionGroupingId !== this.accountTarget.regionGroupingId ||
			this.divisionGroupingId !== this.accountTarget.divisionGroupingId
		);
	}
}

export class AccountGroup {
	table: PaginatedTableViewModel<AccountGroupRow>;
	total: string = '0.00';
	totalNumeric: number;
	loading: boolean;
	groupCurrency: string;
	isExpanded: boolean = false;
	isManualInstitution: boolean;
	hasManual: boolean = false;
	accountRows: AccountGroupRow[];
	private formatter: Formatter = new Formatter();
	constructor(
		public groupId: string,
		public groupType: AcctGroupType,
		public groupDisplay: string,
		public groupCurrencyObj: Currency,
		accounts: AccountGroupRow[] = [],
		private selectMode?: boolean,
		private defaultSortCol: string = AcctGroupColumns.convertedBalance,
		private defaultSortDirrection: SortDirection = 'desc',
		public iconUrl?: string
	) {
		this.resetRows(accounts, groupCurrencyObj);
		this.isExpanded = false;
	}
	resetRows(accounts: AccountGroupRow[] = [], groupCurrency?: Currency): void {
		if (groupCurrency) {
			this.groupCurrencyObj = groupCurrency;
			this.groupCurrency = groupCurrency.code;
		}
		this.accountRows = this.groupId
			? accounts.filter(acct => {
					if (this.groupType.value === AcctGroupTypeOption.isManual) {
						return (this.groupId === 'true' && acct.isManual) || (this.groupId === 'false' && !acct.isManual);
					} else if (this.groupType.value === AcctGroupTypeOption.entityDivision || this.groupType.value === AcctGroupTypeOption.entityRegion) {
						return acct[this.groupType.value] === this.groupId;
					} else {
						return acct.accountTarget[this.groupType.value] === this.groupId;
					}
				})
			: accounts;
		let total: number = 0;
		this.hasManual = false;
		this.accountRows.forEach(acct => {
			if (this.groupType?.value === AcctGroupTypeOption.institutionId && this.isManualInstitution !== false) {
				this.isManualInstitution = acct.accountTarget.dataProviderId === 'MANUAL';
			}
			total += acct.convertedBalance;
			this.hasManual = acct.isManual || this.hasManual;
		});
		this.totalNumeric = total;
		this.total = this.formatter.formatValue(this.totalNumeric, this.groupCurrencyObj);
		this.isExpanded = this.isExpanded && this.accountRows.length !== 0;
		const noDataMessage: string = this.groupId ? 'No accounts remaining' : 'No accounts matching filter';
		this.table = new PaginatedTableViewModel<AccountGroupRow>(
			'accountId',
			this.accountRows,
			this.selectMode,
			this.table?.defaultSortCol || this.defaultSortCol,
			this.table?.defaultSortDirection || this.defaultSortDirrection,
			noDataMessage
		);
		this.loading = false;
	}
	isDirty(): boolean {
		return this.accountRows.some(acct => acct.isDirty());
	}

	getSelectedRows(): AccountGroupRow[] {
		return this.accountRows.filter(row => row.selected);
	}
}

export class GroupedAccountTablesViewModel {
	tables: AccountGroup[] = [];
	expanded: boolean = false;
	gridInitialized: boolean = false;
	filteredAccounts: AccountGroupRow[];
	collectionView: CollectionView<AccountGroupRow>;
	private formatter: Formatter = new Formatter();
	wijmoTemplate: ICellTemplateFunction;
	constructor(
		private tqlValuesDict: TQLValuesDict,
		public groupBy: AcctGroupType,
		allAccounts: AccountTargetV3[],
		private entities: Entity[],
		private currencyDict: CurrencyDict,
		private defaultCurrency: Currency,
		private userAvailablePermissions?: PermissionMap,
		searchData?: SearchData,
		private selectMode?: boolean,
		private institutions?: Institution[]
	) {
		this.wijmoTemplate = this.formatter.currencyWijmoCellTemplate(currencyDict);
		this.setAccountRows(allAccounts, searchData, defaultCurrency);
		if (!this.groupBy.value) {
			this.tables = [new AccountGroup(null, null, null, defaultCurrency, this.filteredAccounts, selectMode)];
		}
	}
	setAccountRows(allAccounts: AccountTargetV3[], searchData: SearchData, newCurrency?: Currency, groupId?: string): void {
		const allRows: AccountGroupRow[] = allAccounts.map(
			acct => new AccountGroupRow(acct, this.tqlValuesDict, this.currencyDict, this.entities, this.userAvailablePermissions, !this.selectMode)
		);
		this.filteredAccounts = allRows.filter(accountGroupRowFilter(searchData));
		this.collectionView = new CollectionView<AccountGroupRow>(allRows);
		this.collectionView.filter = accountGroupRowFilter(searchData);
		if (groupId) {
			this.tables.filter(table => table.groupId === groupId).forEach(table => table.resetRows(this.filteredAccounts, newCurrency));
		} else {
			this.tables.forEach(table => table.resetRows(this.filteredAccounts, newCurrency));
			this.buildNewGroups(allAccounts);
		}
	}
	private buildNewGroups(allAccounts: AccountTargetV3[]): void {
		const allRows: AccountGroupRow[] = allAccounts.map(
			acct => new AccountGroupRow(acct, this.tqlValuesDict, this.currencyDict, this.entities, this.userAvailablePermissions, !this.selectMode)
		);
		if (this.groupBy.value) {
			const tqlValue: TQLValues = this.tqlValuesDict[getFieldPropertyKeyFromAcctGroup(this.groupBy.value)];
			const filterOpts: GenericOption[] = tqlValue?.values;
			if (this.groupBy.value === AcctGroupTypeOption.institutionId) {
				allAccounts.forEach(acct => {
					const matchingFilter: FilterOption = filterOpts?.find(opt => opt.id === acct.institutionId);
					if (!matchingFilter) {
						filterOpts?.push({
							displayValue: acct.institutionNickname,
							key: acct.institutionId,
							id: acct.institutionId,
						});
					}
				});
			}
			if (filterOpts || this.groupBy.value === AcctGroupTypeOption.isManual) {
				if (this.groupBy.value === AcctGroupTypeOption.isManual) {
					this.tables = [
						new AccountGroup('true', this.groupBy, 'Manual', this.defaultCurrency, this.filteredAccounts, this.selectMode, null, null, null),
						new AccountGroup('false', this.groupBy, 'Automated', this.defaultCurrency, this.filteredAccounts, this.selectMode, null, null, null),
					];
				} else {
					filterOpts?.forEach((opt: FilterOption) => {
						if (
							(allAccounts.find(acct => acct[this.groupBy.value] === opt.id) || allRows.find((row: AccountGroupRow) => row[this.groupBy.value] === opt.id)) &&
							!this.tables.find(table => table.groupId === opt.id)
						) {
							let logoUrl: string;
							if (this.groupBy.value === AcctGroupTypeOption.institutionId) {
								logoUrl = this.institutions?.find((ins: Institution) => ins.institutionId === opt.id)?.metadata?.logoUrl;
							}
							this.tables.push(
								new AccountGroup(opt.id, this.groupBy, opt.displayValue, this.defaultCurrency, this.filteredAccounts, this.selectMode, null, null, logoUrl)
							);
						}
					});
				}
				this.tables.sort((a: AccountGroup, b: AccountGroup) => {
					if (a.accountRows.length && !b.accountRows.length) {
						return -1;
					} else if (!a.accountRows.length && b.accountRows.length) {
						return 1;
					} else {
						return b.totalNumeric <= a.totalNumeric ? -1 : 1;
					}
				});
			}
		}
	}

	getSelectedRows(): AccountGroupRow[] {
		let selectedRows: AccountGroupRow[] = [];
		this.tables?.forEach((group: AccountGroup) => (selectedRows = selectedRows.concat(group.getSelectedRows())));
		return selectedRows;
	}
}
