import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { Currency, CurrencyDict, CurrencySummaryResponse } from '../models/currency.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CurrencyService {
	version = 'v1';

	cachedCurrenciesDict: CurrencyDict;
	cachedCurrencies: Currency[];

	constructor(private httpClient: HttpClient) {}

	public clearCache() {
		this.cachedCurrencies = null;
		this.cachedCurrenciesDict = null;
	}

	public getCurrencies(shouldRefresh?: boolean): Observable<HttpResponse<Array<Currency>>> | any {
		if (!this.cachedCurrencies || shouldRefresh) {
			const url = environment.edgeAPI() + '/currencies';
			return this.httpClient
				.get<Array<Currency>>(url, {
					observe: 'response',
					headers: new HttpHeaders({
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'Accept-Version': 'v2',
					}),
				})
				.pipe(
					map(res => {
						this.cachedCurrencies = res.body;
						this.cachedCurrenciesDict = {};
						res.body.forEach(cur => {
							this.cachedCurrenciesDict[cur.code] = cur;
						});
						return res;
					}),
					catchError(err => throwError(err))
				);
		} else {
			const resp = { body: this.cachedCurrencies };
			return of(resp);
		}
	}

	public getCurrenciesSummary(currencyOverride?: string): Observable<HttpResponse<CurrencySummaryResponse>> {
		let parameters = new HttpParams();
		if (currencyOverride) {
			parameters = parameters.set('currencyOverride', currencyOverride);
		}
		const url = environment.edgeAPI() + '/currencies/summary';
		return this.httpClient
			.get<any>(url, {
				params: parameters,
				headers: new HttpHeaders({
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Accept-Version': 'v5',
				}),
				observe: 'response',
			})
			.pipe(
				map(res => res),
				catchError(err => throwError(err))
			);
	}

	public filterCurrencySelect(currencies: Currency[], filteredCurrencies: ReplaySubject<Currency[]>, value: string) {
		if (!currencies) {
			return;
		}
		if (!value) {
			filteredCurrencies.next(currencies.slice());
			return;
		}
		value = value.toLowerCase();
		filteredCurrencies.next(currencies.filter(cur => cur.code.toLowerCase().indexOf(value) > -1 || cur.name.toLowerCase().indexOf(value) > -1));
	}
}
